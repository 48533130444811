import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const LittleAnneQcprPage = () => {
    return (
      <>
        <ProductTopSection
            title="Little Anne QCPR"
            description="Little Anne QCPR Light is a CPR manikin designed to represent a typical adult cardiac arrest patient. The manikin is Bluetooth enabled for QCPR app compatibility and includes a port to connect a SkillGuide device"
            asset="assets/resusci-anne-qcpr.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Female manikin',
                link: '/female-manikin'
            }, {
                text: 'Little Anne QCPR'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=501"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/little-anne-qcpr-how-to-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/female-manikin/little-anne-qcpr/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/female-manikin/little-anne-qcpr/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Airway",
                            link:"/female-manikin/little-anne-qcpr/airway"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Mobile Application",
                            link:"/female-manikin/little-anne-qcpr/mobile-application"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Skill guide",
                            link:"/female-manikin/little-anne-qcpr/skill-guide"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/female-manikin/little-anne-qcpr/torso"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Upgrade Kits",
                            link:"/female-manikin/little-anne-qcpr/upgrade-kits"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Little Anne QCPR"
                    items={[{
                            title: "What is the difference between Little Anne QCPR and Resusci Anne QCPR",
                            subtitle: "",
                            link: "/articles/000003657"
                        },{
                            title: "What is Little Anne QCPR?",
                            subtitle: "",
                            link: "/articles/000006026"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003646 ", text:" User Guide for Little Anne (all versions)" },
                        { link:"/articles/000006026 ", text:" What is Little Anne QCPR?" },
                        { link:"/articles/000003938 ", text:" Little Anne QCPR and Little Junior QCPR material specifications" },
                        { link:"/articles/000001156 ", text:" What is QCPR?" },   
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-qcpr"
                        },{
                            text: "Resusci Anne QCPR",
                            asset: "assets/resusci-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-qcpr"
                        },
                        {
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default LittleAnneQcprPage;