import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const CPRmeter2Page = () => {
    return (
      <>
        <ProductTopSection
            title="CPRmeter 2"
            description="The CPRmeter 2 is a simple tool you can use to ensure high-quality compressions are delivered by all the responders on scene. The CPRmeter 2 provides real-time measured feedback on compression rate (cpm), depth (mm), release (g), compressions count, and inactivity time during CPR, while also enabling responders to self-evaluate their performance with event statistics on the spot."
            asset="assets/cprmeter-2.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'CPRmeter 2'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=498"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"CPRmeter Unit",
                            link:"/medical-devices/cprmeter-2/cprmeter-unit"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/medical-devices/cprmeter-2/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Mobile Application",
                            link:"/medical-devices/cprmeter-2/mobile-application"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your CPRmeter 2"
                    items={[{
                            title: "What is the intended use of the CPRmeter 2?",
                            subtitle: "",
                            link: "/articles/000006990"
                        },{
                            title: "What does CPRmeter 2 package include?",
                            subtitle: "",
                            link: "/articles/000006997"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006990 ", text:" What is the intended use of the CPRmeter 2?" },
                        { link:"/articles/000006993 ", text:" What is the CPR feedback I would get during use of CPRmeter 2?" },
                        { link:"/articles/000006998 ", text:" How often should the patient adhesive be replaced?" },
                        { link:"/articles/000006996 ", text:" Is the CPRmeter 2 reusable?" },
                        { link:"/articles/000006992 ", text:" What is the size of the CPRmeter 2?" },
                        { link:"/articles/000006995 ", text:" Is the CPRmeter 2 serviceable?" },
                        { link:"/articles/000006997 ", text:" What does CPRmeter 2 package include?" },
                        { link:"/articles/000007000 ", text:" Can CPRmeter 2 remain applied to the patient's chest during defibrillation?" },    
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default CPRmeter2Page;