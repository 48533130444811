import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const NursingKidPage = () => {
    return (
      <>
        <ProductTopSection
            title="Nursing Kid"
            description="Realistically representing a six-year old child, Nursing Kid is designed for skill and scenario-based training of the care and management of a variety of pediatric patients. This child is ideal for clinical training of common and uncommon in-hospital pediatric patients including wound assessment and care, first aid, and child abuse training."
            asset="assets/nursing-kid.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            }, {
                text: 'Nursing Kid'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=135"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/kid-junior-manikin/nursing-kid/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/kid-junior-manikin/nursing-kid/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/kid-junior-manikin/nursing-kid/torso"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Pelvis",
                            link:"/articles/000002837"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Nursing Kid"
                    items={[{
                            title: "What are the main specifications for Nursing Kid?",
                            subtitle: "",
                            link: "/articles/000002083"
                        },{
                            title: "Where is the serial number located on Nursing Kid?",
                            subtitle: "",
                            link: "/articles/000002839"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003678 ", text:" Does Nursing Kid have IM injection capabilities?" },
                        { link:"/articles/000002830 ", text:" Does Nursing Kid contain latex?" },
                        { link:"/articles/000002825 ", text:" Is Nursing Kid available in ethnic skin tones?" },
                        { link:"/articles/000002083 ", text:" What are the main specifications for Nursing Kid?" },
                        { link:"/articles/000002828 ", text:" What items ship with Nursing Kid?" },
                        { link:"/articles/000002842 ", text:" What materials are Nursing Kid made of?" },
                        { link:"/articles/000002839 ", text:" Where is the serial number located on Nursing Kid?" },
                        { link:"/articles/000002846 ", text:" What size NG Tube should be used with Nursing Kid?" },
                        { link:"/articles/000002850 ", text:" What size tracheostomy tube is recommended for use with Nursing Kid?" },
                        { link:"/articles/000002849 ", text:" What size urinary catheter should be used with Nursing Kid?" },
                        { link:"/articles/000002823 ", text:" Where can I find a copy of the User Guide for Nursing Kid?" },
                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kid",
                            asset: "assets/megacode-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/megacode-kid"
                        },{
                            text: "Nursing Kid",
                            asset: "assets/nursing-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/nursing-kid"
                        },{
                            text: "Resusci Junior QCPR",
                            asset: "assets/resusci-junior-qcpr.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/resusci-junior-qcpr"
                        },{
                            text: "SimJunior",
                            asset: "assets/sim-junior.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/sim-junior"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default NursingKidPage;