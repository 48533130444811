import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ShockLinkPage = () => {
    return (
      <>
        <ProductTopSection
            title="Shock Link"
            description="ShockLink is a small device that connects with adhesive training pads on one end, and to a defibrillator on the other end. This product has a dual purpose:It works as a rhythm generator. It works as a safety barrier between an AED/defibrillator and a CPR manikin as it absorbs the shock delivered by the defibrillator"
            asset="assets/shock-link.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'Shock Link'
            }]}
            buttonLink="https://laerdal.com/us/ProductDownloads.aspx?productId=459"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/training-devices/shock-link/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Mobile Application",
                            link:"/training-devices/shock-link/mobile-application"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"ShockLink",
                            link:"/training-devices/shock-link/shocklink"
                        },
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Shock Link"
                    items={[{
                            title: "What is ShockLink?",
                            subtitle: "",
                            link: "/articles/000001813"
                        },{
                            title: "What are the part numbers for ShockLink and its accessories?",
                            subtitle: "",
                            link: "/articles/000001759"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000001759 ", text:" What are the part numbers for ShockLink and its accessories?" },
                        { link:"/articles/000001813 ", text:" What is ShockLink?" },
                        { link:"/articles/000002199 ", text:" What rhythms can ShockLink generate?" },
                        { link:"/articles/000001320 ", text:" What are the ShockLink product specifications?" }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "LLEAP",
                            asset: "assets/lleap-hw.png",
                            heightPx: "144px",
                            link: "/training-devices/lleap"
                        },{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default ShockLinkPage;