import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const LaerdalSiliconeResuscitatorsPage = () => {
    return (
      <>
        <ProductTopSection
            title="Laerdal Silicone Resuscitators"
            description="The Laerdal Silicone Resuscitator (LSR) is a self-inflating manual resuscitator that is intended for patients requiring total or intermittent ventilatory support."
            asset="assets/laerdal-silicone-resuscitators.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'Laerdal Silicone Resuscitators'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=264"
        />


        <FullNeutralPageContainer>
            <PageContainer>
                <GetToKnow 
                    title="Get to know your Laerdal Silicone Resuscitators"
                    items={[{
                            title: "What are the weight classes for LSR?",
                            subtitle: "",
                            link: "/articles/000007065"
                        },{
                            title: "What is the expected service life of the LSR?",
                            subtitle: "",
                            link: "/articles/000007059"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000007068 ", text:" How to assemble the LSR?" },
                        { link:"/articles/000007062 ", text:" Do Laerdal Silicone Resuscitators have pressure gauges in them?" },
                        { link:"/articles/000007060 ", text:" How often should I replace the Lip valve for my LSR?" },
                        { link:"/articles/7059 ", text:" What is the expected service life of the LSR?" },
                        { link:"/articles/000007067 ", text:" What is the shelf life for LSR O2 Reservoir bag?" },
                        { link:"/articles/000007066 ", text:" Where can I find the User Guide for the LSR?" },
                        { link:"/articles/000007069 ", text:" How should I clean/disinfect my LSR?" },
                        { link:"/articles/000007065 ", text:" What are the weight classes for LSR?" },
                        { link:"/articles/000007064 ", text:" What is the length of the extension tube used with the LSR?" },
                        { link:"/articles/000007063 ", text:" Should a LSR Preterm-version be used with a term-born baby?" },
                        
                                    
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default LaerdalSiliconeResuscitatorsPage;