import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ShockLinkShockLinkPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'RQI',
                link: '/training-devices/rqi'
            },{
                text: 'ShockLink',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000003567 ", text:" How many applications can I get from the AED Trainer and ShockLink pads?" },
                        { link:"/articles/000001397 ", text:" Can I use my AED/defib pads with ShockLink?" },
                        { link:"/articles/000004031 ", text:" Does ShockLink support override of defibrillator shock advisory mode (SAM)?" },
                        { link:"/articles/000003636 ", text:" What are the power requirements for Shocklink?" },
                        { link:"/articles/000002296 ", text:" What is the advantage of using AED Little Anne with the ShockLink?" },
                        { link:"/articles/000002162 ", text:" What is the advantage of using Resusci Anne QCPR AED with the ShockLink?" },
                        { link:"/articles/000002108 ", text:" Which manikins can I use with ShockLink?" },
                        { link:"/articles/000003702 ", text:" Will \"corpuls3\" defibrillator work with ShockLink?" },
                        { link:"/articles/000004033 ", text:" Is Corpuls Pacing compatible with ShockLink?" },
                        { link:"/articles/000001497 ", text:" What defibrillators can I use with ShockLink?" },
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ShockLinkShockLinkPage;