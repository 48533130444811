import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const TheBagPage = () => {
    return (
      <>
        <ProductTopSection
            title="The Bag"
            description="The BAG resuscitator is a self-inflating, manual resuscitator that is intended for patients requiring total or intermittent ventilation support.The BAG resuscitator provides positive pressure ventilation and also allows for spontaneous breathing either with an artificial airway or with a face mask"
            asset="assets/the-bag.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'The Bag'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=283"
        />


        <FullNeutralPageContainer>
            <PageContainer>
                <GetToKnow 
                    title="Get to know your The Bag"
                    items={[{
                            title: "What are the indications for use for the BAG II?",
                            subtitle: "",
                            link: "/articles/000007057"
                        },{
                            title: "Does The BAG II contain phthalates?",
                            subtitle: "",
                            link: "/articles/000007054"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000007052 ", text:" Can the Oxygen Reservoir on The BAG II be detached?" },
                        { link:"/articles/000007051 ", text:" Are the masks for The BAG II available for sale individually?" },
                        { link:"/articles/000007058 ", text:" Does the BAG II have an expiry date?" },
                        { link:"/articles/000007049 ", text:" How is The BAG II packaged?" },
                        { link:"/articles/000007050 ", text:" Is there a Manometer Connector for The Bag II?" },
                        { link:"/articles/000007056 ", text:" Is there an overview of The BAG II features and benefits?" },
                        { link:"/articles/000007053 ", text:" What are the mask sizes for the BAG II?" },
                        { link:"/articles/000007057 ", text:" What are the indications for use for the BAG II?" },
                        { link:"/articles/000007055 ", text:" How long is the O2 tubing included with The Bag II?" },
                        { link:"/articles/000007054 ", text:" Does The BAG II contain phthalates?" },                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default TheBagPage;