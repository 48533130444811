import { BREAKPOINTS, Card, GlobalNavigationBar } from '@laerdal/life-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const Topic = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;
const TopicContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const Title = styled.div`
  color: #1D1D1F;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; 
`;

const TopicSelection = styled.div`

    display: flex;
    flex-wrap: wrap;
    gap: 8px; 
    justify-content: center;
`;

const LandingCard = styled(Card)`
  width: 240px;

  div:first-child{
    min-width: unset;
  }
  .card-topSection {
    min-width:222px;
  }

  .card-middleSection{
    min-width: unset;
  }

  .titleBlock {
    text-align: center;
    font-size: 18px;
    
  }
`;

interface TopicsProps {
  title?: string;
  items: TopicItem[]
}
export interface TopicItem {
  asset: string;
  text: string;
  link: string;
  heightPx: string;
}

const Topics = ({title, items}: TopicsProps) => {
    
  const navigate = useNavigate()
  
    return (
        <Topic>
            <TopicContainer>
              { !!title &&
                <Title>{title}</Title>
              }
              <TopicSelection>
                { items.map((item, index) =>
                    <LandingCard key={`link-${index}`}
                      onCardClicked={() => { 
                          navigate(item.link);
                          return {};
                        }}
                      variant="elevated"
                      topSectionProps={{
                        image: {
                          src: item.asset,
                          alt: item.text,
                          width: "192px",
                          height: item.heightPx
                        }}
                      }
                      middleSectionProps={{
                        title: item.text,
                        description: ""
                      }}/>
                  )
                }
              </TopicSelection>
            </TopicContainer>
          </Topic>
    );
}

export default Topics;