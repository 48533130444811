import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const RqiPagePage = () => {
    return (
      <>
        <ProductTopSection
            title="RQI"
            description="The QCPR App pairs with a wide-range of Laerdal manikins and devices to take CPR training to the next level."
            asset="assets/rqi.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'RQI'
            }]}
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Other",
                            link:"/training-devices/rqi/other"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"RQI Cart",
                            link:"/training-devices/rqi/rqi-cart"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Software",
                            link:"/training-devices/rqi/software"
                        },
                    ]}
                />
                <GetToKnow 
                    title="Get to know your RQI"
                    items={[{
                            title: "Definitions and Acronyms related to RQI",
                            subtitle: "",
                            link: "/articles/000006047"
                        },{
                            title: "Is a Secure SDLC in place for RQI?",
                            subtitle: "",
                            link: "/articles/000006083"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006106 ", text:" Do all employees and contractors receive annual training on information security policies and procedures?" },
                        { link:"/articles/000006047 ", text:" Definitions and Acronyms related to RQI" },
                        { link:"/articles/000006107 ", text:" Do employees receive Secure SDLC training?" },
                        { link:"/articles/000006105 ", text:" Does the organization review the information security policy?" },
                        { link:"/articles/000006101 ", text:" Does the RQI environment come under PCI-DSS compliance requirements?" },
                        { link:"/articles/000006083 ", text:" Is a Secure SDLC in place for RQI?" },
                        { link:"/articles/000006084 ", text:" Is a Static Application Security Testing to find code vulnerability in place for RQI?" },
                        { link:"/articles/000006100 ", text:" Is the SOC2 Type II interim report for Security, Availability and Confidentially Trust principles available?" },
                        { link:"/articles/000003388 ", text:" How can I identify learners that have incomplete or past due activities?" },
                        { link:"/articles/000002025 ", text:" How do I verify my RQI e-card?" },

                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Shock Link",
                            asset: "assets/shock-link.png",
                            heightPx: "144px",
                            link: "/training-devices/shock-link"
                        },{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default RqiPagePage;