import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const CPRmeter2DocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Medical devices',
                link: '/medical-devices'
            },{
                text: 'CPRmeter 2',
                link: '/medical-devices/cprmeter-2'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000006990 ", text:" What is the intended use of the CPRmeter 2?" },
                        { link:"/articles/000006993 ", text:" What is the CPR feedback I would get during use of CPRmeter 2?" },
                        { link:"/articles/000006998 ", text:" How often should the patient adhesive be replaced?" },
                        { link:"/articles/000006996 ", text:" Is the CPRmeter 2 reusable?" },
                        { link:"/articles/000006992 ", text:" What is the size of the CPRmeter 2?" },
                        { link:"/articles/000006995 ", text:" Is the CPRmeter 2 serviceable?" },
                        { link:"/articles/000006997 ", text:" What does CPRmeter 2 package include?" },
                        { link:"/articles/000007000 ", text:" Can CPRmeter 2 remain applied to the patient's chest during defibrillation?" },
                        { link:"/articles/000007001 ", text:" How does the CPRmeter 2 work with a soft surface, e.g. mattress?" },
                        { link:"/articles/000007006 ", text:" How often should I inspect or maintain the CPRmeter 2?" },
                        { link:"/articles/000007002 ", text:" What environmental conditions can the CPRmeter 2 withstand?" },
                        { link:"/articles/000007010 ", text:" Do I need training on the CPRmeter 2 device before using it clinically?" },
                        { link:"/articles/000007003 ", text:" Can I use CPRmeter 2 in a moving environment, such as helicopter, ambulance, stretcher?" },
                        { link:"/articles/000007004 ", text:" Can I use the CPRmeter 2 in combination with mechanical/compression machine?" },
                        { link:"/articles/000007005 ", text:" Where should I store the CPRmeter 2 between uses?" },
                        { link:"/articles/000007016 ", text:" Does the CPRmeter 2 device follow AHA, ILCOR and ERC guidelines?" },
                        { link:"/articles/000007017 ", text:" How can I view the data from CPRmeter 2?" },
                        { link:"/articles/000007018 ", text:" What performance statistics are displayed in the QCPR Quick Review?" },
                        { link:"/articles/000007011 ", text:" What should I do if I am using CPRmeter 2 with a manikin which gives feedback?" },
                        { link:"/articles/000007012 ", text:" How do I clean and disinfect the CPRmeter 2?" },
                        { link:"/articles/000007013 ", text:" What is the CPRmeter 2 device's warranty?" },
                        { link:"/articles/000007014 ", text:" What standards does the CPRmeter 2 comply with?" },
                        { link:"/articles/000007015 ", text:" What are the Regulatory Approvals that the CPRmeter 2 has?" },
                        { link:"/articles/000007019 ", text:" How do I activate Bluetooth for the CPRmeter 2?" },
                        { link:"/articles/000007026 ", text:" Overview of CPRmeter 2 FAQs" },
                        { link:"/articles/000006999 ", text:" Does the CPRmeter 2 contain latex?" }
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default CPRmeter2DocumentationPage;