import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimNewBTetherlessTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'SimNewB Tetherless',
                link: '/baby-newborn-manikin/simnewb-tetherless'
            },{
                text: 'Legs',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000005626 ", text:" Can bilateral needle thoracentesis be performed on SimNewB?" },
                        { link:"/articles/000005458 ", text:" Can I do chest compressions on SimNewB?" },
                        { link:"/articles/000005457 ", text:" Can I introduce fluids/medications on SimNewB?" },
                        { link:"/articles/000005465 ", text:" Can I overcharge my SimNewB battery?" },
                        { link:"/articles/000005485 ", text:" Can SimNewB be used in an incubator?" },
                        { link:"/articles/000005471 ", text:" How do I clean SimNewB?" },
                        { link:"/articles/000005472 ", text:" How do I empty and clean the umbilical reservoir on SimNewB?" },
                        { link:"/articles/000005464 ", text:" How long does it take to charge the battery in SimNewB?" },
                        { link:"/articles/000005463 ", text:" How long will the battery last when using SimNewB?" },
                        { link:"/articles/000005515 ", text:" How to prepare Umbilical Reservoir for UVC on SimNewB?" },
                        { link:"/articles/000005516 ", text:" How to prepare for UVC large volume infusions on SimNewB?" },
                        { link:"/articles/000005469 ", text:" I am having trouble getting tape to stick to the new SimNewB skin. What do you recommend?" },
                        { link:"/articles/000005454 ", text:" Is SimNewB available in different skin tones?" },
                        { link:"/articles/000005484 ", text:" What blood concentrate can I use in the SimNewB umbilical reservoir?" },
                        { link:"/articles/000005389 ", text:" Product Data Sheet for Simulated Umbilical Blood - 3 fl. oz." },
                        
                    ]}/>

                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimNewBTetherlessTorsoPage;