import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingAnneSimulatorPelvisPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Nursing Anne Simulator',
                link: '/female-manikin/nursing-anne-simulator'
            },{
                text: 'Pelvis',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000004285 ", text:" How to set up the Nursing Anne Simulator Stoma for Irrigation?" },
                        { link:"/articles/000004279 ", text:" How to fill and pressurize the Urine Reservoir in Nursing Anne Simulator?" },
                        { link:"/articles/000003998 ", text:" Is the waist locking mechanism required to place Nursing Annes Simulator in a sitting position?" },
                        { link:"/articles/000006786 ", text:" Small change in Manifold Valves for Nursing Anne Simulator" },
                        { link:"/articles/000006307 ", text:" Suppository and Enema Setup for Nursing Anne Simulator" },
                        { link:"/articles/000006929 ", text:" Additional guidance when using Nursing Anne Simulator in Upright Sitting Position" },
                        { link:"/articles/000007218 ", text:" How to order replacement Duck Bill Valves for Nursing Anne Simulator?" },
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingAnneSimulatorPelvisPage;