import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ResusciBabyFirstAidPage = () => {
    return (
      <>
        <ProductTopSection
            title="Resusci Baby FirstAid"
            description="The Resusci Baby for First Aid offer optimal realism to infant First Aid Training. With its realistic appearance students can quickly sharpen their skills in performing infant First Aid Practice at its highest quality."
            asset="assets/resusci-baby-firstaid.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Resusci Baby FirstAid'
            }]}
            buttonLink="https://laerdal.com/us/ProductDownloads.aspx?productId=395"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/articles/000002045"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Resusci Baby First Aid"
                    items={[{
                            title: "What is the Resusci Baby First Aid manikin?",
                            subtitle: "",
                            link: "/articles/000002045"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "Little Baby QCPR",
                        asset: "assets/little-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/little-baby-qcpr"
                    },{
                        text: "Resusci Baby QCPR",
                        asset: "assets/resusci-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/resusci-baby-qcpr"
                    },{
                        text: "Premature Anne Baby",
                        asset: "assets/premature-anne-baby.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/premature-anne-baby"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default ResusciBabyFirstAidPage;