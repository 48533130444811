import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingAnnePelvisPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Nursing Anne',
                link: '/female-manikin/nursing-anne'
            },{
                text: 'Pelvis',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000001265 ", text:" Is the adult colon reservoir available in ethnic skin tones?" },
                        { link:"/articles/000003323 ", text:" Is the One-Hole Abdominal/Belly Plate available in ethnic skin tones?" },
                        { link:"/articles/000002340 ", text:" Is the removable red stoma included with Nursing Anne or Nursing Kelly manikins?" },
                        { link:"/articles/000001481 ", text:" Is there an upgrade kit to improve urinary catheterization for Nursing Anne and Nursing Kelly?" },
                        { link:"/articles/000003264 ", text:" What is the fluid capacity of the adult urinary reservoir (abdominal plate)?" },
                        { link:"/articles/000003661 ", text:" When were improved urinary valve connectors introduced for adult patient care manikins?" },                        
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingAnnePelvisPage;