import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LaerdalSuctionUnitCabinetCasePage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Medical devices',
                link: '/medical-devices'
            },{
                text: 'Laerdal Suction Unit',
                link: '/medical-devices/laerdal-suction-unit'
            },{
                text: 'Cabinet/Case',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000007114 ", text:" What is Catalog Number for the LSU Battery?" },
                        { link:"/articles/000007115 ", text:" Is it possible to service the LSU?" },
                        { link:"/articles/000007103 ", text:" Is there a replacement for the blue cap on the front side of the LSU?" },
                        { link:"/articles/000007084 ", text:" Why isn't my LSU battery status indicator on?" },
                        { link:"/articles/000007106 ", text:" Is there a Safety Data Sheet for LSU battery?" },
                        { link:"/articles/000007105 ", text:" What type of battery is the LSU battery?" },
                        { link:"/articles/000007090 ", text:" Where is the serial number for my LSU?" },
                        { link:"/articles/000007087 ", text:" What to do if my LSU cannot be operated from the internal battery?" },
                        { link:"/articles/000007085 ", text:" When do I need to replace the LSU battery?" },
                        { link:"/articles/000007091 ", text:" What are the dimensions for LSU Wall Bracket?" },
                        { link:"/articles/000007086 ", text:" What could be wrong if my LSU operates but has low or no suction?" },
                        { link:"/articles/000007097 ", text:" Why is the LSU battery not holding charge?" },
                        { link:"/articles/000007092 ", text:" What is the battery run time for LSU?" },
                        
                        
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LaerdalSuctionUnitCabinetCasePage;