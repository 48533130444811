import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingAnneSimulatorTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Nursing Anne Simulator',
                link: '/female-manikin/nursing-anne-simulator'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000005944 ", text:" Can I use ChloraPrep on my Nursing Anne Simulator?" },
                        { link:"/articles/000004278 ", text:" How to check and replace the Air Filter in Nursing Anne Simulator?" },
                        { link:"/articles/000004295 ", text:" How to prepare the Nursing Anne Simulator Central Line Reservoir?" },
                        { link:"/articles/000004276 ", text:" How to quick fill Reservoirs in Nursing Anne Simulator?" },
                        { link:"/articles/000003995 ", text:" Does Nursing Anne Simulator have QCPR and can I perform CPR on her?" },
                        { link:"/articles/000006301 ", text:" Why is my Ethernet Jack damaged on Nursing Anne Simulator?" },
                        { link:"/articles/000006777 ", text:" How to Connect Large Volume Infusion Bypass System to Stomach Reservoir in Nursing Anne Simulator" },
                        { link:"/articles/000006776 ", text:" How to clean the Stomach Reservoir in Nursing Anne Simulator?" },
                        { link:"/articles/000003997 ", text:" How can I improve the adhesion of dressings and tapes on Nursing Anne Simulator skin?" },
                        { link:"/articles/000006858 ", text:" Breast Pockets removed from Front Skin of Nursing Anne Simulator" },
                        { link:"/articles/000006864 ", text:" Change to Indwelling Reservoir on Nursing Anne Simulators" },
                        { link:"/articles/000004106 ", text:" What is the recommendation for removing adehsive residue from Nursing Anne Simulator?" },
                        
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingAnneSimulatorTorsoPage;