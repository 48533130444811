import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const FaceShieldsPages = () => {
    return (
      <>
        <ProductTopSection
            title="Face Shields"
            description="The Laerdal Face Shield is the simple, low-cost solution for CPR Barrier devices. The protective Laerdal Face Shield helps providers overcome hesitation to start resuscitation by preventing direct contact with the victim’s mouth, nose and face."
            asset="assets/face-shields.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'Face Shields'
            }]}
        />
        
        <FullNeutralPageContainer>
            <PageContainer>
                <GetToKnow 
                    title="Get to know your Face Shields"
                    items={[{
                            title: "What is the intended use of Laerdal Face Shield?",
                            subtitle: "",
                            link: "/articles/000007204"
                        },{
                            title: "Does Laerdal Face Shield have a filter?",
                            subtitle: "",
                            link: "/articles/000007206"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>


        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006018 ", text:" What is the size of the Keyring for Laerdal Face Shield?" },
                        { link:"/articles/000007207 ", text:" Does the Laerdal Face Shield have HEPA filter?" },
                        { link:"/articles/000007208 ", text:" What is the size of the LFS?" },
                        { link:"/articles/000007205 ", text:" What are the different packaging options of Laerdal Face Shield?" },
                        { link:"/articles/000007206 ", text:" Does Laerdal Face Shield have a filter?" },
                        { link:"/articles/000007204 ", text:" What is the intended use of Laerdal Face Shield?" },
                                    
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default FaceShieldsPages;