import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimBabyMKIILegsPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'SimBaby MK II',
                link: '/baby-newborn-manikin/simbaby-mk-ii'
            },{
                text: 'Legs',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>

                <Faqs 
                    title="Relevant articles"
                    items={[                    {
                        link:"000004097",
                        text:"Can the IO leg of SimBaby be used with an EZY-IO gun?"
                    },
                    {
                        link:"000004253",
                        text:"How to change the SimBaby IO Leg Module?"
                    },
                    {
                        link:"000004243",
                        text:"How to prepare the IO System on SimBaby?"
                    },
                    {
                        link:"000004244",
                        text:"How to repair the SimBaby IO Leg Module?"
                    },
                    {
                        link:"000003606",
                        text:"What is the fluid capacity of the SimBaby IO Leg reservoir?"
                    }

                    ]}/>

                    <CantFindCreateCase />

            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimBabyMKIILegsPage;