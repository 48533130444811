import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimJuniorTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            },{
                text: 'SimJunior',
                link: '/kid-junior-manikin/sim-junior'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000002391 ", text:" Can the Broselow Tape be used effectively with SimJunior (232-05050)?" },
                        { link:"/articles/000003332 ", text:" Does SimJunior (232-05050) contain latex?" },
                        { link:"/articles/000006007 ", text:" Why does chest rise become stronger when I activate tongue edema on my SimJunior?" },
                        { link:"/articles/000001575 ", text:" Is it possible to add your own sounds to SimJunior?" },
                        { link:"/articles/000001318 ", text:" Is SimJunior (232-05050) QCPR capable?" },
                        { link:"/articles/000001782 ", text:" Should the batteries be left installed in SimJunior?" },
                        { link:"/articles/000007175 ", text:" Why do SimJunior Lungs still inflate slowly even when Lung Restriction Feature has been activated?" },
                        
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimJuniorTorsoPage;