import { BREAKPOINTS, Card, COLORS, ContentIcons, GlobalNavigationBar, PageWidth, SearchBar, Size } from '@laerdal/life-react-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const GrayGradient = styled.div`
  z-index: 0;
  position: absolute;
  width:100%;
  height:100%;
`;

const GrayGradientTop = styled.div`
  height:50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 69.5%, rgba(0, 0, 0, 0.50) 100%);
`;

const GrayGradientBottom = styled.div`
  height:50%;
  background: var(--overlay-black-50, rgba(0, 0, 0, 0.50));
`;
const HeaderContainer = styled.div`
  
  height: 441px;

  display: flex;
  position: relative;
  
  background: url('assets/LandingHeaderBackground.png') lightgray;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  width: 100%;
`;

const TopFrame = styled(PageWidth)`
  ${BREAKPOINTS.LARGE}{
    width: 1024px;
    margin: 72px auto 32px auto;  
  }
  padding:24px;
    
  gap:16px;
  flex-grow: 1;  
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-self: start;
`;

const TopFrameText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:16px;
`;

const DesktopH3 = styled.div`
  
  font-size: 64px;
  

  color: ${COLORS.white};
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
`;

const DesktopH4 = styled.div`
  font-size: 18px;
  ${BREAKPOINTS.LARGE}{
    font-size: 38px;
  }

  color: ${COLORS.white};
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
`;

const LandingHeader = () => {    
    const [searchTerm, setSearchTerm] = useState<string>('');
    const navigate = useNavigate()
  
    return (
      <HeaderContainer>
      <GrayGradient>
        <GrayGradientTop></GrayGradientTop>
        <GrayGradientBottom></GrayGradientBottom>
      </GrayGradient>
      
      <TopFrame 
        useMaxWidth={true} 
        maxWidth={1024}
        >
        <TopFrameText>
          <DesktopH3>Laerdal Support</DesktopH3>
          <DesktopH4>What do you need help with?</DesktopH4>
        </TopFrameText>
        <SearchBar
          id="DemoSearchBarSmall"
          placeholder="Type a question or search by keyword"
          enterSearch={(e) => {
            // @ts-ignore
            posthog.capture('LandingPage Search',{
              searchTerm: searchTerm
            });
            navigate('articles?query='+searchTerm);
          }}
          setSearchTerm={(term)=>{setSearchTerm(term)}}
          
          removeSearch={() => {
            
          }}
          size={Size.Small}
        />
      </TopFrame>
    </HeaderContainer>
    );
}

export default LandingHeader;