import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneQcprUpgradeKitsPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne QCPR',
                link: '/female-manikin/resusci-anne-qcpr'
            },{
                text: 'Upgrade Kits',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000004202 ", text:" How to identify an upgradable Resusci Anne QCPR manikin?" },
                        { link:"/articles/000005875 ", text:" How to upgrade from Standard to Airway Head on Resusci Anne QCPR?" },
                        { link:"/articles/000004277 ", text:" How to install Resusci Anne QCPR 2018 Upgrade Kit Bundle?" },
                        { link:"/articles/000001592 ", text:" What parts are included in the Battery Upgrade Kit Resusci Anne QCPR?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneQcprUpgradeKitsPage;