import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const AlsBabyPage = () => {
    return (
      <>
        <ProductTopSection
            title="ALS Baby"
            description='The Laerdal&reg; ALS Baby&trade; is a portable skill trainer for realistic infant resuscitation training. Laerdal ALS Baby manikin is a three-month-old infant with an anatomy that offers exceptional realism for individual training. The Laerdal ALS baby offers the opportunity to practice advanced resuscitation skills, including airway management, professional rescuer CPR, vascular access, and 4-lead ECG monitoring.'
            asset="assets/als-baby.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'ALS Baby'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=128"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/articles/000001936"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Legs",
                            link:"/baby-newborn-manikin/als-baby/legs"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Mini Anne"
                    items={[{
                            title: "What is Mini Anne?",
                            subtitle: "Last Modified 9 Sept 2022, 07:15",
                            link: "/articles/000006013"
                        },{
                            title: "Mini Anne material specifications",
                            subtitle: "Last Modified 17 Jul 2023, 14:04",
                            link: "/articles/000004082"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[{
                        text:"Can the EZ IO Gun be used on the ALS Baby?",
                        link:"/articles/000002198"
                    },{
                        text:"How does the Baby IO Leg work and with which manikins can it be used?",
                        link:"/articles/000005685"
                    },{
                        text:"How many uses can the ALS Baby Leg replacement Pad withstand?",
                        link:"/articles/000002428"
                    }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "SimNewB Tetherless",
                        asset: "assets/simnewb-tetherless.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simnewb-tetherless"
                    },{
                        text: "Little Baby QCPR",
                        asset: "assets/little-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/little-baby-qcpr"
                    },{
                        text: "Resusci Baby QCPR",
                        asset: "assets/resusci-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/resusci-baby-qcpr"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        <LaerdalCustomerCare />
      </>);
  };
  
  export default AlsBabyPage;