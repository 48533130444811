import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimNewBTetherlessDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'SimNewB Tetherless',
                link: '/baby-newborn-manikin/simnewb-tetherless'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000005451 ", text:" What is the length and weight of SimNewB?" },
                        { link:"/articles/000005453 ", text:" What is the operating temperature and humidity range for SimNewB?" },
                        { link:"/articles/000005460 ", text:" What solution can I use to give SimNewB a wet appearance?" },
                        { link:"/articles/000005474 ", text:" What warranty does SimNewB have?" },
                        { link:"/articles/000005619 ", text:" Will there be any 'how-to' videos for SimNewB?" },
                        { link:"/articles/000005475 ", text:" Can I have an extended warranty for my SimNewB?" },
                        { link:"/articles/000005481 ", text:" Is User Guide for SimNewB available in any other languages than English?" },
                        { link:"/articles/000001181 ", text:" What are the environmental  conditions required for SimNewB?" },
                        { link:"/articles/000005482 ", text:" Where is the product number/serial number/CE mark located on SimNewB?" },
                        
                    ]}/>

                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimNewBTetherlessDocumentationPage;