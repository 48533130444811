import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../../commonComponents/Topics";
import Subtopics from "../../../../../commonComponents/Subtopics";
import Faqs from "../../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const AedTrainer3MobilePadsPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'AED Trainer',
                link: '/training-devices/aed-trainer'
            }, {
                text: 'AED Trainer 3',
                link: '/training-devices/aed-trainer/aed-trainer-3'
            },{
                text: 'Pads',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Pads"
                    items={[ 
                        { link:"/articles/000006177 ", text:" Do I need Interconnect cable for AED Trainer 2 or 3?" },
                        { link:"/articles/000001273 ", text:" Do I need Pediatric Defibrillation Pads to practice Pediatric Defibrillation?" },
                        { link:"/articles/000002462 ", text:" How does an External Manikin Adapter work?" },

                    ]}/>
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default AedTrainer3MobilePadsPage;