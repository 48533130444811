import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingKidDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            },{
                text: 'Nursing Kid',
                link: '/kid-junior-manikin/nursing-kid'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000003678 ", text:" Does Nursing Kid have IM injection capabilities?" },
                        { link:"/articles/000002830 ", text:" Does Nursing Kid contain latex?" },
                        { link:"/articles/000002825 ", text:" Is Nursing Kid available in ethnic skin tones?" },
                        { link:"/articles/000002083 ", text:" What are the main specifications for Nursing Kid?" },
                        { link:"/articles/000002828 ", text:" What items ship with Nursing Kid?" },
                        { link:"/articles/000002842 ", text:" What materials are Nursing Kid made of?" },
                        { link:"/articles/000002839 ", text:" Where is the serial number located on Nursing Kid?" },
                        { link:"/articles/000002846 ", text:" What size NG Tube should be used with Nursing Kid?" },
                        { link:"/articles/000002850 ", text:" What size tracheostomy tube is recommended for use with Nursing Kid?" },
                        { link:"/articles/000002849 ", text:" What size urinary catheter should be used with Nursing Kid?" },
                        { link:"/articles/000002823 ", text:" Where can I find a copy of the User Guide for Nursing Kid?" },
                                   
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingKidDocumentationPage;