import { GlobalNavigationBar } from '@laerdal/life-react-components';

const Header = () => {
    const navigation = {
        items: [
          {
            label: 'Home',
            to: '/',
            exact: true
          },   
        ]
      } as any;

    return (
        <GlobalNavigationBar
            isAuthenticated={true}
            name="Support"
            useMaxWidth={true}
            desktop={navigation}
            mobile={navigation}
        />
    );
}

export default Header;