import axios from 'axios';
import { CaseCreateDto } from '../../model/dto/caseCreateDto';

class CaseApi {
  CreateCase = async (dto: CaseCreateDto, files: File[]): Promise<string> => {
    const formData = new FormData();
    formData.append('dto', JSON.stringify(dto));
    files.forEach((file) => formData.append('files', file));

    return axios
      .post(`${process.env.REACT_APP_API_URL}/Cases`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data;
      });
  };
}

export default new CaseApi;