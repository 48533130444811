import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SoftwarePage = () => {
    return (
      <>
        <ProductTopSection
            title="Software"
            description=""
            asset="assets/software.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Software'
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Topics 
                    items={[{
                            text: "LLEAP",
                            asset: "assets/lleap-hw.png",
                            heightPx: "144px",
                            link: "/software/lleap"
                        },{
                            text: "QCPR App",
                            asset: "assets/qcpr-app.png",
                            heightPx: "144px",
                            link: "/software/qcpr-app"
                        },
                        // {
                        //     text: "SimCapture",
                        //     asset: "assets/simcapture.png",
                        //     heightPx: "144px",
                        //     link: "/software/simcapture"
                        // },{
                        //     text: "Connect",
                        //     asset: "assets/connect.png",
                        //     heightPx: "144px",
                        //     link: "/software/connect"
                        // },
                        // {
                        //     text: "SimPad",
                        //     asset: "assets/simpad.png",
                        //     heightPx: "144px",
                        //     link: "/software/simpad"
                        // },
                        {
                            text: "TeamReporter",
                            asset: "assets/teamreporter.png",
                            heightPx: "144px",
                            link: "/software/teamreporter"
                        }
                    ]}
                />
                
                <CantFindWhatYouNeed />
                <BigSmallContent 
                    big={{
                        title: "Laerdal Global Warranty",
                        description: "In the event that your Laerdal Medical product needs warranty service, please contact Laerdal Medical or the Authorized Dealer from whom it was purchased. In order to avoid any unnecessary inconvenience on your part, we suggest reading the Directions for Use carefully before contacting Laerdal Medical or our Authorized Dealer.",
                        linkText: "PDF",
                        linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                    }}
                    small={{
                        title: "Privacy Statement",
                        description: "This statement describes the purposes for which personal information is collected, parties with whom we may share it, and measures we take to protect your personal information.",
                        linkText: "Read more",
                        linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                    }}/>
                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SoftwarePage;