import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LaerdalCompactSuctionUnitDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Medical devices',
                link: '/medical-devices'
            },{
                text: 'Laerdal Compact Suction Unit',
                link: '/medical-devices/laerdal-compact-suction-unit'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000005752 ", text:" Discontinuation of Laerdal Compact Suction Unit (LCSU3) Support" },
                        { link:"/articles/000007155 ", text:" Is the LCSU 4 serviceable?" },
                        { link:"/articles/000007165 ", text:" Is the LCSU 4 300 ml and 800 ml canister reusable?" },
                        { link:"/articles/000007126 ", text:" What is included in the LCSU 4 300 ml version?" },
                        { link:"/articles/000007146 ", text:" Is LCSU 4 supplied with a catheter?" },
                        { link:"/articles/000007158 ", text:" Is there a trouble shooting guide for the LCSU 4?" },
                        { link:"/articles/000007157 ", text:" How do you perform a Device Test for LCSU 4?" },
                        { link:"/articles/000007138 ", text:" Has the LCSU 4 been drop tested?" },
                        { link:"/articles/000007163 ", text:" Does the LCSU 4 have accessories or spare parts?" },
                        { link:"/articles/000007147 ", text:" Can LCSU 4 be used for gastrointestinal suction?" },
                        { link:"/articles/000007132 ", text:" Are LCSU 3 items compatible with the LCSU 4?" },
                        { link:"/articles/000007124 ", text:" How long does it take to charge the LCSU 4 battery?" },
                        { link:"/articles/000007141 ", text:" Is there a DC Power Cord for LCSU 4?" },
                        { link:"/articles/000007143 ", text:" What are the dimensions of the LCSU 4 carrying case?" },
                        { link:"/articles/000007127 ", text:" Which parts are required to convert LCSU 4 canister version?" },
                        { link:"/articles/000007142 ", text:" How many minutes of battery operation remain when the low-battery indicator light illuminates in RED on my LCSU 4?" },
                        { link:"/articles/000007164 ", text:" What is the IP rating for LCSU 4?" },
                        { link:"/articles/000007140 ", text:" What is included in the LCSU 4 800 ml version?" },
                        { link:"/articles/000007148 ", text:" What is the warranty for the LCSU 4?" },
                        { link:"/articles/000007139 ", text:" What are the filter specifications for LCSU 4 High Efficiency Filter Kit?" },
                        { link:"/articles/000007161 ", text:" What is the length of Patient Tube for LCSU 4?" },
                        { link:"/articles/000007134 ", text:" What materials are the LCSU 4 made of?" },
                        { link:"/articles/000007131 ", text:" What is the sound level of the LCSU 4?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LaerdalCompactSuctionUnitDocumentationPage;