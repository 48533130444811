import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SimMan3GEssentialBleedingPage = () => {
    return (
      <>
        <ProductTopSection
            title="SimMan 3G Essential Bleeding"
            description="SimMan Essential Bleeding is a patient simulation system that facilitates training of Basic and Advanced Life Support. This system allows the instructor to effectively assess the learner’s individual and team skills based on realistic clinical situations."
            asset="assets/simman-3g-essential-bleeding.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Male manikin',
                link: '/male-manikin'
            }, {
                text: 'SimMan 3G Essential Bleeding'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=340"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/articles/000002409"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/articles/000002463"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your SimMan 3G Essential Bleeding"
                    items={[{
                            title: "Can I use saline solution or just water to mix with simulated blood?",
                            subtitle: "",
                            link: "/articles/000002409"
                        },{
                            title: "How much blood fluid can be put into the SimMan Essential Bleeding?",
                            subtitle: "",
                            link: "/articles/000002463"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000002409 ", text:" Can I use saline solution or just water to mix with simulated blood?" },
                        { link:"/articles/000002463 ", text:" How much blood fluid can be put into the SimMan Essential Bleeding?" },                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kelly",
                            asset: "assets/megacode-kelly.png",
                            heightPx: "144px",
                            link: "/male-manikin/megacode-kelly"
                        },{
                            text: "SimMan 3G Essential",
                            asset: "assets/simman-3g-essential.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SimMan3GEssentialBleedingPage;