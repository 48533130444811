import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const FemaleManikinPage = () => {
    return (
      <>
        <ProductTopSection
            title="Female Manikin"
            description=""
            asset="assets/female-manikin-big.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female Manikin'
            }]}
            
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Topics 
                    items={[{
                            text: "Resusci Anne Advanced SkillTrainer",
                            asset: "assets/resusci-anne-advanced-skilltrainer.png",
                            heightPx: "114px",
                            link: "/female-manikin/resusci-anne-advanced-skilltrainer"
                        },{
                            text: "Resusci Anne QCPR",
                            asset: "assets/resusci-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-qcpr"
                        },{
                            text: "Resusci Anne Simulator",
                            asset: "assets/resusci-anne-simulator.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-simulator"
                        },{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-qcpr"
                        },{
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        },{
                            text: "Mini Anne Plus",
                            asset: "assets/mini-anne-plus.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne-plus"
                        },{
                            text: "Nursing Anne",
                            asset: "assets/nursing-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/nursing-anne"
                        },{
                            text: "Nursing Anne Simulator",
                            asset: "assets/nursing-anne-simulator.png",
                            heightPx: "144px",
                            link: "/female-manikin/nursing-anne-simulator"
                        },
                    ]}
                />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005565", text:" What are the clinical features for Resusci Anne Simulator?" },
                        { link:"/articles/000002585", text:" Does the Resusci Anne Simulator contain latex?" },
                        { link:"/articles/000001894", text:" What circulation features does Resusci Anne Simulator have?" },
                        { link:"/articles/000001591", text:" What is the warranty on the Resusci Anne Simulator with SimPad?" },
                        { link:"/articles/000001471", text:" What Resusci Anne Simulator BP readings are controlled by SimPad?" },
                        { link:"/articles/000001437", text:" What Resusci Anne Simulator pulses are controlled by SimPad?" },
                        { link:"/articles/000002304", text:" Why did I receive a manual air pump with the Resusci Anne Simulator?" },
                        { link:"/articles/000001383", text:" Will I damage the compressed air container in the Resusci Anne Simulator if I overfill it?" },
                        { link:"/articles/000007180", text:" Where is the serial number located on my Resusci Anne Simulator / Resusci Anne Advanced SkillTrainer?" },
                        { link:"/articles/000006036", text:" Material Specifications for Resusci Anne Simulator" },
                    ]}  
                    />
                <CantFindWhatYouNeed />
                <BigSmallContent 
                    big={{
                        title: "Laerdal Global Warranty",
                        description: "In the event that your Laerdal Medical product needs warranty service, please contact Laerdal Medical or the Authorized Dealer from whom it was purchased. In order to avoid any unnecessary inconvenience on your part, we suggest reading the Directions for Use carefully before contacting Laerdal Medical or our Authorized Dealer.",
                        linkText: "PDF",
                        linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                    }}
                    small={{
                        title: "Privacy Statement",
                        description: "This statement describes the purposes for which personal information is collected, parties with whom we may share it, and measures we take to protect your personal information.",
                        linkText: "Read more",
                        linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                    }}/>
                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default FemaleManikinPage;