import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SimManALSPage = () => {
    return (
      <>
        <ProductTopSection
            title="SimMan ALS"
            description="SimMan ALS is a realistic interactive Training Simulator for simulating a wide range of advanced life saving skills in pre-hospital emergencies. It is tetherless, Wifi operated, with a flexible control solution (SimPad PLUS or Laerdal Learning Application (LLEAP)) depending on training needs. The simulator responds to clinical intervention, instructor control, and pre-programmed scenarios for effective practice of diagnostic skills and patient treatment."
            asset="assets/simman-als.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Male manikin',
                link: '/male-manikin'
            }, {
                text: 'SimMan ALS'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=479"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/simman-als-how-to-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/male-manikin/simman-als/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/male-manikin/simman-als/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"ShockLink",
                            link:"/male-manikin/simman-als/shocklink"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"SimPad Unit",
                            link:"/male-manikin/simman-als/simpad-unit"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Software",
                            link:"/male-manikin/simman-als/software"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/male-manikin/simman-als/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your SimMan ALS"
                    items={[{
                            title: "What are the clinical features for SimMan ALS?",
                            subtitle: "",
                            link: "/articles/000005590"
                        },{
                            title: "Will the additional battery added to the side panel of SimMan ALS be charged when the simulator is connected to AC power?",
                            subtitle: "",
                            link: "/articles/000003516"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003482 ", text:" Can I use other SimMan 3G modules like the trauma modules on my SimMan ALS?" },
                        { link:"/articles/000003514 ", text:" Will the SimMan ALS run indefinitely if connected to AC power with or without an internal battery connected?" },
                        { link:"/articles/000005590 ", text:" What are the clinical features for SimMan ALS?" },
                        { link:"/articles/000003516 ", text:" Will the additional battery added to the side panel of SimMan ALS be charged when the simulator is connected to AC power?" },                            
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kelly",
                            asset: "assets/megacode-kelly.png",
                            heightPx: "144px",
                            link: "/male-manikin/megacode-kelly"
                        },{
                            text: "SimMan 3G Essential",
                            asset: "assets/simman-3g-essential.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential"
                        },{
                            text: "SimMan 3G Essential Bleeding",
                            asset: "assets/simman-3g-essential-bleeding.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential-bleeding"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SimManALSPage;