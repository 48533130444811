import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const StifneckSelectPage = () => {
    return (
      <>
        <ProductTopSection
            title="Stifneck Select"
            description="This product is intended to support cervical spine in a neutral position during transportation, in combination with other cervical and full body immobilization devices (CSIDs and FBSIDs)."
            asset="assets/stifneck-select.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'Stifneck Select'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=281"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <GetToKnow 
                    title="Get to know your Stifneck Select"
                    items={[{
                            title: "Has Laerdal classified any of the medical devices with a HCPCS code?",
                            subtitle: "",
                            link: "/articles/000006230"
                        },{
                            title: "Is the Stifneck Select a reusable collar?",
                            subtitle: "",
                            link: "/articles/000007040"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006230 ", text:" Has Laerdal classified any of the medical devices with a HCPCS code?" },
                        { link:"/articles/000007042 ", text:" Do the Stifneck Select Collars contain latex?" },
                        { link:"/articles/000007045 ", text:" Can the size be changed if a Stifneck Select Collar is snapped into a particular size that is incorrect for the patient?" },
                        { link:"/articles/000007040 ", text:" Is the Stifneck Select a reusable collar?" },
                        { link:"/articles/000007048 ", text:" What is the difference between Stifneck Select and Stifneck Extrication Collars?" },
                        { link:"/articles/000007047 ", text:" What is the intended use and indication of use for the Stifneck Select and Stifneck Extrication Collar?" },                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default StifneckSelectPage;