import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneSimulatorHeadPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne Simulator',
                link: '/female-manikin/resusci-anne-simulator'
            },{
                text: 'Head',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000002014 ", text:" Are there any special lubrication recommendations when performing airway techniques on the Resusci Anne Simulator?" },
                        { link:"/articles/000002426 ", text:" Can I intubate the Resusci Anne Simulator?" },
                        { link:"/articles/000001296 ", text:" How does the airway obstruction feature work in the Resusci Anne Simulator?" },
                        { link:"/articles/000001908 ", text:" What airway devices can be used with Resusci Anne Simulator?" },
                        { link:"/articles/000001542 ", text:" What airway techniques can I perform on the Resusci Anne Simulator?" },
                        { link:"/articles/000001652 ", text:" What is the part number for the Head speaker for Resusci Anne Simulator?" },
                        { link:"/articles/000004030 ", text:" What is the appropriate i-gel size for Airway Head on Resusci Anne Simulator?" },
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneSimulatorHeadPage;