import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ThomasTubeHolderPage = () => {
    return (
      <>
        <ProductTopSection
            title="Thomas TubeHolder"
            description="Thomas Select Tube Holder is designed to secure advanced airway devices, e.g. Endotracheal Tubes (ETs), Laryngeal Mask Airways (LMAs) and other wider Supraglottic Airway devices (SGAs), to reduce the risk of accidental extubation. It is intended for use on persons of at least 8 years old with a short-term need (less than 24 hours) for airway-tube device management."
            asset="assets/thomas-tubeholder.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'Thomas TubeHolder'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=284"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <GetToKnow 
                    title="Get to know your Thomas TubeHolder"
                    items={[{
                            title: "What is the intended use of Thomas Select Tube Holder?",
                            subtitle: "",
                            link: "/articles/000007076"
                        },{
                            title: "What are the benefits of using the Thomas Select Tube Holder?",
                            subtitle: "",
                            link: "/articles/000007074"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000007075 ", text:" Does the Thomas Select Tube Holder replace the existing Thomas Tube Holder?" },
                        { link:"/articles/000007082 ", text:" Can the Thomas Select Tube Holder be used on children?" },
                        { link:"/articles/000007078 ", text:" Can the Thomas Select Tube Holder be re-used?" },
                        { link:"/articles/000007081 ", text:" Does the Thomas Select Tube Holder contain latex?" },
                        { link:"/articles/000007070 ", text:" Does Thomas Tube Holder contain latex?" },
                        { link:"/articles/000007076 ", text:" What is the intended use of Thomas Select Tube Holder?" },
                        { link:"/articles/000007074 ", text:" What are the benefits of using the Thomas Select Tube Holder?" },
                        { link:"/articles/000007080 ", text:" What are the features of Thomas Select Tube Holder?" },
                        { link:"/articles/000007079 ", text:" Is Thomas Select Tube Holder in compliance with any standards?" },
                        { link:"/articles/000007071 ", text:" What are the largest and smallest endotracheal tube sizes recommended for the Thomas Tube Holder?" },
                        { link:"/articles/000007072 ", text:" Are there any age restrictions for the Thomas Tube Holder (TTH)?" },
                        { link:"/articles/000007083 ", text:" What materials are the Thomas Select Tube Holder made off?" },
                        { link:"/articles/000007077 ", text:" What size Endotracheal (ET) tubes can be used with Thomas Select Tube Holder?" },
                        { link:"/articles/000007073 ", text:" What size Supraglottic Airway Devices can be accommodated with the Thomas Select Tube Holder?" },                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default ThomasTubeHolderPage;