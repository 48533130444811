import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LittleAnneQcprDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Little Anne QCPR',
                link: '/female-manikin/little-anne-qcpr'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000006027 ", text:" Little Anne QCPR - Introduction video" },
                        { link:"/articles/000003657 ", text:" What is the difference between Little Anne QCPR and Resusci Anne QCPR" },
                        { link:"/articles/000004231 ", text:" What is Compressions Only CPR protocol?" },
                        { link:"/articles/000004216 ", text:" What is 30:2 CPR protocol?" },
                        { link:"/articles/000006282 ", text:" Does Little Anne, Little Anne QCPR, Little Junior QCPR and Little Baby QCPR manikins comply with 2020 Guidelines?" },
                        { link:"/articles/000003644 ", text:" Battery run time for Little Anne QCPR, Little Junior QCPR and Little Baby QCPR" },
                        { link:"/articles/000003649 ", text:" How does Little Anne QCPR and Little Junior QCPR work?" },
                        { link:"/articles/000003648 ", text:" LOT and Serial number labels for Little Anne QCPR and Little Junior QCPR" },
                        { link:"/articles/000003645 ", text:" Life time expectancy and endurance of Little Anne QCPR, Little Junior QCPR and Little Baby QCPR" },
                        { link:"/articles/000003646 ", text:" User Guide for Little Anne (all versions)" },
                        { link:"/articles/000006026 ", text:" What is Little Anne QCPR?" },
                        { link:"/articles/000003938 ", text:" Little Anne QCPR and Little Junior QCPR material specifications" },
                        { link:"/articles/000001156 ", text:" What is QCPR?" },                        
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LittleAnneQcprDocumentationPage;