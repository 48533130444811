import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LaerdalSuctionUnitDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Medical devices',
                link: '/medical-devices'
            },{
                text: 'Laerdal Suction Unit',
                link: '/medical-devices/laerdal-suction-unit'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000003768 ", text:" Is the LSU Wall Bracket SAE 3043 compliant?" },
                        { link:"/articles/000007120 ", text:" How is the LSU packaged?" },
                        { link:"/articles/000007096 ", text:" Does a Shoulder Strap come with the LSU Carry Bag?" },
                        { link:"/articles/000007102 ", text:" Does LSU contain latex?" },
                        { link:"/articles/000007112 ", text:" Do you have a picture of the LSU User Interface with labelling?" },
                        { link:"/articles/000007110 ", text:" How to perform LSU Device test?" },
                        { link:"/articles/000007101 ", text:" Is there a parts overview for the Laerdal Suction Unit (LSU)?" },
                        { link:"/articles/000007109 ", text:" Is there a troubleshooting guide for LSU?" },
                        { link:"/articles/000007088 ", text:" What is the length of LSU DC power cord?" },
                        { link:"/articles/000007113 ", text:" What does the LSU Battery Status indicate?" },
                        { link:"/articles/000007118 ", text:" How should I clean LSU with Serres canister?" },
                        { link:"/articles/000007119 ", text:" How should I clean LSU with Bemis canister?" },
                        { link:"/articles/000007117 ", text:" How should I clean LSU Reusable?" },
                        { link:"/articles/000007100 ", text:" What are the recommended LSU routines for battery charging?" },
                        { link:"/articles/000007094 ", text:" Is the Laerdal Suction Unit (LSU) safe for use in aircrafts?" },
                        { link:"/articles/000007093 ", text:" What is the catalog number for the LSU Carry Bag?" },
                        { link:"/articles/000007098 ", text:" What components do I use with the Serres Canister configuration for the LSU?" },
                        { link:"/articles/000007095 ", text:" What is the length and size of the disposable suction tubing?" },
                        { link:"/articles/000007111 ", text:" Why does my LSU shut down with a flashing Power ON indicator?" },
                        { link:"/articles/000007099 ", text:" Why does my LSU not operate with either the AC or DC Power Cord connected?" },
                        { link:"/articles/000007108 ", text:" What is the sound level generated by the LSU?" }
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LaerdalSuctionUnitDocumentationPage;