import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimManALSTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Male manikin',
                link: '/male-manikin'
            },{
                text: 'SimMan ALS',
                link: '/male-manikin/simman-als'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000003490 ", text:" Can I add an additional battery to SimMan ALS to extend use?" },
                        { link:"/articles/000003494 ", text:" Can I perform Anterior/Posterior defibrillation on SimMan ALS?" },
                        { link:"/articles/000003486 ", text:" Can I update my SimMan ALS with defibrillation studs for use of a defibrillation training cable and paddles?" },
                        { link:"/articles/000003491 ", text:" Can I use the defibrillation paddles on SimMan ALS?" },
                        { link:"/articles/000003947 ", text:" Can Zoll defibrillators be used with different Laerdal manikins?" },
                        { link:"/articles/000003495 ", text:" Does SimMan ALS contain latex?" },
                        { link:"/articles/000003510 ", text:" Does the SimMan ALS chest skin with SonoSim RFID tags change how I connect ECG leads?" },
                        { link:"/articles/000003507 ", text:" How do I know how much battery life is left for SimMan ALS?" },
                        { link:"/articles/000003505 ", text:" How long does it take to charge the battery within the SimMan ALS if it is not being used?" },
                        { link:"/articles/000004268 ", text:" How to connect External Battery and change Internal Battery for SimMan ALS?" },
                        { link:"/articles/000006153 ", text:" What has happened with the green patches inside the SimMan ALS Chest Skin?" },
                        { link:"/articles/000003502 ", text:" What is the battery life for SimMan ALS?" },
                        { link:"/articles/000003501 ", text:" What would happen if I defibrillate SimMan ALS with paddles?" },
                        { link:"/articles/000003509 ", text:" When I activate left or right tension pneumothorax on the software both the pneumothorax bladders inflate. Is this normal for SimMan ALS?" },
                        { link:"/articles/000003513 ", text:" When I switch off the SimMan ALS there is an air leakage sound. Is this normal?" },
                        { link:"/articles/000003504 ", text:" What are the small red marks on the SimMan ALS torso skin?" }
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimManALSTorsoPage;