import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const MegaCodeKidPage = () => {
    return (
      <>
        <ProductTopSection
            title="MegaCode Kid"
            description="MegaCode Kid is a realistic manikin for training in a wide range of pediatric advanced life saving skills in pre-hospital emergencies."
            asset="assets/megacode-kid.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            }, {
                text: 'MegaCode Kid'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=142"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/kid-junior-manikin/megacode-kid/documentation"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/kid-junior-manikin/megacode-kid/head"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/articles/000001193"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your MegaCode Kid"
                    items={[{
                            title: "What is the difference between ECG Kid and MegaCode Kid?",
                            subtitle: "",
                            link: "/articles/000001988"
                        },{
                            title: "Where is the serial number located on MegaCode Kid?",
                            subtitle: "",
                            link: "/articles/000006204"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003701 ", text:" What is MegaCode Kid's weight?" },
                        { link:"/articles/000001988 ", text:" What is the difference between ECG Kid (231-15050) and MegaCode Kid (231-05050)?" },
                        { link:"/articles/000006204 ", text:" Where is the serial number located on MegaCode Kid?" },
                        { link:"/articles/000001324 ", text:" Where can I find a copy of the User Guide (DfU) for MegaCode Kid (231-05050)?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kid",
                            asset: "assets/megacode-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/megacode-kid"
                        },{
                            text: "Nursing Kid",
                            asset: "assets/nursing-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/nursing-kid"
                        },{
                            text: "Resusci Junior QCPR",
                            asset: "assets/resusci-junior-qcpr.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/resusci-junior-qcpr"
                        },{
                            text: "SimJunior",
                            asset: "assets/sim-junior.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/sim-junior"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default MegaCodeKidPage;