import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimBabyMKIIDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'SimBaby MK II',
                link: '/baby-newborn-manikin/simbaby-mk-ii'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[{
                        text:"Are 'how-to' videos available for SimBaby?",
                        link:"/articles/000005581"
                    },{
                        text:"What is the recommended ventilation volume for SimBaby when connected to a ventilator?",
                        link:"/articles/000005664"
                    },{
                        text:"What is the recommended setting for breaths per minute on a ventilator?",
                        link:"/articles/000005663"
                    },{
                        link:"/articles/000004092",
                        text:"Can I get a replacement User Guide for my SimBaby?"
                    },{
                        link:"/articles/000004109",
                        text:"Is the new SimBaby available in different skin tones?"
                    },{
                        link:"/articles/000004111",
                        text:"Does the SimBaby contain any latex parts on the simulator or the consumables?"
                    },{
                        link:"/articles/000004083",
                        text:"What devices can I control my SimBaby with?"
                    },{
                        link:"/articles/000004084",
                        text:"What is the length and weight of SimBaby?"
                    },{
                        link:"/articles/000006206",
                        text:"Where is the serial number located on SimBaby?"
                    },{
                        link:"/articles/000004089",
                        text:"What parts are available for the new SimBaby?"
                    },{
                        link:"/articles/000004100",
                        text:"What size needle is recommended when using with the new SimBaby IO leg?"
                    },{
                        link:"/articles/000004093",
                        text:"Is the User Guide for SimBaby available in any other languages than English?"
                    },

                    ]}/>

                    <CantFindCreateCase />

            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimBabyMKIIDocumentationPage;