import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const AedTrainer2Page = () => {
    return (
      <>
        <ProductTopSection
            title="AED Trainer 2"
            description="The Laerdal AED Trainer 2 is designed to prepare emergency responders to use the Heartstart FR2 AED. This Trainer provides a variety of simulations, or training scripts, to help responders become familiar with the AED and allow them to demonstrate the basic skills necessary to use the AED in an emergency."
            asset="assets/aed-trainer-2.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'AED Trainer',
                link: '/training-devices/aed-trainer'
            }, {
                text: 'AED Trainer 2'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=265"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/training-devices/aed-trainer/aed-trainer-2/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Software",
                            link:"/training-devices/aed-trainer/aed-trainer-2/software"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Pads",
                            link:"/articles/000001905"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your AED Trainer 2"
                    items={[{
                            title: "What is AED Trainer 2?",
                            subtitle: "",
                            link: "/articles/000006037"
                        },{
                            title: "AED Trainer 2 material specifications",
                            subtitle: "",
                            link: "/articles/000003981"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005530 ", text:" User Guide for AED Trainer 2" },
                        { link:"/articles/000004122 ", text:" AED Trainer 2 weight and size dimensions" },
                        { link:"/articles/000002231 ", text:" Can AED Trainer 2 remote control be used with AED Trainer 3?" },
                        { link:"/articles/000002286 ", text:" Why does the AED Trainer 2 say \"paused\" after delivering a shock?" },
                        { link:"/articles/000003981 ", text:" AED Trainer 2 material specifications" },
                        { link:"/articles/000006037 ", text:" What is AED Trainer 2?" },
                        { link:"/articles/000007329 ", text:" Battery Runtime for AED Trainer 2" },

                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer"
                        },{
                            text: "AED Trainer 3",
                            asset: "assets/aed-trainer-3.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer-3"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default AedTrainer2Page;