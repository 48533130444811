import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const MiniAnnePlusPage = () => {
    return (
      <>
        <ProductTopSection
            title="Mini Anne Plus"
            description="Mini Anne Plus is a low cost, CPR training solution, for Basic Life Support (BLS) training. The kit is portable, allowing for easy storage in one convenient carry bag, and transportation from classroom to classroom. It is designed specifically to meet the needs of organizations and schools. The solution is reusable, hygienic, and easy to use."
            asset="assets/mini-anne-plus.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Female manikin',
                link: '/female-manikin'
            }, {
                text: 'Mini Anne Plus'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=390"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/female-manikin/mini-anne-plus/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/female-manikin/mini-anne-plus/consumables"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Inflatable Body complete",
                            link:"/female-manikin/mini-anne-plus/inflatable-body-complete"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Mini Anne Plus"
                    items={[{
                            title: "User Guide for Mini Anne Plus",
                            subtitle: "",
                            link: "/articles/000005533"
                        },{
                            title: "What are the product features for Mini Anne Plus?",
                            subtitle: "",
                            link: "/articles/000006010"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006010 ", text:" What are the product features for Mini Anne Plus?" },
                        { link:"/articles/000001634 ", text:" Is there a list of all part numbers for Mini Anne Plus?" },
                        { link:"/articles/000001978 ", text:" What are the weight and dimensions for the Kneel Mats for Mini Anne Plus?" },
                        { link:"/articles/000001783 ", text:" What's the difference between CPR Anytime and Mini Anne Plus?" },
                        { link:"/articles/000001668 ", text:" What's the difference between the AHA's CPR in Schools kit and Mini Anne Plus?" },
                        { link:"/articles/000001997 ", text:" What are the specifications/characteristics of the Mini Anne Plus Compression Clicker?" },
                        { link:"/articles/000006019 ", text:" What is Mini Anne Plus?" },
                        { link:"/articles/000003989 ", text:" Mini Anne Plus material specifications" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne-plus"
                        },{
                            text: "Resusci Anne QCPR",
                            asset: "assets/mini-anne-plus.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne-plus"
                        },
                        {
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default MiniAnnePlusPage;