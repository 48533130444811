import { BREAKPOINTS, COLORS } from '@laerdal/life-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const FaqContainer = styled.div`
 
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.white};

  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  
`;
const FaqList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
const FaqTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; 
`;
const FaqLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; 
`;

interface FaqProps {
  title: string,
  items: FaqItem[]
}
interface FaqItem {
  text: string;
  link: string;
}


const Faqs = ({title, items}: FaqProps) => {    
  const navigate = useNavigate()
  
    return (
        <FaqContainer>
          <FaqList>
            <FaqTitle>{title}</FaqTitle>
            { items.map((item, index) =>
                <FaqLink 
                  onClick={(e)=>{
                    e.preventDefault();
                    navigate(item.link!);
                  }}
                  href={item.link} 
                  key={`link-${index}`}>
                  {item.text}
                </FaqLink>
              )
            }
          </FaqList>
        </FaqContainer>
    );
}

export default Faqs;