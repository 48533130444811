import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const HeartStartTrainerPage = () => {
    return (
      <>
        <ProductTopSection
            title="HeartStart Trainer"
            description="The HeartStart Trainer M5086A is designed to prepare emergency responders to use the HeartStart HS1 Defibrillators, including the Laerdal HeartStart Defibrillator M5067A.The HeartStart Trainer cannot be used to deliver defibrillation treatment."
            asset="assets/heartstart-trainer.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'HeartStart Trainer'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=251"
        />


        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    }]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default HeartStartTrainerPage;