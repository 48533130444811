import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const NursingAnneSimulatorPage = () => {
    return (
      <>
        <ProductTopSection
            title="Nursing Anne Simulator"
            description="Nursing Anne Simulator and Nursing Anne Simulator Geriatric are realistic, interactive training simulators to educate healthcare professionals to initiate and maintain care for patients, within the fundamentals of nursing curricula. It is tetherless, WiFi operated, with a flexible operating system depending on training needs. The simulator responds to clinical intervention, instructor control, and preprogrammed scenarios for effective practice."
            asset="assets/nursing-anne-simulator.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Female manikin',
                link: '/female-manikin'
            }, {
                text: 'Nursing Anne Simulator'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=521"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/nursing-anne-simulator-how-to-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/female-manikin/nursing-anne-simulator/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/female-manikin/nursing-anne-simulator/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/female-manikin/nursing-anne-simulator/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Arms",
                            link:"/female-manikin/nursing-anne-simulator/arms"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Legs",
                            link:"/female-manikin/nursing-anne-simulator/legs"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/female-manikin/nursing-anne-simulator/torso"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Pelvis",
                            link:"/female-manikin/nursing-anne-simulator/pelvis"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Nursing Anne Simulator"
                    items={[{
                            title: "Overview of Nursing Anne Simulator Control Panel and Label",
                            subtitle: "",
                            link: "/articles/000004287"
                        },{
                            title: "What type of equipment and sizes do fit Nursing Anne Simulator?",
                            subtitle: "",
                            link: "/articles/000005863"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000004102 ", text:" What Moulage products work well on Nursing Anne Simulator?" },
                        { link:"/articles/000004079 ", text:" Can Betadine/Iodine be used on the Nursing Anne Simulator?" },
                        { link:"/articles/000003992 ", text:" Does Nursing Anne Simulator contain latex?" },
                        { link:"/articles/000004287 ", text:" Overview of Nursing Anne Simulator Control Panel and Label" },
                        { link:"/articles/000006176 ", text:" Where to find the Serial Number on Nursing Anne Simulator?" },
                        { link:"/articles/000003999 ", text:" Are there temperature limits or environmental conditions to consider for operation of or storing Nursing Anne Simulator?" },
                        { link:"/articles/000005863 ", text:" What type of equipment and sizes do fit Nursing Anne Simulator?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/nursing-anne-simulator"
                        },{
                            text: "Resusci Anne QCPR",
                            asset: "assets/nursing-anne-simulator.png",
                            heightPx: "144px",
                            link: "/female-manikin/nursing-anne-simulator"
                        },
                        {
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default NursingAnneSimulatorPage;