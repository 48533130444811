import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../../commonComponents/Topics";
import Subtopics from "../../../../../commonComponents/Subtopics";
import Faqs from "../../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const AedTrainer3DocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'AED Trainer',
                link: '/training-devices/aed-trainer'
            }, {
                text: 'AED Trainer 3',
                link: '/training-devices/aed-trainer/aed-trainer-3'
            }, {
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Documentation"
                    items={[ 
                        { link:"/articles/000005531 ", text:" User Guide for AED Trainer 3" },
                        { link:"/articles/000006035 ", text:" What is AED Trainer 3?" },
                        { link:"/articles/000004197 ", text:" Training scenarios for AED Trainer 3" },
                        { link:"/articles/000001657 ", text:" What is the intended use for AED Trainer 3" },
                        { link:"/articles/000002299 ", text:" What languages are available for AED Trainer 2 and 3?" },
                        { link:"/articles/000005424 ", text:" Can AED Trainer 3 and other AED Trainers be used with RA AST and RA Simulator?" },
                        { link:"/articles/000006328 ", text:" How to use the Laerdal AED Trainer 3 for the first time?" },
                        { link:"/articles/000006285 ", text:" Do AED Trainers comply with 2020 Guidelines?" },
                        { link:"/articles/000003988 ", text:" AED Trainer 3 material specifications" },
                        { link:"/articles/000007330 ", text:" Battery Runtime for AED Trainer 3" },
                    ]}/>
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default AedTrainer3DocumentationPage;