import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const LleapPage = () => {
    return (
      <>
        <ProductTopSection
            title="LLEAP"
            description="With LLEAP, instructors have control over the patient simulator's physiology, giving a seamless and immersive training experience for the learners. Fully integrated with Laerdal’s simulators, accessory products, Laerdal Scenario Cloud, and SimCapture, LLEAP allows you to control the physiology of the simulator and every other parameter of your training session - both in-situ and in simulation center settings."
            asset="assets/lleap-hw.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Software',
                link: '/software'
            }, {
                text: 'LLEAP'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=423"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/lleap-how-to-videos/"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Computer",
                            link:"/software/lleap/computer"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Software",
                            link:"/software/lleap/software"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your LLEAP"
                    items={[{
                            title: "Is LLEAP compatible with my PC?",
                            subtitle: "",
                            link: "/articles/000002358"
                        },{
                            title: "How to setup a WiFi network for PC and SimPad PLUS?",
                            subtitle: "",
                            link: "/articles/000005668"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006302 ", text:" How to find the IP Addresses on my Laptop/PC?" },
                        { link:"/articles/000002358 ", text:" Is LLEAP compatible with my PC?" },
                        { link:"/articles/000006275 ", text:" Is the All-in-One (AiO) PC VESA compatible?" },
                        { link:"/articles/000002573 ", text:" How to display the patient monitor on another screen or projector?" },
                        { link:"/articles/000005668 ", text:" How to setup a WiFi network for PC and SimPad PLUS?" },
                        { link:"/articles/000006880 ", text:" Why does my computer display message that IT is controlling it?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Shock Link",
                            asset: "assets/shock-link.png",
                            heightPx: "144px",
                            link: "/software/shock-link"
                        },{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/software/aed-trainer"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default LleapPage;