import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const TeamReporterPage = () => {
    return (
      <>
        <ProductTopSection
            title="TeamReporter"
            description="Video-based app designed for high performance CPR training (team training).Get 2 Videos at the end: full video (video and audio) + Interaction timeline. Video of Highlight reels - with condensed timeline with target feedback areas and recommendations.Highlight reel can take time to process (processed on device!) We want to move video processing to the cloud - to avoid spec problems (eg. memory)"
            asset="assets/teamreporter.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Software',
                link: '/software'
            }, {
                text: 'TeamReporter'
            }]}
        />

        <FullNeutralPageContainer>
            <PageContainer>
              
                <GetToKnow 
                    title="Get to know your TeamReporter"
                    items={[{
                            title: "What is TeamReporter?",
                            subtitle: "",
                            link: "/articles/000006039"
                        },{
                            title: "TeamReporter App compatible devices",
                            subtitle: "",
                            link: "/articles/000006023"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005982 ", text:" Can I change the name and password of my TeamReporter users?" },
                        { link:"/articles/000005977 ", text:" Can I cancel my TeamReporter license early or reduce the number of users?" },
                        { link:"/articles/000006014 ", text:" Can I use the TeamReporter App on a Tablet or iPad?" },
                        { link:"/articles/000005980 ", text:" Can I use the same TeamReporter account in two different devices at the same time?" },
                        { link:"/articles/000005985 ", text:" Does Laerdal collect personal data in the TeamReporter App?" },
                        { link:"/articles/000005981 ", text:" How do I create new users in the TeamReporter Web Portal?" },
                        { link:"/articles/000005991 ", text:" How do I get started with the TeamReporter App?" },
                        { link:"/articles/000005993 ", text:" How do I know if my manikin works with the TeamReporter App?" },
                        { link:"/articles/000005995 ", text:" How does the score in the TeamReporter App work?" },
                        { link:"/articles/000005978 ", text:" How often do I need to renew my TeamReporter license?" },
                        { link:"/articles/000005983 ", text:" I forgot my TeamReporter App password, what do I need to do?" },
                        { link:"/articles/000005979 ", text:" I just got money from a grant, can I purchase more than 1 year of access to TeamReporter App?" },
                        { link:"/articles/000005986 ", text:" Is my data protected in the TeamReporter App?" },
                        { link:"/articles/000005989 ", text:" Is it possible for me to try the TeamReporter App?" },
                        { link:"/articles/000005997 ", text:" TeamReporter FAQs - Subscription and Payment" },
                        { link:"/articles/000005996 ", text:" TeamReporter FAQs - Get started with TeamReporter App" },
                        { link:"/articles/000005998 ", text:" TeamReporter FAQs - Data Privacy in TeamReporter" },
                        { link:"/articles/000006023 ", text:" TeamReporter App compatible devices" },
                        { link:"/articles/000005976 ", text:" What happens if I do not renew my TeamReporter license?" },
                        { link:"/articles/000005994 ", text:" What is the difference between TeamReporter and SkillReporter App?" },
                        { link:"/articles/000005992 ", text:" What is the difference between an Administrator and a User in the TeamReporter?" },
                        { link:"/articles/000005984 ", text:" Why is data privacy relevant for me?" },
                        { link:"/articles/000005990 ", text:" Will I be invoiced if I forget to cancel my TeamReporter trial?" },
                        { link:"/articles/000006015 ", text:" Why is TeamReporter App requesting access to my Bluetooth, Location and Camera?" },
                        { link:"/articles/000006304 ", text:" TeamReporter Login Troubleshouting" },
                        { link:"/articles/000005987 ", text:" Where is my TeamReporter data stored?" },
                        { link:"/articles/000006303 ", text:" How to subscribe to TeamReporter ?" },
                        { link:"/articles/000006831 ", text:" How to start with TeamReporter as an Instructor ?" },
                        { link:"/articles/000006039 ", text:" What is TeamReporter?" },

                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "LLEAP",
                            asset: "assets/lleap-hw.png",
                            heightPx: "144px",
                            link: "/software/lleap"
                        },{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/software/aed-trainer"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default TeamReporterPage;