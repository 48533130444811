import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneSimulatorAccessoriesPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne Simulator',
                link: '/female-manikin/resusci-anne-simulator'
            },{
                text: 'Accessories',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000002059 ", text:" Can the Resusci Anne Simulator be used with an external microphone?" },
                        { link:"/articles/000001299 ", text:" Can the Resusci Anne Simulator be upgraded with wound modules for first aid training?" },
                        { link:"/articles/000002354 ", text:" Do I have to use the manual air pump to fill the Resusci Anne Simulators compressed air container?" },
                        { link:"/articles/000001681 ", text:" Do I have to use AC power to operate the Resusci Anne Simulator?" },
                        { link:"/articles/000003930 ", text:" How to change the router channel?" },
                        { link:"/articles/000001856 ", text:" Is there a power supply available for the Resusci Anne Simulator?" },
                        { link:"/articles/000001752 ", text:" Why does the external power supply for the Resusci Anne Simulator shut down during use?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneSimulatorAccessoriesPage;