import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    max-width:1000px;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const MegaCodeKellyPage = () => {
    return (
      <>
        <ProductTopSection
            title="MegaCode Kelly"
            description="A full-body manikin designed for the practice of advanced, difficult and obstructed airway scenarios and IV therapy. MegaCode Kelly with SimPad PLUS allows cardiac defibrillation, pacing and ECG interpretation of over 1000 rhythm variations."
            asset="assets/megacode-kelly.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Male manikin',
                link: '/male-manikin'
            }, {
                text: 'MegaCode Kelly'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=139"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/male-manikin/megacode-kelly/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/male-manikin/megacode-kelly/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Arms",
                            link:"/male-manikin/megacode-kelly/arms"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/male-manikin/megacode-kelly/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/male-manikin/megacode-kelly/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your MegaCode Kelly"
                    items={[{
                            title: "What is the difference in functionality between the Megacode Kelly Basic and Advanced manikins?",
                            subtitle: "",
                            link: "/articles/000003269"
                        },{
                            title: "Where is the serial number located on MegaCode Kelly?",
                            subtitle: "",
                            link: "/articles/000006203"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003697 ", text:" What is MegaCode Kelly's weight?" },
                        { link:"/articles/000003258 ", text:" What is the difference between the two black bulbs on MegaCode Kelly manikins?" },
                        { link:"/articles/000003269 ", text:" What is the difference in functionality between the Megacode Kelly Basic and Advanced manikins?" },
                        { link:"/articles/000001812 ", text:" What items ship with the Adult IO Leg (205-10150)?" },
                        { link:"/articles/000006203 ", text:" Where is the serial number located on MegaCode Kelly?" },
                        { link:"/articles/000003276 ", text:" What ships with the MegaCode Kelly Advanced (200-05050) manikin?" },
                        { link:"/articles/000003271 ", text:" What ships with the MegaCode Kelly Basic (203-05050) manikins?" },
                        { link:"/articles/000003274 ", text:" Where can I find a copy of the User Guide (DfU) for MegaCode Kelly?" }                     
                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "SimMan 3G Essential",
                            asset: "assets/simman-3g-essential.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential"
                        },{
                            text: "SimMan 3G Essential Bleeding",
                            asset: "assets/simman-3g-essential-bleeding.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential-bleeding"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default MegaCodeKellyPage;