import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LittleAnneQcprTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Little Anne QCPR',
                link: '/female-manikin/little-anne-qcpr'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000003792 ", text:" Software release - Firmware updates for QCPR Sensor" },
                        { link:"/articles/000005558 ", text:" What is correct Hand placement?" },
                        { link:"/articles/000006326 ", text:" How to change battery in my Little Anne QCPR?" },
                        { link:"/articles/000002643 ", text:" How does compression clicker function for Little Anne QCPR and Little Junior QCPR?" },
                        { link:"/articles/000003928 ", text:" Little Anne QCPR and Little Junior QCPR Sensor does not register ventilations" },
                        { link:"/articles/000006817 ", text:" Color change for Little Anne QCPR back section" },
                        { link:"/articles/000004039 ", text:" What is the QCPR Ventilation sticker?" },
                        { link:"/articles/000005600 ", text:" What is the QCPR Compression reflector?" },
                        { link:"/articles/000003863 ", text:" Little Anne QCPR or Little Junior QCPR firmware update has failed, the Sensor does not power ON" },
                        { link:"/articles/000005550 ", text:" Compression and ventilation specifications for Little Anne, Little Anne QCPR, Little Junior QCPR and Little Baby QCPR" },
                        { link:"/articles/000007230 ", text:" How to replace Little Anne QCPR Lung?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LittleAnneQcprTorsoPage;