import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const CrashKellyPage = () => {
    return (
      <>
        <ProductTopSection
            title="Crash Kelly"
            description="Crash Kelly is a durable, rugged training manikin with an intubation head for advanced airway management training and realistic articulation allowing the manikin to be placed in various settings for extrication or rescue."
            asset="assets/crash-kelly.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Male manikin',
                link: '/male-manikin'
            }, {
                text: 'Crash Kelly'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=148"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/male-manikin/crash-kelly/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/male-manikin/crash-kelly/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Crash Kelly"
                    items={[{
                            title: "What ships with the Crash Kelly?",
                            subtitle: "",
                            link: "/articles/000003296"
                        },{
                            title: "Where is the serial number located on a Crash Kelly?",
                            subtitle: "",
                            link: "/articles/000001699"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003294 ", text:" What spare parts are available for the Crash Kelly (201-10001)?" },
                        { link:"/articles/000001776 ", text:" Does Crash Kelly (201-10001) contain latex?" },
                        { link:"/articles/000003292 ", text:" How do I clean and store the Crash Kelly (201-10001) manikin?" },
                        { link:"/articles/000003288 ", text:" Is the Crash Kelly (201-10001) available in ethnic skin tones?" },
                        { link:"/articles/000003303 ", text:" What is the height and weight of a Crash Kelly (201-10001)?" },
                        { link:"/articles/000003296 ", text:" What ships with the Crash Kelly (201-10001)?" },                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kelly",
                            asset: "assets/megacode-kelly.png",
                            heightPx: "144px",
                            link: "/male-manikin/megacode-kelly"
                        },{
                            text: "SimMan 3G Essential",
                            asset: "assets/simman-3g-essential.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential"
                        },{
                            text: "SimMan 3G Essential Bleeding",
                            asset: "assets/simman-3g-essential-bleeding.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential-bleeding"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default CrashKellyPage;