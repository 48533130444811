import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const MiniAnnePlusInflatableBodyCompletePage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Mini Anne Plus',
                link: '/female-manikin/mini-anne-plus'
            },{
                text: 'Inflatable Body Complete',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000006030 ", text:" Caring for your Mini Anne Plus kit" },
                        { link:"/articles/000006029 ", text:" Getting to know your Mini Anne Plus kit" },
                        { link:"/articles/000006011 ", text:" How to inflate the Mini Anne Plus?" },
                        { link:"/articles/000002251 ", text:" How are the MiniAnne Plus inflated?" },
                        { link:"/articles/000001426 ", text:" How does the Mini Anne Plus clicker differ from the Mini Anne clicker?" },                                  
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default MiniAnnePlusInflatableBodyCompletePage;