import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingAnneHeadPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Nursing Anne',
                link: '/female-manikin/nursing-anne'
            },{
                text: 'Head',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000001762 ", text:" Can I purchase a replacement airway for Nursing Anne?" },
                        { link:"/articles/000003593 ", text:" Can Nursing Anne be intubated nasally?" },
                        { link:"/articles/000003662 ", text:" Can you perform a nasal saline flush effectively on Nursing Anne or Nursing Kelly?" },
                        { link:"/articles/000003356 ", text:" What is the part number for Nursing Anne Head Speaker?" },
                        { link:"/articles/000001378 ", text:" What options are available for a Nursing Anne with a ripped head skin?" },
                        { link:"/articles/000003317 ", text:" What to do if a Nursing Anne face skin has separated from the skull at the hairline?" },                            
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingAnneHeadPage;