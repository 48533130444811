import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const MiniBabyDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Mini Baby',
                link: '/baby-newborn-manikin/als-baby'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>

            <Faqs 
                    title="Relevant articles"
                    items={[{
                        text:"User Guide for Mini Baby",
                        link:"/articles/000005536"
                    },{
                        text:"What is Mini Baby?",
                        link:"/articles/000006021"
                    },{
                        text:"Mini Baby material specifications",
                        link:"/articles/000003982"
                    }
                    ]}/>

                    <CantFindCreateCase />

            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default MiniBabyDocumentationPage;