import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingAnneDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Nursing Anne',
                link: '/female-manikin/nursing-anne'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000003651 ", text:" Are there temperature limits or environmental conditions to consider for operation of or storing Nursing Anne?" },
                        { link:"/articles/000001485 ", text:" Does Nursing Anne contain latex?" },
                        { link:"/articles/000003240 ", text:" Where is the serial number located on Nursing Anne?" },
                        { link:"/articles/000002589 ", text:" What parts are available for Nursing Anne?" },
                        { link:"/articles/000003251 ", text:" Where can I find a copy of the User Guide for Nursing Anne?" },
                        { link:"/articles/000003626 ", text:" How to perform NG tube insertion on Nursing Anne?" },                              
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingAnneDocumentationPage;