import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneQcprTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne QCPR',
                link: '/female-manikin/resusci-anne-qcpr'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000005426 ", text:" Can I use two Li-Ion batteries with the same Resusci QCPR (BLE) manikin?" },
                        { link:"/articles/000003900 ", text:" Can owner of Resusci Anne QCPR (Pre-2018) configuration upgrade to a rechargeable battery?" },
                        { link:"/articles/000003889 ", text:" Can the user of Resusci Anne QCPR (2018) and Resusci Baby QCPR (BLE) charge the Li-Ion battery outside the manikin?" },
                        { link:"/articles/000005425 ", text:" How long can I use the Resusci Anne QCPR (2018) manikin when the Low Battery Indication is activated (Red LED)?" },
                        { link:"/articles/000005950 ", text:" How to connect the Ventilator Adapter to Resusci Anne QCPR?" },
                        { link:"/articles/000005427 ", text:" How to replace the Li-Ion battery in my Resusci Anne QCPR (2018)?" },
                        { link:"/articles/000006151 ", text:" Li-Ion Battery FAQs - Resusci QCPR Line" },
                        { link:"/articles/000004229 ", text:" Manikin does not turn ON after updating firmware with App - Resusci Junior QCPR and Resusci Anne QCPR (2018)" },
                        { link:"/articles/000005428 ", text:" What does “reset the Fuel Gauge” actually mean and why do I need to do it? - Resusci QCPR BLE manikins with new Li-Ion Battery" },
                        { link:"/articles/000005431 ", text:" What happens if I install a new Li-Ion battery in my Resusci QCPR BLE manikin but do not reset the Fuel Gauge?" },
                        { link:"/articles/000005430 ", text:" What to do if the new battery of my Resusci QCPR BLE manikin does not have any charge when I receive it (depleted battery)?" },
                        { link:"/articles/000005697 ", text:" What to do if my Resusci Anne QCPR (2018) does not start charging?" },
                        { link:"/articles/000003933 ", text:" Are Resusci Anne QCPR (Pre-2018) manikins available with a rechargable battery?" },
                        { link:"/articles/000003888 ", text:" Can I use external automated compression devices as LUCAS and AutoPulse with my Resusci Anne manikin?" },
                        { link:"/articles/000003883 ", text:" Can the Resusci Anne QCPR (2018) rechargeable Li-Ion battery be replaced if it fails or do not take sufficient charging?" },
                        { link:"/articles/000002359 ", text:" Does it make any difference using the Resusci Anne QCPR on the floor or on a mattress?" },
                        { link:"/articles/000002420 ", text:" How do I know that my Resusci Anne QCPR has been exposed to 1.000.000 chest compressions?" },
                        { link:"/articles/000002393 ", text:" How long will the batteries last on the Resusci Anne QCPR (Pre-2018) and how often should I change them?" },
                        { link:"/articles/000002384 ", text:" Lithium batteries inside Resusci Anne systems with SimPad will not charge, why?" },
                        { link:"/articles/000001215 ", text:" What to do if the Wi-Fi signal (green light) disappears after a few blinks and I cannot connect my QCPR manikin?" },
                        { link:"/articles/000003629 ", text:" What are the power requirements for Resusci Anne QCPR (pre 2018)?" },
                        { link:"/articles/000002422 ", text:" What is the part number for the cable that goes from the QCPR manikin to the SimPad SkillReporter?" },
                        { link:"/articles/000003534 ", text:" Why does a red light appear on the manikin's button panel?" },
                        { link:"/articles/000006856 ", text:" Compression specifications for Resusci Anne QCPR" },
                        { link:"/articles/000006857 ", text:" Resusci Anne - How to change Chest Spring?" },
                        { link:"/articles/000006885 ", text:" Battery run time for Resusci Anne, Resusci Junior and Resusci Baby QCPR" },
                        { link:"/articles/000006908 ", text:" Resusci Anne - How to change Chest Skin?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneQcprTorsoPage;