import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const KidJuniorDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            },{
                text: 'Little Junior QCPR',
                link: '/kid-junior-manikin/little-junior-qcpr'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000005528 ", text:" User Guide for Little Junior (all versions)" },
                        { link:"/articles/000004230 ", text:" What is 5 initial breaths CPR protocol?" },
                        { link:"/articles/000006281 ", text:" What are the CPR protocols for Adults, Children and Infants?" },
                        { link:"/articles/000004076 ", text:" What is 15:2 CPR Child protocol?" },
                        { link:"/articles/000006031 ", text:" What is Little Junior QCPR?" }                     
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default KidJuniorDocumentationPage;