import { BREAKPOINTS, Button, COLORS, ComponentL, ComponentM, ComponentTextStyle, HorizontalCard, HyperLink, LoadingIndicator, PageWidth, Paginator, SearchBar, Size, SystemIcons, Table } from "@laerdal/life-react-components";
import React, { useEffect } from "react";
import { useState } from "react";
import {  useLocation } from "react-router";
import styled from "styled-components";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import ArticleApi from "../../services/api/ArticleApi";
import {useDebouncedValue} from 'rooks';
import { useNavigate } from "react-router-dom";
import posthog from 'posthog-js';
import PartFinderApi from "../../services/api/PartFinderApi";
import { PartFinderItemDto } from "../../model/dto/partFinderItemDto";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const PartFinderSearchPage = () => {
  let query = useQuery();

  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [securityCode] = useState<string>(query.get("s") ?? '');
  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<PartFinderItemDto[]>([]);

  
  useEffect(() => {
    PartFinderApi
      .GetTopLevel(searchTerm, securityCode)
      .then((data) => {
        setLoading(false);
        setItems(data)
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  return (
    <>
      <PageWidth 
        useMaxWidth={true} 
        >
        <SearchBar
          id="DemoSearchBarSmall"
          placeholder="Product name"
          enterSearch={(e) => {
            // @ts-ignore
            posthog.capture('PartFinder Search',{
              searchTerm: searchTerm
            });

            setLoading(true);
            PartFinderApi
              .GetTopLevel(searchTerm, securityCode)
              .then((data) => {
                setLoading(false);
                setItems(data)
              })
              .catch(error => {
                console.error(error);
              })
          }}
          setSearchTerm={(term)=>{setSearchTerm(term)}}
          
          removeSearch={() => {
            setSearchTerm('');
          }}
          size={Size.Small}
        />
        
        <Table
          noRowsLabel={'No items found'}
          rows={items || []}
          remoteOperations={false}
          showLoadingIndicator={loading}
          selectable={true}
          onSelectionChange={(item:PartFinderItemDto) => {
            navigate(`/partFinder/${item.id}?s=${securityCode}`)
          }}
          columns={
            [
              {
                key: 'itemNumber',
                name: 'Product code'
              },{
                key: 'description',
                name: 'Name',
                width: 500
              },{
                key: 'itemDescription2',
                name: 'Description'
              },{
                key: 'itemType',
                name: 'Item type'
              },{
                key: 'itemLifecycle',
                name: 'Lifecycle'
              },{
                key: 'partVirtual',
                name: 'Virtual'
              },{
                key: 'partMakePurchase',
                name: 'PMP'
              },{
                key: 'lotSerial',
                name: 'Serial'
              },{
                key: 'countryOfOrigin',
                name: 'Country'
              },{
                key: 'commodityCode',
                name: 'Commodity code'
              },{
                key: 'partProductGroup',
                name: 'Product Group'
              },{
                key: 'partProductFamily',
                name: 'Product Family'
              },{
                key: 'partProductLine',
                name: 'Product Line'
              },{
                key: 'partProductType',
                name: 'Product Type'
              }
            ]
          }
        />
        
      </PageWidth>
    </>);
};

export default PartFinderSearchPage;