import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const BaxStrapPage = () => {
    return (
      <>
        <ProductTopSection
            title="BaxStrap"
            description="The Laerdal BaXstrap Spineboard provides the highest quality and most comfortable care for patients requiring immediate emergency spinal care. As one of the lightest, most rigid boards on the market, the BaXstrap Spineboard prepares providers for any situation. The durable and uniquely contoured design, matched with our private labeling option, makes the BaXstrap Spineboard the premium spine board on the market."
            asset="assets/baxstrap.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'BaxStrap'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=240"
        />

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />


                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default BaxStrapPage;