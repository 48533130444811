import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const LaerdalSuctionUnitPage = () => {
    return (
      <>
        <ProductTopSection
            title="Laerdal Suction Unit"
            description="Laerdal’s portable suction unit (LSU) is widely known for its excellent performance and functional design. It comes with reusable canister, allowing for safe and effective clearing of the patient’s airway"
            asset="assets/laerdal-suction-unit.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'Laerdal Suction Unit'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=234"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/laerdal-suction-unit/"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories LSR",
                            link:"/medical-devices/laerdal-suction-unit/accessories-lsr"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Cabinet/Case",
                            link:"/medical-devices/laerdal-suction-unit/cabinet-case"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/medical-devices/laerdal-suction-unit/documentation"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Laerdal Suction Unit"
                    items={[{
                            title: "How to perform LSU Device test?",
                            subtitle: "",
                            link: "/articles/000007110"
                        },{
                            title: "How should I clean LSU Reusable?",
                            subtitle: "",
                            link: "/articles/000007117"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>


        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000007102 ", text:" Does LSU contain latex?" },
                        { link:"/articles/000007112 ", text:" Do you have a picture of the LSU User Interface with labelling?" },
                        { link:"/articles/000007110 ", text:" How to perform LSU Device test?" },
                        { link:"/articles/000007101 ", text:" Is there a parts overview for the Laerdal Suction Unit (LSU)?" },
                        { link:"/articles/000007109 ", text:" Is there a troubleshooting guide for LSU?" },
                        { link:"/articles/000007088 ", text:" What is the length of LSU DC power cord?" },
                        { link:"/articles/000007113 ", text:" What does the LSU Battery Status indicate?" },
                        { link:"/articles/000007118 ", text:" How should I clean LSU with Serres canister?" },
                        
                    ]}/>


                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default LaerdalSuctionUnitPage;