import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const QcprAppMobileAppPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Software',
                link: '/software'
            }, {
                text: 'QCPR App',
                link: '/software/qcpr-app'
            },{
                text: 'Mobile Application',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000005975 ", text:" Anniewhere - How to interpret feedback symbols?" },
                        { link:"/articles/000005988 ", text:" Anniewhere - How to sign up for access?" },
                        { link:"/articles/000005874 ", text:" Are Laerdal Apps in compliance with WCAG guidelines?" },
                        { link:"/articles/000005548 ", text:" Can I export the CPR data from the QCPR App to my computer?" },
                        { link:"/articles/000005879 ", text:" How to view QCPR SkillReporter App results in Excel?" },
                        { link:"/articles/000005404 ", text:" QCPR Classroom App - How to set up the Classroom system?" },
                        { link:"/articles/000005406 ", text:" QCPR Classroom App - How to run QCPR Race" },
                        { link:"/articles/000005407 ", text:" QCPR Classroom App - How to run a Training session?" },
                        { link:"/articles/000005403 ", text:" QCPR Classroom App - How to interpret feedback symbols" },
                        { link:"/articles/000005405 ", text:" QCPR Classroom App - Data Analytic cloud service" },
                        { link:"/articles/000005999 ", text:" Quick Start Guide for Anniewhere Learner" },
                        { link:"/articles/000006000 ", text:" Quick Start Guide for Anniewhere Instructor" },
                        { link:"/articles/000005538 ", text:" User Guide for QCPR Apps" },
                        { link:"/articles/000005816 ", text:" What is DARE Instructor App?" },
                        { link:"/articles/000005706 ", text:" What is QCPR SkillReporter App?" },
                        { link:"/articles/000006017 ", text:" What is TCPR Link App?" },
                        { link:"/articles/000006002 ", text:" What is the relationship between QCPR Classroom App and Anniewhere?" },
                        { link:"/articles/000005867 ", text:" Why are compressions to full manikin depth registered as OK compressions in QCPR App?" },
                        { link:"/articles/000006006 ", text:" Why do Laerdal's Apps require access to mobile device’s location, camera, pictures and files?" },
                        { link:"/articles/000006192 ", text:" Getting started with Anniewhere" },
                        { link:"/articles/000004292 ", text:" Privacy Policy - Does Laerdal collect and store data registered in Mobile Applications (Apps)?" },
                        { link:"/articles/000006003 ", text:" Anniewhere - Online CPR Training Service" },
                        { link:"/articles/000006012 ", text:" Anniewhere - How many can train in one session?" },
                        { link:"/articles/000005966 ", text:" User Guide for Anniewhere Instructor" },
                        { link:"/articles/000005967 ", text:" User Guide for Anniewhere Learner" },
                        { link:"/articles/000005965 ", text:" What is Anniewhere?" },
                        { link:"/articles/000003652 ", text:" How to display the QCPR App on big TV screen?" },
                        { link:"/articles/000004124 ", text:" What is QCPR Classroom App?" },
                        { link:"/articles/000006921 ", text:" QCPR App - Smart devices (Phones and tablets) Permission requirements" },
                        { link:"/articles/000006920 ", text:" QCPR App - Data Protection and Privacy Policy" },
                        { link:"/articles/000003758 ", text:" How to connect Little Family QCPR manikins with mobile device?" },
                        { link:"/articles/000006922 ", text:" QCPR App - Do I need Internet to use my App?" },
                        { link:"/articles/000006917 ", text:" What are the guideline recommendations supported by QCPR App ?" },
                        { link:"/articles/000006916 ", text:" What are the guidelines supported by Laerdal QCPR Manikins ?" },
                        { link:"/articles/000006320 ", text:" Does QCPR App support the 3:1 CPR Newborn protocol?" },
                        { link:"/articles/000006209 ", text:" Why is QCPR App limited to maximum 6 manikins?" },
                        { link:"/articles/000005805 ", text:" QCPR App only connects to 3 manikins, but not 6 manikins" },
                        { link:"/articles/000006942 ", text:" QCPR App - How to get started ?" },
                        { link:"/articles/000006918 ", text:" What are the protocols available for Laerdal QCPR Manikins ?" },
                        { link:"/articles/000006946 ", text:" QCPR App - How to change the protocol for my session ?" },
                        { link:"/articles/000006945 ", text:" QCPR App - How to set a 15:2 session for my Little Baby or Little Junior ?" },
                        { link:"/articles/000006943 ", text:" QCPR App vs QCPR Training App" },
                        { link:"/articles/000006941 ", text:" QCPR App - Little Anne Firmware Update is failing on some Samsung devices" },
                        { link:"/articles/000003759 ", text:" Mobile device specifications for QCPR Apps" },
                        { link:"/articles/000003807 ", text:" QCPR App feedback symbols and scoring" },
                        { link:"/articles/000006952 ", text:" QCPR App - Signup Trouble Shooting" },
                        { link:"/articles/000006981 ", text:" Understanding Best Practice in Resuscitation: Navigating UK Guidelines and Our App's Scoring System" },
                        { link:"/articles/000004213 ", text:" QCPR App matrix - manikin compatibility and features" },
                        { link:"/articles/000006980 ", text:" QCPR App - Functions in AED Trainer Remote Control" },
                        { link:"/articles/000006986 ", text:" Update the software on your manikins" },
                        { link:"/articles/000007028 ", text:" What are the Password Requirements in QCPR App when creating a new account?" },
                        { link:"/articles/000005937 ", text:" Colors of Ambulances in the QCPR Game" },
                        { link:"/articles/000006939 ", text:" QCPR App - Minimum devices Requirements" },
                        { link:"/articles/000003762 ", text:" How to download Mobile Apps in App Store and Google Play?" },
                        { link:"/articles/000006977 ", text:" QCPR App - How to get started with AED Trainer" },
                        { link:"/articles/000003984 ", text:" What is QCPR Game?" },
                        { link:"/articles/000007232 ", text:" QCPR App - Samsung devices - Update of One UI to version 6.0 might affect device performances of our customers" },
                        { link:"/articles/000007233 ", text:" QCPR App - How to see the detailed score for my CPR Game ?" },
                        { link:"/articles/000007234 ", text:" QCPR App - What are the URLs to be whitelisted if QCPR App is running on a restricted network ?" },
                        { link:"/articles/000007237 ", text:" Transitioning from QCPR SkillReporter to QCPR App" },
                        { link:"/articles/000006969 ", text:" QCPR App - What are the Manikin Firmware versions supported?" },
                        { link:"/articles/000007238 ", text:" Transitioning from QCPR SkillReporter to QCPR App (Chinese)" },
                        { link:"/articles/000005882 ", text:" What is QCPR App?" },
                        { link:"/articles/000003654 ", text:" What is QCPR Learner App?" },
                        { link:"/articles/000003658 ", text:" What is QCPR Training App?" },
                        { link:"/articles/000006940 ", text:" QCPR App - Which devices have been tested by Laerdal?" },
                        { link:"/articles/000007325 ", text:" UEFA Euro 2024 - How to setup the booth and start the game" },
                        { link:"/articles/000007313 ", text:" UEFA Euro 2024 - Fan zones support" },
                        { link:"/articles/000007331 ", text:" UEFA Euro 2024 - Maintenance Checklist" },
                        { link:"/articles/000007323 ", text:" UEFA Euro 2024 - Troubleshooting" }
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default QcprAppMobileAppPage;