import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const MaleManikinPage = () => {
    return (
      <>
        <ProductTopSection
            title="Male manikin"
            description=""
            asset="assets/resuscitation-training-big.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Male manikin'
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Topics 
                    items={[{
                            text: "Crash Kelly",
                            asset: "assets/crash-kelly.png",
                            heightPx: "144px",
                            link: "/male-manikin/crash-kelly"
                        },{
                            text: "MegaCode Kelly",
                            asset: "assets/megacode-kelly.png",
                            heightPx: "144px",
                            link: "/male-manikin/megacode-kelly"
                        },{
                            text: "SimMan 3G Essential",
                            asset: "assets/simman-3g-essential.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential"
                        },{
                            text: "SimMan 3G Essential Bleeding",
                            asset: "assets/simman-3g-essential-bleeding.png",
                            heightPx: "116px",
                            link: "/male-manikin/simman-3g-essential-bleeding"
                        },{
                            text: "SimMan 3G PLUS",
                            asset: "assets/simman-3g-plus.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-plus"
                        },
                        // {
                        //     text: "SimMan 3G Trauma",
                        //     asset: "assets/simman-3g-trauma.png",
                        //     heightPx: "144px",
                        //     link: "/male-manikin/simman-3g-trauma"
                        // },
                        {
                            text: "SimMan ALS",
                            asset: "assets/simman-als.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-als"
                        },
                        // {
                        //     text: "SimMan Vascular",
                        //     asset: "assets/simman-vascular.png",
                        //     heightPx: "144px",
                        //     link: "/male-manikin/simman-vascular"
                        // }
                    ]}
                />

                
                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000002099 ", text:" Can a SimMan Essential be upgraded to a SimMan Essential Bleeding?" },
                        { link:"/articles/000004377 ", text:" What wound modules are available for the SimMan Essential?" },
                        { link:"/articles/000002646 ", text:" How many uses should I be able to get out of the SimMan Essential IV Arm?" },
                        { link:"/articles/000004238 ", text:" How to clean the SimMan Essential IV Arm?" },
                        { link:"/articles/000001785 ", text:" How to set up the IV arm on a SimMan Essential?" },
                    ]}  
                    />
                <CantFindWhatYouNeed />
                <BigSmallContent 
                    big={{
                        title: "Laerdal Global Warranty",
                        description: "In the event that your Laerdal Medical product needs warranty service, please contact Laerdal Medical or the Authorized Dealer from whom it was purchased. In order to avoid any unnecessary inconvenience on your part, we suggest reading the Directions for Use carefully before contacting Laerdal Medical or our Authorized Dealer.",
                        linkText: "PDF",
                        linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                    }}
                    small={{
                        title: "Privacy Statement",
                        description: "This statement describes the purposes for which personal information is collected, parties with whom we may share it, and measures we take to protect your personal information.",
                        linkText: "Read more",
                        linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                    }}/>
                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default MaleManikinPage;