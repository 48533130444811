import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const LittleJuniorQcprPage = () => {
    return (
      <>
        <ProductTopSection
            title="Little Junior QCPR"
            description="The next generation of Little Junior includes QCPR measurement and feedback technology which provides real-time objective feedback on compressions and ventilations to identify compliance and quality improvement."
            asset="assets/little-junior-qcpr.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            }, {
                text: 'Little Junior QCPR'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=532"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/kid-junior-manikin/little-junior-qcpr/documentation"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Little Junior QCPR"
                    items={[{
                            title: "What is Little Junior QCPR?",
                            subtitle: "",
                            link: "/articles/000006031"
                        },{
                            title: "What is 15:2 CPR Child protocol?",
                            subtitle: "",
                            link: "/articles/000004076"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005528 ", text:" User Guide for Little Junior (all versions)" },
                        { link:"/articles/000004230 ", text:" What is 5 initial breaths CPR protocol?" },
                        { link:"/articles/000006281 ", text:" What are the CPR protocols for Adults, Children and Infants?" },
                        { link:"/articles/000004076 ", text:" What is 15:2 CPR Child protocol?" },
                        { link:"/articles/000006031 ", text:" What is Little Junior QCPR?" }                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kid",
                            asset: "assets/megacode-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/megacode-kid"
                        },{
                            text: "Nursing Kid",
                            asset: "assets/nursing-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/nursing-kid"
                        },{
                            text: "Resusci Junior QCPR",
                            asset: "assets/resusci-junior-qcpr.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/resusci-junior-qcpr"
                        },{
                            text: "SimJunior",
                            asset: "assets/sim-junior.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/sim-junior"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default LittleJuniorQcprPage;