import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneQcprHeadPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne QCPR',
                link: '/female-manikin/resusci-anne-qcpr'
            },{
                text: 'Head',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000001882 ", text:" Are there different airway heads for the Resusci Anne?" },
                        { link:"/articles/000006269 ", text:" What types of intubation devices and methods does the Resusci Family Airway Heads support?" },
                        { link:"/articles/000006861 ", text:" Rotational Eyes for Resusci Anne and Resusci Junior Airway Head" },
                        { link:"/articles/000006863 ", text:" How to change Rotating Eyes?" },
                        { link:"/articles/000006947 ", text:" Can Resusci Family Airway Heads be ventilated by mouth-to-mouth?" },
                        { link:"/articles/000005711 ", text:" How to replace Face Skin on Resusci Baby with standard Head?" },
                        { link:"/articles/000005934 ", text:" How to connect the Pulse Bulb to Resusci manikins?" },
                        { link:"/articles/000006909 ", text:" Resusci Anne - How to change Face Mask on standard Head?" },
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneQcprHeadPage;