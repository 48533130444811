import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SkillGuidePage = () => {
    return (
      <>
        <ProductTopSection
            title="SkillGuide"
            description="The SkillGuide is a CPR device for Laerdal QCPR Manikins offering three modes for use: Feedback, Blind, and Debriefing. Resusci Anne QCPR, Resusci Junior QCPR, and Resusci Baby are all compatible with the SkillGuide Device."
            asset="assets/skillguide.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'SkillGuide'
            }]}
            buttonLink="https://laerdal.com/us/ProductDownloads.aspx?productId=398"
        />

        <FullNeutralPageContainer>
            <PageContainer>
              
                <GetToKnow 
                    title="Get to know your SkillGuide"
                    items={[{
                            title: "What is SkillGuide?",
                            subtitle: "",
                            link: "/articles/000006040"
                        },{
                            title: "How is the new SkillGuide device powered?",
                            subtitle: "",
                            link: "/articles/000002513"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005537 ", text:" User Guide for SkillGuide" },
                        { link:"/articles/000004352 ", text:" Can SkillGuide use 15:2 CPR Child protocol?" },
                        { link:"/articles/000002513 ", text:" How is the new SkillGuide device powered?" },
                        { link:"/articles/000001345 ", text:" How long should the batteries last for in an old SkillGuide?" },
                        { link:"/articles/000002107 ", text:" What information is provided in Feedback Mode when using the SkillGuide?" },
                        { link:"/articles/000001574 ", text:" What information is provided in the Blind Mode operation of the SkillGuide?" },
                        { link:"/articles/000001524 ", text:" What information is provided in the Debrief Mode of operation when using the SkillGuide?" },
                        { link:"/articles/000003563 ", text:" What is the length of the cable for the new SkillGuide?" },
                        { link:"/articles/000006040 ", text:" What is SkillGuide?" }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "LLEAP",
                            asset: "assets/lleap-hw.png",
                            heightPx: "144px",
                            link: "/training-devices/lleap"
                        },{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SkillGuidePage;