import { COLORS, ContentIcons, LoadingIndicator, PageWidth } from "@laerdal/life-react-components";
import styled from "styled-components";
import BreadcrumbTopSection from "../../commonComponents/BreadcrumbTopSection";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import Faqs from "../../commonComponents/Faqs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductRelevantArticlesDto } from "../../model/dto/productRelevantArticlesDto";
import ArticleApi from "../../services/api/ArticleApi";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ProductLineComponent = () => {
    const { categoryLink, productLink, componentLink } = useParams<{ categoryLink: string, productLink: string, componentLink: string }>();

    const [loading, setLoading] = useState<boolean>(true);
    const [dto, setDto] = useState<ProductRelevantArticlesDto | undefined>(undefined);

    useEffect(() => {
        setLoading(true);
        setDto(undefined);

        if(categoryLink && productLink && componentLink)
        {
            ArticleApi
              .GetRelevantArticlesLine(categoryLink, productLink, componentLink)
              .then((data) => {
                setLoading(false);
                setDto(data);
              })
        }
    }, [categoryLink, productLink, componentLink]);
    return (
        <>
            {loading &&
                <LoadingIndicator />
            }

            { dto &&
                <>
                    <BreadcrumbTopSection
                        breadcrumb={[{
                            text: 'Home',
                            link: '/'
                        }, {
                            text: dto.categoryName,
                            link: `/category/${categoryLink}`
                        }, {
                            text: dto.productName,
                            link: `/category/${categoryLink}/product/${productLink}`
                        }, {
                            text: dto.componentName,
                        }]}
                    />
                    <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
                        <PageContainer>
                            <Faqs
                                title="Relevant articles"
                                items={
                                    dto.articles.map((x)=> ({
                                        text: x.name,
                                        link: `/articles/Knowledge/${x.link}`
                                    }))
                                } />
                            <CantFindCreateCase />
                        </PageContainer>
                    </PageWidth>
                </>
            }
        </>);
};

export default ProductLineComponent;