import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const NeoNatalieKitPage = () => {
    return (
      <>
        <ProductTopSection
            title="NeoNatalie Resuscitator Oxygen Kit"
            description="NeoNatalie is an inflatable simulator designed to teach basic neonatal resuscitation skills. Developed to meet the key requirements for teaching the initial steps of resuscitation in the first ten minutes of a newborn’s life, NeoNatalie is an effective, cost-efficient tool to enhance your training program."
            asset="assets/neonatalie-kit.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'NeoNatalie Resuscitator Oxygen Kit'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=380"
        />

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />


                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default NeoNatalieKitPage;