import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LittleAnneStackableDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Little Anne Stackable',
                link: '/female-manikin/little-anne-stackable'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[          
                        { link:"/articles/000006953 ", text:" How to replace Little Anne Back Skull?" },
                        { link:"/articles/000006955 ", text:" How to replace Little Anne Front Skull?" },
                        { link:"/articles/000006957 ", text:" How to replace Little Anne Head Elastic Band?" },
                        { link:"/articles/000006960 ", text:" How to replace Little Anne Battery Cover Lid?" },
                        { link:"/articles/000006962 ", text:" How to disassemble Little Anne 6-pack?" },
                        { link:"/articles/000006963 ", text:" How to wash Little Anne Face and Chest Skin?" },
                        { link:"/articles/000006964 ", text:" How to wash Little Anne Chest Skin?" },
                        { link:"/articles/000006965 ", text:" How to replace Little Anne Airway Filter?" },
                        { link:"/articles/000006966 ", text:" How to setup Little Anne 6-pack?" },
                        { link:"/articles/000006967 ", text:" How to setup Little Anne single unit?" },
                        { link:"/articles/000006959 ", text:" How to replace Little Anne Chest Assembly?" },
                        { link:"/articles/000007326 ", text:" What is included in spare part 135-28050 for Little Anne?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LittleAnneStackableDocumentationPage;