import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const RqiSoftwarePage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'RQI',
                link: '/training-devices/rqi'
            },{
                text: 'Softwar',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000006120 ", text:" Can you certify that there is no experience of information security incidents resulting in the loss of RQI customer data?" },
                        { link:"/articles/000006095 ", text:" Does the organization follow the FIPS 140-2 guidance to secure data at rest and in transit with a unique key for each customer?" },
                        { link:"/articles/000006103 ", text:" Does the environment contain PII data?" },
                        { link:"/articles/000006102 ", text:" Does the environment come under PHI/HIPAA compliance requirements?" },
                        { link:"/articles/000006094 ", text:" Does the organization use cryptography to secure customer data?" },
                        { link:"/articles/000006093 ", text:" Does the organization have a mechanism to purge all data belonging to a customer upon disengagement from services?" },
                        { link:"/articles/000006056 ", text:" Does the RQI application allow for the deletion of a User ID without causing any disturbance to other users/processes and systems?" },
                        { link:"/articles/000006074 ", text:" Does the RQI application allow for the automatic modification of user accounts?" },
                        { link:"/articles/000006080 ", text:" Does the RQI application allow for regular monitoring of application logs for access violations?" },
                        { link:"/articles/000006059 ", text:" Does the RQI application allow any forcible authentication like integrating with Windows AD?" },
                        { link:"/articles/000006060 ", text:" Does the RQI application allow integration with the Imprivata Single Sign-On solution?" },
                        { link:"/articles/000006062 ", text:" Does the RQI application allow integration with AD RBAC/Group Policy authentication?" },
                        { link:"/articles/000006061 ", text:" Does the RQI application allow integration with Active Directory?" },
                        { link:"/articles/000006076 ", text:" Does the RQI application allow for the removal of full or part of the functionality should a security issue be identified?" },
                        { link:"/articles/000006072 ", text:" Does the RQI application centrally control access management?" },
                        { link:"/articles/000006073 ", text:" Does the RQI application allow users to be created through an automated process?" },
                        { link:"/articles/000006051 ", text:" Does the RQI application allow the users to change their password?" },
                        { link:"/articles/000006053 ", text:" Does the RQI application allow support personnel to reset the password to be entered by the user at the next login?" },
                        { link:"/articles/000006081 ", text:" Does the RQI application provide shareable access log details of the facility?" },
                        { link:"/articles/000006050 ", text:" Does the RQI application lock out users after a predefined number of unsuccessful login attempts, for a predefined period of time?" },
                        { link:"/articles/000006057 ", text:" Does the RQI application integrate with Enterprise Authentications systems using SAML 2.0 or WSFED?" },
                        { link:"/articles/000006058 ", text:" Does the RQI application integrate with Enterprise Authentication systems like AD/LDAP using secure LDAP?" },
                        { link:"/articles/000006112 ", text:" Does the RQI application store application and customer data in cloud storage?" },
                        { link:"/articles/000006052 ", text:" Does the RQI application require security questions when performing a password reset?" },
                        { link:"/articles/000006049 ", text:" Does the RQI application require passwords to be changed on a periodic basis?" },
                        { link:"/articles/000006048 ", text:" Does the RQI application support complex passwords, including requiring a mix of alpha, numeric, and special characters?" },
                        { link:"/articles/000006079 ", text:" Does the RQI application support an audit log monitoring details such as user details, changes by user, etc.?" },
                        { link:"/articles/000006054 ", text:" Does the RQI application store the passwords using protections, such as MD5, Salt, etc.?" },
                        { link:"/articles/000006070 ", text:" Does the RQI application store the data at rest with encryption?" },
                        { link:"/articles/000006078 ", text:" Does the RQI application support monitoring of user access violations?" },
                        { link:"/articles/000006065 ", text:" Does the RQI application support MFA to sign-on?" },
                        { link:"/articles/000006055 ", text:" Does the RQI application support deactivating an account without deleting the User ID?" },
                        { link:"/articles/000006066 ", text:" Does the RQI application support creating user access and usage reports on-demand?" },
                        { link:"/articles/000006075 ", text:" Does the RQI application support the modification of functionalities without compromising security?" },
                        { link:"/articles/000006068 ", text:" Does the RQI application support SSL based access using TLS 1.2 and above?" },
                        { link:"/articles/000006064 ", text:" Does the RQI application support Single Sign-On?" },
                        { link:"/articles/000006077 ", text:" Does the RQI application support monitoring on Access/Secure logs?" },
                        { link:"/articles/000006071 ", text:" Does the RQI application use Role-Based Access to control access to resources and functions?" },
                        { link:"/articles/000006063 ", text:" Does the RQI application support Windows nested groups?" },
                        { link:"/articles/000006069 ", text:" Does the RQI application support the removal of customer data per the Customer Data Retention Policy?" },
                        { link:"/articles/000006067 ", text:" Does the RQI application support the on-demand report of a user?" },
                        { link:"/articles/000006132 ", text:" Does the RQI infrastructure design support business continuity and disaster recovery?" },
                        { link:"/articles/000006085 ", text:" Does the RQI application-hosting environment have MFA?" },
                        { link:"/articles/000006099 ", text:" Does the SOC2 Type II report cover all five trust principles, including Security, Availability, Processing Integrity, Confidentially and Privacy?" },
                        { link:"/articles/000006119 ", text:" Have there been any security incidents that resulted in a loss of RQI customer data?" },
                        { link:"/articles/000006131 ", text:" Has the RQI restore process been tested?" },
                        { link:"/articles/000006092 ", text:" Is customer data only used to perform the agreed tasks/purpose?" },
                        { link:"/articles/000006091 ", text:" Is access to the customer environment and all its associated data restricted to a few employees/vendors on a need to know basis?" },
                        { link:"/articles/000006128 ", text:" Is customer RQI data backed up?" },
                        { link:"/articles/000006113 ", text:" Is the internal network security which accesses the RQI hosting environment secured?" },
                        { link:"/articles/000006096 ", text:" Is the environment where RQI is hosted SOC2 Type II certified?" },
                        { link:"/articles/000006087 ", text:" Is the RQI application-hosting environment physically protected with state of the art security such as CCTV, biometric, etc.?" },
                        { link:"/articles/000006088 ", text:" Is the RQI application or user data stored on removable media?" },
                        { link:"/articles/000006129 ", text:" Is the RQI application instance data backed up?" },
                        { link:"/articles/000006109 ", text:" Is the RQI application hosted in a secure environment?" },
                        { link:"/articles/000006086 ", text:" Is the RQI application environment hardened with industry best practices?" },
                        { link:"/articles/000006125 ", text:" Is the RQI source code stored securely?" },
                        { link:"/articles/000006089 ", text:" Is the RQI hosting environment built with high security along with IDS/IPS to safeguard applications and all associated data?" },
                        { link:"/articles/000006130 ", text:" Is the RQI cloud configuration backed up?" },
                        { link:"/articles/000006118 ", text:" Is there a process for RQI application and operating system patching?" },
                        { link:"/articles/000006117 ", text:" Is there a change management process in place?" },
                        { link:"/articles/000006114 ", text:" Is there an incident management policy?" },
                        { link:"/articles/000006110 ", text:" Is there an access log for the RQI hosting location?" },
                        { link:"/articles/000006115 ", text:" What is the incident management process for RQI?" },
                        { link:"/articles/000006127 ", text:" What port numbers are used by the RQI file transfer service?" },
                        { link:"/articles/000006126 ", text:" What port numbers are used by the RQI application?" },
                        { link:"/articles/000003370 ", text:" Are there some specific examples of insight that Analytics for RQI may provide?" },
                        { link:"/articles/000003411 ", text:" Can learners be excluded from Analytics for RQI data?" },
                        { link:"/articles/000003406 ", text:" Do inactive learners appear in the Analytics for RQI application?" },
                        { link:"/articles/000003401 ", text:" How are target ranges set for the data in the skills stack?" },
                        { link:"/articles/000003389 ", text:" How are the goals (shaded area) set for the Overview stack?" },
                        { link:"/articles/000003396 ", text:" How are the scores for Latest- Best- or All Attempts calculated?" },
                        { link:"/articles/000003386 ", text:" How do users access Analytics for RQI?" },
                        { link:"/articles/000003368 ", text:" How does Analytics for RQI work?" },
                        { link:"/articles/000003393 ", text:" How does the date filter work in the Compliance Stack?" },
                        { link:"/articles/000003398 ", text:" How is the score displayed on the Leaderboard determined?" },
                        { link:"/articles/000003404 ", text:" How quickly will skills exercises completed in SimManager appear in the Analytics for RQI App?" },
                        { link:"/articles/000003394 ", text:" Is there an export function for data in Analytics for RQI?" },
                        { link:"/articles/000004440 ", text:" What are “Stacks” within Analytics for RQI?" },
                        { link:"/articles/000003397 ", text:" What does the Learner Activity Detail display?" },
                        { link:"/articles/000003399 ", text:" What is a key metric slice?" },
                        { link:"/articles/000003402 ", text:" What is revealed in the Cognitive Stack?" },
                        { link:"/articles/000003392 ", text:" What is revealed in the Compliance Stack?" },
                        { link:"/articles/000003400 ", text:" What is revealed in the Skills Stack?" },
                        { link:"/articles/000003387 ", text:" What is the default date range for data presented?" },
                        { link:"/articles/000003376 ", text:" What issues can Analytics for RQI help customers to explore?" },
                        { link:"/articles/000003371 ", text:" When will data updates be processed?" },
                        { link:"/articles/000003391 ", text:" Will filter selections be maintained after the user logs out?" },
                        { link:"/articles/000003390 ", text:" Will \"old\" data be available to review in RQI Analytics?" },
                        { link:"/articles/000006082 ", text:" Are all of the security configurations monitored to ensure adequate and required rules are configured to safeguard infrastructure and data?" },
                        { link:"/articles/000006108 ", text:" Are all resources supporting the RQI production environment located in the US region?" },
                        { link:"/articles/000006121 ", text:" Are document incident response policies and procedures in place for RQI?" },
                        { link:"/articles/000006116 ", text:" Are incident management SLA reports generated for RQI?" },
                        { link:"/articles/000006134 ", text:" Are regular BC & DR drills performed, and the results of the tests documented for RQI?" },
                        { link:"/articles/000006124 ", text:" Are the RQI application files stored securely?" },
                        { link:"/articles/000006090 ", text:" Are the RQI infrastructure logs monitored and reviewed regularly?" },
                        { link:"/articles/000006133 ", text:" Are there Business Continuity (BC) and Disaster Recovery (DR) process and procedures in place for RQI?" },
                        { link:"/articles/000006123 ", text:" Are there dedicated information security resources available for RQI?" },
                        { link:"/articles/000006122 ", text:" Can you certify that there have been no breaches that resulted in a mandatory notification under privacy laws?" },
                        { link:"/articles/000006323 ", text:" Are RQI Services affected by the Log4j/Log4Shell issue?" },
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default RqiSoftwarePage;