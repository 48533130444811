import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingAnneSimulatorDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Nursing Anne Simulator',
                link: '/female-manikin/nursing-anne-simulator'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000004102 ", text:" What Moulage products work well on Nursing Anne Simulator?" },
                        { link:"/articles/000004079 ", text:" Can Betadine/Iodine be used on the Nursing Anne Simulator?" },
                        { link:"/articles/000003992 ", text:" Does Nursing Anne Simulator contain latex?" },
                        { link:"/articles/000004287 ", text:" Overview of Nursing Anne Simulator Control Panel and Label" },
                        { link:"/articles/000006176 ", text:" Where to find the Serial Number on Nursing Anne Simulator?" },
                        { link:"/articles/000003999 ", text:" Are there temperature limits or environmental conditions to consider for operation of or storing Nursing Anne Simulator?" },
                        { link:"/articles/000005863 ", text:" What type of equipment and sizes do fit Nursing Anne Simulator?" },
                        { link:"/articles/000004284 ", text:" How to remove and replace the Nursing Anne Simulator Head and Torso Skin?" },
                        
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingAnneSimulatorDocumentationPage;