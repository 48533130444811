import { BREAKPOINTS, Button, COLORS, HyperLink, Size } from '@laerdal/life-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import posthog from 'posthog-js';


const FullPageContainer = styled.div`
  display:flex;
  background: ${COLORS.neutral_20};
`;
const TopSectionContainer = styled.div`
  max-width: 1200px;
  padding:64px;
  margin: 0 auto;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;
const LeftColumn = styled.div`
  ${BREAKPOINTS.LARGE}{
    display:flex;
  }
    
  display:none;
  flex-direction:column;
  gap: 16px;
  flex: 1;

  img {
    margin: 0 auto;
    width: 186px;
    height: 186px;
    flex-shrink: 0;
    border-radius: 50%; /* This makes the image round */
    overflow: hidden;
  }
`;
const RightColumn = styled.div`
  flex: 1;
  display: flex;
  gap:12px;
  flex-direction: column;
      align-items: start;

`;
const Title = styled.div`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
`;
const Description = styled.div`
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
`;


const LaerdalCustomerCare = () => {    
  const navigate = useNavigate()
  
  return (
    <FullPageContainer>
      <TopSectionContainer>
        <ColumnContainer>
          <LeftColumn>
            <img src="assets/customer-care-ilustration.png" alt='Laerdal customer care illustration' />
          </LeftColumn>
          <RightColumn>
            <Title>Laerdal customer service</Title>
            <Description>If you have an urgent matter or require immediate assistance, please contact Laerdal customer service directly.</Description>
            <HyperLink 
              variant={'default'} 
              onClick={(e)=>{
                e.preventDefault();
                posthog?.capture('SUPP-ContactUS',{
                  ref: document.referrer
                });
                window.location.href = 'https://laerdal.com/support/customer-service/contact-us/';
              }} 
              href={'https://laerdal.com/support/customer-service/contact-us/'}>
                Contact us
            </HyperLink>
          </RightColumn>
        </ColumnContainer>
      </TopSectionContainer>
    </FullPageContainer>
  );
}

export default LaerdalCustomerCare;