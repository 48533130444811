import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const AlsBabyLegsPage = () => {
    return (
        <>
            <BreadcrumbTopSection
                breadcrumb={[{
                    text: 'Home',
                    link: '/'
                }, {
                    text: 'Baby/Newborn manikin',
                    link: '/baby-newborn-manikin'
                }, {
                    text: 'ALS Baby',
                    link: '/baby-newborn-manikin/als-baby'
                }, {
                    text: 'Legs',
                }]}
            />
            <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
                <PageContainer>
                    <Faqs
                        title="Relevant articles"
                        items={[{
                            text: "Can the EZ IO Gun be used on the ALS Baby?",
                            link: "/articles/000002198"
                        }, {
                            text: "How does the Baby IO Leg work and with which manikins can it be used?",
                            link: "/articles/000005685"
                        }, {
                            text: "How many uses can the ALS Baby Leg replacement Pad withstand?",
                            link: "/articles/000002428"
                        }
                        ]} />
                    <CantFindCreateCase />
                </PageContainer>
            </PageWidth>
        </>);
};

export default AlsBabyLegsPage;