import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const PrematureAnneBabyPage = () => {
    return (
      <>
        <ProductTopSection
            title="Premature Anne Baby"
            description="Premature Anne™ is a realistically proportioned 25-week preterm infant developed in collaboration with the American Academy of Pediatrics. Premature Anne is designed to facilitate training of healthcare professionals in proper care and resuscitation techniques for preterm infants. Preterm infants are at greater risk and require specialized care by a highly skilled team of health professionals. Premature Anne is designed to meet the specific Neonatal Resuscitation Program® training requirements, defined by the American Academy of Pediatrics, to help ensure better patient outcomes for the tiniest of babies."
            asset="assets/premature-anne-baby.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Premature Anne Baby'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=472"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/premature-anne-how-to-videos/"
                        },{
                            icon: <ContentIcons.TaskTrainer size="48" color={COLORS.primary_500} />,
                            text:"Task trainer",
                            link:"/baby-newborn-manikin/premature-anne-baby/task-trainer"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/baby-newborn-manikin/premature-anne-baby/documentation"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Premature Anne"
                    items={[{
                            title: "Which lubricant should I use with my Premature Anne?",
                            subtitle: "",
                            link: "/articles/000005635"
                        },{
                            title: "Where is the serial number located on Premature Anne?",
                            subtitle: "",
                            link: "/articles/000006205"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003609 ", text:" Can I use Premature Anne with a Patient Monitor?" },
                        { link:"/articles/000003551 ", text:" Can Premature Anne be hooked up to a ventilator?" },
                        { link:"/articles/000003616 ", text:" Can I run Premature Anne on LLEAP?" },
                        { link:"/articles/000003421 ", text:" What is the charging time for Premature Anne?" },                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "SimNewB Tetherless",
                        asset: "assets/simnewb-tetherless.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simnewb-tetherless"
                    },{
                        text: "Little Baby QCPR",
                        asset: "assets/little-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/little-baby-qcpr"
                    },{
                        text: "Resusci Baby QCPR",
                        asset: "assets/resusci-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/resusci-baby-qcpr"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default PrematureAnneBabyPage;