import { BREAKPOINTS, Card, COLORS, ComponentTextStyle, ComponentXL, ContentIcons, GlobalNavigationBar, HorizontalCard, SystemIcons } from '@laerdal/life-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import posthog from 'posthog-js';

const Contact = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  background: ${COLORS.neutral_20};
`;


const HorizontalCardFullWidth = styled(HorizontalCard)`
  width: 100%;
  div > div:nth-child(2) {
    flex:unset;
    width: 100%;
  }
`

const CantFindCreateCase = () => {    
    const navigate = useNavigate()
  
    return (
      <Contact>
            <ComponentXL textStyle={ComponentTextStyle.Bold}>
              Can’t find what you need?
            </ComponentXL>
            <HorizontalCardFullWidth
                icon={<ContentIcons.Note />}
                variant="elevated"
                title="Get Support"
                description="Explore our support options to find the help you need. Click here to get started and connect with our expert team."
                action={() => { 
                  posthog?.capture('SUPP-GetSupport',{
                    url: window.location.href,
                    ref: document.referrer
                  });
                  //window.location.href = 'https://laerdal.com/support/customer-service/contact-us/'
                  navigate('/case/create');
                }}
                actions={[{
                    componentType: 'icon', 
                    action: ()=>{ 
                      posthog?.capture('SUPP-GetSupport',{
                        url: window.location.href,
                        ref: document.referrer
                      });
                      //window.location.href = 'https://laerdal.com/support/customer-service/contact-us/'
                      navigate('/case/create'); 
                     }, 
                    icon: <SystemIcons.OpenNewWindow/>
                  }
                ]}/>
          </Contact>
    );
}

export default CantFindCreateCase;

