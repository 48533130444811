import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const PocketMaskPage = () => {
    return (
      <>
        <ProductTopSection
            title="Pocket Mask"
            description="The Laerdal Pocket Mask is designed for mouth-to-mask ventilation of a non-breathing adult, child or infant as a barrier between the rescuer and patient. For use on an infant, the mask may be turned 180° to achieve a better seal. "
            asset="assets/pocket-mask.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'Pocket Mask'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=268"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <GetToKnow 
                    title="Get to know your Pocket Mask"
                    items={[{
                            title: "Is the Pocket Mask reusable?",
                            subtitle: "",
                            link: "/articles/000007199"
                        },{
                            title: "What is the intended use of the Pocket Mask?",
                            subtitle: "",
                            link: "/articles/000007193"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000007198 ", text:" What material is the Pocket Mask one-way valve made of?" },
                        { link:"/articles/000007200 ", text:" How do I clean and disinfect the Pocket Mask mask?" },
                        { link:"/articles/000007195 ", text:" What is included with the different Pocket Mask configurations?" },
                        { link:"/articles/000007202 ", text:" What is the shelf life of a Pocket Mask mask?" },
                        { link:"/articles/000007199 ", text:" Is the Pocket Mask reusable?" },
                        { link:"/articles/000007194 ", text:" What are the different packaging options for Pocket Mask?" },
                        { link:"/articles/000007203 ", text:" Is there an option for a Private Labeled packaging for Pocket Mask and Laerdal Face Shield?" },
                        { link:"/articles/000007196 ", text:" Does the Pocket Mask with oxygen inlet include a headstrap?" },
                        { link:"/articles/000007197 ", text:" Does Pocket Mask or any of its components contain latex?" },
                        { link:"/articles/000007201 ", text:" Are the filter and One-way valve in the Pocket Mask reusable?" },
                        { link:"/articles/000007193 ", text:" What is the intended use of the Pocket Mask?" }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default PocketMaskPage;