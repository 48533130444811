import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SimMan3GEssentialPage = () => {
    return (
      <>
        <ProductTopSection
            title="SimMan 3G Essential"
            description="SimMan Essential is a cost-effective solution used to enhance team performance and build an individuals core skills in airway, breathing, cardiac and circulation management."
            asset="assets/simman-3g-essential.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Male manikin',
                link: '/male-manikin'
            }, {
                text: 'SimMan 3G Essential'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=340"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/male-manikin/simman-3g-essential/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/male-manikin/simman-3g-essential/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Arms",
                            link:"/male-manikin/simman-3g-essential/arms"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/articles/000001148"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Legs",
                            link:"/male-manikin/simman-3g-essential/legs"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Pelvis",
                            link:"/male-manikin/simman-3g-essential/pelvis"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/articles/000001433"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your SimMan 3G Essential"
                    items={[{
                            title: "What is the general manikin handling for SimMan Essential?",
                            subtitle: "",
                            link: "/articles/000002120"
                        },{
                            title: "Does SimMan Essential contain latex?",
                            subtitle: "",
                            link: "/articles/000001755"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000002099 ", text:" Can a SimMan Essential be upgraded to a SimMan Essential Bleeding?" },
                        { link:"/articles/000004377 ", text:" What wound modules are available for the SimMan Essential?" },
                        { link:"/articles/000002646 ", text:" How many uses should I be able to get out of the SimMan Essential IV Arm?" },
                        { link:"/articles/000004238 ", text:" How to clean the SimMan Essential IV Arm?" },
                        { link:"/articles/000001785 ", text:" How to set up the IV arm on a SimMan Essential?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kelly",
                            asset: "assets/megacode-kelly.png",
                            heightPx: "144px",
                            link: "/male-manikin/megacode-kelly"
                        },{
                            text: "SimMan 3G Essential Bleeding",
                            asset: "assets/simman-3g-essential-bleeding.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential-bleeding"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SimMan3GEssentialPage;