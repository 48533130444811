import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SimJuniorPage = () => {
    return (
      <>
        <ProductTopSection
            title="SimJunior"
            description="SimJunior represents a 6-year-old boy that simulates a wide range of conditions from a healthy, talking child to an unresponsive, critical patient with no vital signs. SimJunior allows learners to focus on a broad range of pediatric skills in order to gain exposure and practical experience of life-threatening pediatric problems."
            asset="assets/sim-junior.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            }, {
                text: 'SimJunior'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=341"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/kid-junior-manikin/sim-junior/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/kid-junior-manikin/sim-junior/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/kid-junior-manikin/sim-junior/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Legs",
                            link:"/kid-junior-manikin/sim-junior/legs"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Software",
                            link:"/kid-junior-manikin/sim-junior/software"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/kid-junior-manikin/sim-junior/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your SimJunior"
                    items={[{
                            title: "What type of equipment and sizes do fit SimJunior?",
                            subtitle: "",
                            link: "/articles/000005856"
                        },{
                            title: "Where is the serial number located on SimJunior?",
                            subtitle: "",
                            link: "/articles/000003329"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003605 ", text:" How much does SimJunior (manikin only) weigh?" },
                        { link:"/articles/000001431 ", text:" Is SimJunior (232-05050) Guidelines compliant?" },
                        { link:"/articles/000003329 ", text:" Where is the serial number located on SimJunior (232-05050)?" },
                        { link:"/articles/000002066 ", text:" What regulatory compliance does SimJunior (232-05050) have?" },
                        { link:"/articles/000003346 ", text:" What ships with SimJunior (232-05050)?" },
                        { link:"/articles/000003327 ", text:" Where can I find a copy of the User Guide (DfU) for SimJunior (232-05050)?" },
                        { link:"/articles/000005856 ", text:" What type of equipment and sizes do fit SimJunior?" },
                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kid",
                            asset: "assets/megacode-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/megacode-kid"
                        },{
                            text: "Nursing Kid",
                            asset: "assets/nursing-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/nursing-kid"
                        },{
                            text: "Resusci Junior QCPR",
                            asset: "assets/resusci-junior-qcpr.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/resusci-junior-qcpr"
                        },{
                            text: "SimJunior",
                            asset: "assets/sim-junior.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/sim-junior"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SimJuniorPage;