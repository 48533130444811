import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ResusciAnneQcprPage = () => {
    return (
      <>
        <ProductTopSection
            title="Resusci Anne QCPR"
            description="Resusci Anne QCPR is designed for High Quality CPR training. To enable real time feedback and debriefing, connect to SkillGuide, SimPad or any device with QCPR Learner/Instructor app installed."
            asset="assets/resusci-anne-qcpr.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Female manikin',
                link: '/female-manikin'
            }, {
                text: 'Resusci Anne QCPR'
            }]}
            buttonLink="https://laerdal.com/us/ProductDownloads.aspx?productId=393"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/resusci-anne-qcpr-how-to-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/female-manikin/resusci-anne-qcpr/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/female-manikin/resusci-anne-qcpr/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/female-manikin/resusci-anne-qcpr/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Mobile Application",
                            link:"/female-manikin/resusci-anne-qcpr/mobile-application"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Software",
                            link:"/female-manikin/resusci-anne-qcpr/software"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/female-manikin/resusci-anne-qcpr/torso"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"UpgradeKits",
                            link:"/female-manikin/resusci-anne-qcpr/upgrade-kits"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Resusci Anne QCPR"
                    items={[{
                            title: "What is Resusci Anne QCPR, Resusci Junior QCPR and Resusci Baby QCPR?",
                            subtitle: "",
                            link: "/articles/000005678"
                        },{
                            title: "Resusci QCPR Manikins FAQs - Serial Number Location",
                            subtitle: "",
                            link: "/articles/000006161"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006161 ", text:" Resusci QCPR Manikins FAQs - Serial Number Location" },
                        { link:"/articles/5678 ", text:" What is Resusci Anne QCPR, Resusci Junior QCPR and Resusci Baby QCPR?" },
                        { link:"/articles/000005742 ", text:" Which Resusci manikins work with HeartCode?" },
                        { link:"/articles/000006158 ", text:" Where is the serial number located on my Resusci Anne QCPR?" },
                        { link:"/articles/000003564 ", text:" Does Resusci Anne QCPR contain latex?" },
                        { link:"/articles/000001248 ", text:" Is there research articles that prove the success of using VAM manikins for CPR?" },
                        { link:"/articles/000002397 ", text:" What are the options when the clothes are dirty?" },
                        { link:"/articles/000002061 ", text:" What is the life expectancy of the Resusci QCPR manikins (number of compressions)?" },
                        { link:"/articles/000001635 ", text:" What is the maximum compression depth for Resusci Anne QCPR?" },
                        { link:"/articles/000001795 ", text:" What is the pairing code for bluetooth for Resusci Anne QCPR?" },
                        { link:"/articles/000003784 ", text:" What is wrong when my Resusci Anne QCPR turns off after a few seconds?" },
                        { link:"/articles/000001651 ", text:" What parts should be replaced at intervals for Resusci Anne QCPR?" },
                        { link:"/articles/000003887 ", text:" Is it recommended to have spare batteries available using the rechargeable Resusci Anne QCPR?" },
                        { link:"/articles/000006832 ", text:" How to mount Hard Legs onto my Resusci Anne?" },
                        { link:"/articles/000004189 ", text:" What are the differences between Pre-2018 Resusci Anne QCPR and new Resusci Anne QCPR?" },
                        { link:"/articles/000005930 ", text:" Material Specifications for Resusci Anne QCPR" },
                        { link:"/articles/000005888 ", text:" How to train Prone CPR on Resusci Anne?" },
                        { link:"/articles/000004167 ", text:" How to identify if I have a Resusci QCPR BLE enabled manikin?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-qcpr"
                        },{
                            text: "Resusci Anne QCPR",
                            asset: "assets/resusci-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-qcpr"
                        },
                        {
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default ResusciAnneQcprPage;