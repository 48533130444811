import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ResusciBabyQcprPage = () => {
    return (
      <>
        <ProductTopSection
            title="Resusci Baby QCPR"
            description="Resusci Baby QCPR is a professional-grade infant CPR manikin designed to represent a 3-month old baby, which enables professional first responders to practice CPR skills and high-performance team training. "
            asset="assets/resusci-baby-qcpr.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Resusci Baby QCPR'
            }]}
            buttonLink="https://laerdal.com/us/ProductDownloads.aspx?productId=394"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/resusci-baby-qcpr-how-to-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/baby-newborn-manikin/resusci-baby-qcpr/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/baby-newborn-manikin/resusci-baby-qcpr/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/articles/000005712"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/baby-newborn-manikin/resusci-baby-qcpr/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Resusci Baby QCPR"
                    items={[{
                            title: "What are the clinical features for Resusci Baby QCPR?",
                            subtitle: "",
                            link: "/articles/000005654"
                        },{
                            title: "Where is the serial number located on my Resusci Baby QCPR?",
                            subtitle: "",
                            link: "/articles/000006160"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>
{/* 
        <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006160 ", text:" Where is the serial number located on my Resusci Baby QCPR?" },
                        { link:"/articles/000005654 ", text:" What are the clinical features for Resusci Baby QCPR?" },
                        { link:"/articles/000003561 ", text:" Does Resusci Baby QCPR contain latex?" },
                        { link:"/articles/000001075 ", text:" What size infant does the Resusci Baby simulate?" },
                        { link:"/articles/000006005 ", text:" How to install Resusci Baby QCPR Upgrade Kit?" },
                        { link:"/articles/000005680 ", text:" What are the differences between the Resusci Baby QCPR (2013 - 2019 model) and current Resusci Baby QCPR?" },
                        { link:"/articles/000005935 ", text:" Material Specifications for Resusci Baby QCPR" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "SimNewB Tetherless",
                        asset: "assets/simnewb-tetherless.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simnewb-tetherless"
                    },{
                        text: "Little Baby QCPR",
                        asset: "assets/little-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/little-baby-qcpr"
                    },{
                        text: "Premature Anne Baby",
                        asset: "assets/premature-anne-baby.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/premature-anne-baby"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default ResusciBabyQcprPage;