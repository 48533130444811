import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SimNewBTetherlessPage = () => {
    return (
      <>
        <ProductTopSection
            title="SimNewB Tetherless"
            description="SimNewB is a newborn tetherless simulator co-created with the American Academy of Pediatrics, designed to help improve neonatal resuscitation and to meet the specific learning objectives of Neonatal Resuscitation Program. Focusing on the first 10 minutes of life, SimNewB provides realistic training for critical interventions such as lung recruitment maneuvers and advanced airway management."
            asset="assets/simnewb-tetherless.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'SimNewB Tetherless'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=160"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/simnewb-how-to-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/baby-newborn-manikin/simnewb-tetherless/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Consumables",
                            link:"/baby-newborn-manikin/simnewb-tetherless/consumables"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/baby-newborn-manikin/simnewb-tetherless/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Legs",
                            link:"/baby-newborn-manikin/simnewb-tetherless/legs"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/baby-newborn-manikin/simnewb-tetherless/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your SimNewB Tetherless"
                    items={[{
                            title: "What is the length and weight of SimNewB?",
                            subtitle: "",
                            link: "/articles/000005451"
                        },{
                            title: "Where is the product number/serial number/CE mark located on SimNewB?",
                            subtitle: "",
                            link: "/articles/000005482"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005451 ", text:" What is the length and weight of SimNewB?" },
                        { link:"/articles/000005453 ", text:" What is the operating temperature and humidity range for SimNewB?" },
                        { link:"/articles/000005460 ", text:" What solution can I use to give SimNewB a wet appearance?" },
                        { link:"/articles/000005474 ", text:" What warranty does SimNewB have?" },
                        { link:"/articles/000005619 ", text:" Will there be any 'how-to' videos for SimNewB?" },
                        { link:"/articles/000005475 ", text:" Can I have an extended warranty for my SimNewB?" },
                        { link:"/articles/000005481 ", text:" Is User Guide for SimNewB available in any other languages than English?" },
                        { link:"/articles/000001181 ", text:" What are the environmental  conditions required for SimNewB?" },
                        { link:"/articles/000005482 ", text:" Where is the product number/serial number/CE mark located on SimNewB?" }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "Little Baby QCPR",
                        asset: "assets/little-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/little-baby-qcpr"
                    },{
                        text: "Resusci Baby QCPR",
                        asset: "assets/resusci-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/resusci-baby-qcpr"
                    },{
                        text: "Premature Anne Baby",
                        asset: "assets/premature-anne-baby.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/premature-anne-baby"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SimNewBTetherlessPage;