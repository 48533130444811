import { BREAKPOINTS, Card, COLORS, GlobalNavigationBar, HorizontalCard } from '@laerdal/life-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;
const GetToKnowContainer = styled.div`
  display: flex;
  padding: 32px;
  padding: 0 16px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  max-width:900px;
  align-self: stretch;
`;

const Title = styled.div`
  color: #1D1D1F;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; 
`;

const GetToKnowSelection = styled.div`
  ${BREAKPOINTS.LARGE}{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  display: flex;
  gap: 32px;
  flex-direction: column;
`;

const GetToKnowLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.black};
    text-decoration: none; 
  }
`;

interface GetToKnowProps {
  title?: string;
  items: Item[]
}
interface Item {
  title: string;
  subtitle: string;
  link: string;
}

const GetToKnow = ({title, items}: GetToKnowProps) => {
  const navigate = useNavigate()
  
  return (
    <Container>
        <GetToKnowContainer>
          { !!title &&
            <Title>{title}</Title>
          }
          <GetToKnowSelection>
            { items.map((item, index) =>
                <GetToKnowLink href={item.link}>

                  <HorizontalCard key={`link-${index}`}
                    onClickCapture={() => {
                        return {};
                      }}
                    variant="elevated"
                    title={item.title}
                    description={item.subtitle}
                  />
                </GetToKnowLink>
              )
            }
          </GetToKnowSelection>
        </GetToKnowContainer>
      </Container>
  );
}

export default GetToKnow;