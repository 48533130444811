import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const NursingBabyPage = () => {
    return (
      <>
        <ProductTopSection
            title="Nursing Baby"
            description="The Nursing Baby SimPad is a full-size infant manikin that realistically simulates a 6-month old patient. It is specifically designed for training professionals in the practice of basic and advanced nursing techniques. Nursing Baby training includes sounds auscultation, IV and IO skills, fontanel assessment, urinary catheterization and general pediatric patient care. This infant is unrivaled for clinical training in core pediatric in-hospital clinical skills. *The SimPad PLUS System is required for operation of the Nursing Baby SimPad Capable Simulator. Sold separately."
            asset="assets/nursing-baby.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Nursing Baby'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=137"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.ProvidersOrders size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/baby-newborn-manikin/nursing-baby/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/articles/000002797"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/baby-newborn-manikin/nursing-baby/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Arms",
                            link:"/articles/000006915"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Legs",
                            link:"/baby-newborn-manikin/nursing-baby/legs"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Pelvis",
                            link:"/baby-newborn-manikin/nursing-baby/pelvis"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/baby-newborn-manikin/nursing-baby/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Nursing Baby"
                    items={[{
                            title: "What ships with Nursing Baby?",
                            subtitle: "",
                            link: "/articles/000002775"
                        },{
                            title: "Where is the serial number located on Nursing Baby?",
                            subtitle: "",
                            link: "/articles/000002779"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>
{/* 
        <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[{
                        text:"Mini Anne material specifications",
                        link:"/articles/000004082"
                    }, {
                        text:"What is Mini Family?",
                        link:"/articles/000006854"
                    }, {
                        text:"Mini Anne - CPR Course Video",
                        link:"/articles/000006009"
                    }, {
                        text:"What are the dimensions for the CPR Anytime Kit boxes?",
                        link:"/articles/000001734"
                    }, {
                        text:"How does the Mini Anne Plus clicker differ from the MiniAnne clicker?",
                        link:"/articles/000001426"
                    }, {
                        text:"What's the difference between the AHA's CPR in Schools kit and Mini Anne Plus?",
                        link:"/articles/000001668"
                    }, {
                        text:"How does the Mini Anne Plus airway differ from the MiniAnne airway?",
                        link:"/articles/000001912"
                    }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "SimNewB Tetherless",
                        asset: "assets/simnewb-tetherless.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simnewb-tetherless"
                    },{
                        text: "Little Baby QCPR",
                        asset: "assets/little-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/little-baby-qcpr"
                    },{
                        text: "Resusci Baby QCPR",
                        asset: "assets/resusci-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/resusci-baby-qcpr"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default NursingBabyPage;