import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const LittleBabyQcprPage = () => {
    return (
      <>
        <ProductTopSection
            title="Little Baby QCPR"
            description="Little Baby QCPR is a cost effective CPR manikin for Basic Life Support (BLS) training. It is a full body infant manikin model. It simulates a three (3) months old infant baby with realistic features, average physiology and anatomy."
            asset="assets/little-baby-qcpr.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Little Baby QCPR'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=570"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.ProvidersOrders size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/baby-newborn-manikin/little-baby-qcpr/documentation"
                        },{
                            icon: <ContentIcons.Cleaning size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/baby-newborn-manikin/little-baby-qcpr/head"
                        },{
                            icon: <ContentIcons.Cleaning size="48" color={COLORS.primary_500} />,
                            text:"Legs",
                            link:"/articles/000006148"
                        },{
                            icon: <ContentIcons.CPRTraining size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/baby-newborn-manikin/little-baby-qcpr/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Little Baby QCPR"
                    items={[{
                            title: "What is Little Baby QCPR?",
                            subtitle: "",
                            link: "/articles/000006013"
                        },{
                            title: "How does Little Baby QCPR work?",
                            subtitle: "",
                            link: "/articles/000005564"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[{
                        link:"/articles/000005656",
                        text:"User Guide for Little Baby QCPR"
                    },{
                        link:"/articles/000005655",
                        text:"What are the clinical features for Little Baby QCPR?"
                    },{
                        link:"/articles/000006318",
                        text:"What is 3:1 CPR Newborn protocol?"
                    },{
                        link:"/articles/000005552",
                        text:"Little Baby QCPR material specifications"
                    },{
                        link:"/articles/000006033",
                        text:"What is Little Baby QCPR?"
                    },{
                        link:"/articles/000005564",
                        text:"How does Little Baby QCPR work?"
                    }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "SimNewB Tetherless",
                        asset: "assets/simnewb-tetherless.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simnewb-tetherless"
                    },{
                        text: "Resusci Baby QCPR",
                        asset: "assets/resusci-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/resusci-baby-qcpr"
                    },{
                        text: "Premature Anne Baby",
                        asset: "assets/premature-anne-baby.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/premature-anne-baby"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default LittleBabyQcprPage;