import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const RqiOtherPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'RQI',
                link: '/training-devices/rqi'
            },{
                text: 'Computer',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000006106 ", text:" Do all employees and contractors receive annual training on information security policies and procedures?" },
                        { link:"/articles/000006047 ", text:" Definitions and Acronyms related to RQI" },
                        { link:"/articles/000006107 ", text:" Do employees receive Secure SDLC training?" },
                        { link:"/articles/000006105 ", text:" Does the organization review the information security policy?" },
                        { link:"/articles/000006101 ", text:" Does the RQI environment come under PCI-DSS compliance requirements?" },
                        { link:"/articles/000006083 ", text:" Is a Secure SDLC in place for RQI?" },
                        { link:"/articles/000006084 ", text:" Is a Static Application Security Testing to find code vulnerability in place for RQI?" },
                        { link:"/articles/000006100 ", text:" Is the SOC2 Type II interim report for Security, Availability and Confidentially Trust principles available?" },
                        { link:"/articles/000003388 ", text:" How can I identify learners that have incomplete or past due activities?" },
                        { link:"/articles/000002025 ", text:" How do I verify my RQI e-card?" },
                        { link:"/articles/000003395 ", text:" How does the Compliance stack treat deleted assignments?" },
                        { link:"/articles/000003403 ", text:" How does the Compliance Stack treat learning events?" },
                        { link:"/articles/000003687 ", text:" RQI Manikin replacement" },
                        { link:"/articles/000006097 ", text:" Are audit reports of the SOC2 Type II certification available?" },
                        { link:"/articles/000006046 ", text:" Are there any FAQs regarding RQI Information Security?" },
                        { link:"/articles/000006098 ", text:" Can a certificate of SOC2 compliance be provided?" },
                        { link:"/articles/000006104 ", text:" Does the organization follow standard security framework called ISO 27001?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default RqiOtherPage;