import axios from 'axios';
import { PartFinderItemDto } from '../../model/dto/partFinderItemDto';
import { PartFinderItemDetailsDto } from '../../model/dto/partFinderItemDetailsDto';


class PartFinderApi {
  GetTopLevel = async (search?: string, s?: string): Promise<PartFinderItemDto[]> => {
    let url = `${process.env.REACT_APP_API_URL}/PartFinder/toplevel?`;

    if (!!search) url += `&query=${search}`;
    if (!!s) url += `&s=${s}`;
    
    return axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };
  
  GetDetails = async (id: string, s?: string ): Promise<PartFinderItemDetailsDto> => {
    let url = `${process.env.REACT_APP_API_URL}/PartFinder/details/${id}?`;

    if (!!s) url += `&s=${s}`;
    
    return axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };
}

export default new PartFinderApi;