import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ResusciAnneAdvancedSkillTrainerPage = () => {
    return (
      <>
        <ProductTopSection
            title="Resusci Anne Advanced SkillTrainer"
            description="The Resusci Anne Advanced SkillTrainer (RA AST) is designed to meet the core learning objectives of intermediate to advanced healthcare. The manikin supports training for CPR, defibrillation, IV therapy and basic to intermediate (supraglottic) airway management procedures. Adding the optional first aid and rescue limbs also enables first aid and extrication practice. "
            asset="assets/resusci-anne-advanced-skilltrainer.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Female manikin',
                link: '/female-manikin'
            }, {
                text: 'Resusci Anne Advanced SkillTrainer'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=123"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/female-manikin/resusci-anne-advanced-skilltrainer/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Arms",
                            link:"/articles/000006136"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Resusci Anne Advanced SkillTrainer"
                    items={[{
                            title: "What are the clinical features for Resusci Anne Advanced SkillTrainer?",
                            subtitle: "",
                            link: "/articles/000005589"
                        },{
                            title: "Material Specifications for Resusci Anne Advanced SkillTrainer",
                            subtitle: "",
                            link: "/articles/000006044"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006136 ", text:" Does the Resusci Anne Advanced SkillTrainer contain Latex?" },
                        { link:"/articles/000005589 ", text:" What are the clinical features for Resusci Anne Advanced SkillTrainer?" },
                        { link:"/articles/000006044 ", text:" Material Specifications for Resusci Anne Advanced SkillTrainer" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-advanced-skilltrainer"
                        },{
                            text: "Resusci Anne QCPR",
                            asset: "assets/resusci-anne-advanced-skilltrainer.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-advanced-skilltrainer"
                        },
                        {
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default ResusciAnneAdvancedSkillTrainerPage;