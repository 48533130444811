import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const KidJuniorPage = () => {
    return (
      <>
        <ProductTopSection
            title="Kid/Junior manikin"
            description=""
            asset="assets/kid-junior-manikin.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Kid/Junior manikin'
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Topics 
                    items={[{
                            text: "Little Junior QCPR",
                            asset: "assets/little-junior-qcpr.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/little-junior-qcpr"
                        },{
                            text: "MegaCode Kid",
                            asset: "assets/megacode-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/megacode-kid"
                        },{
                            text: "Nursing Kid",
                            asset: "assets/nursing-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/nursing-kid"
                        },{
                            text: "Resusci Junior QCPR",
                            asset: "assets/resusci-junior-qcpr.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/resusci-junior-qcpr"
                        },{
                            text: "SimJunior",
                            asset: "assets/sim-junior.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/sim-junior"
                        }
                    ]}
                />
                
                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003605 ", text:" How much does SimJunior (manikin only) weigh?" },
                        { link:"/articles/000001431 ", text:" Is SimJunior (232-05050) Guidelines compliant?" },
                        { link:"/articles/000003329 ", text:" Where is the serial number located on SimJunior (232-05050)?" },
                        { link:"/articles/000002066 ", text:" What regulatory compliance does SimJunior (232-05050) have?" },
                        { link:"/articles/000003346 ", text:" What ships with SimJunior (232-05050)?" },
                        { link:"/articles/000003327 ", text:" Where can I find a copy of the User Guide (DfU) for SimJunior (232-05050)?" },
                        { link:"/articles/000005856 ", text:" What type of equipment and sizes do fit SimJunior?" }
                    ]}  
                    />
                <CantFindWhatYouNeed />
                <BigSmallContent 
                    big={{
                        title: "Laerdal Global Warranty",
                        description: "In the event that your Laerdal Medical product needs warranty service, please contact Laerdal Medical or the Authorized Dealer from whom it was purchased. In order to avoid any unnecessary inconvenience on your part, we suggest reading the Directions for Use carefully before contacting Laerdal Medical or our Authorized Dealer.",
                        linkText: "PDF",
                        linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                    }}
                    small={{
                        title: "Privacy Statement",
                        description: "This statement describes the purposes for which personal information is collected, parties with whom we may share it, and measures we take to protect your personal information.",
                        linkText: "Read more",
                        linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                    }}/>
                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default KidJuniorPage;