import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ResusciJuniorQcprPage = () => {
    return (
      <>
        <ProductTopSection
            title="Resusci Junior QCPR"
            description="Resusci Junior QCPR is a professional-grade pediatric CPR manikin designed to represent a 5-6 year old boy, which enables professional first responders to practice CPR skills and high-performance team training."
            asset="assets/resusci-junior-qcpr.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            }, {
                text: 'Resusci Junior QCPR'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=533"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/resusci-junior-qcpr-how-to-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/kid-junior-manikin/resusci-junior-qcpr/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/kid-junior-manikin/resusci-junior-qcpr/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Consumables",
                            link:"/articles/000006194"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/kid-junior-manikin/resusci-junior-qcpr/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/kid-junior-manikin/resusci-junior-qcpr/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Resusci Junior QCPR"
                    items={[{
                            title: "Material Specifications for Resusci Junior QCPR",
                            subtitle: "",
                            link: "/articles/000005936"
                        },{
                            title: "Where is the serial number located on my Resusci Junior QCPR?",
                            subtitle: "",
                            link: "/articles/000006159"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006159 ", text:" Where is the serial number located on my Resusci Junior QCPR?" },
                        { link:"/articles/000004411 ", text:" Does Resusci Junior QCPR contain latex?" },
                        { link:"/articles/000006310 ", text:" How to upgrade from Standard to Airway Head on Resusci Junior QCPR?" },
                        { link:"/articles/000005936 ", text:" Material Specifications for Resusci Junior QCPR" },                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kid",
                            asset: "assets/megacode-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/megacode-kid"
                        },{
                            text: "Nursing Kid",
                            asset: "assets/nursing-kid.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/nursing-kid"
                        },{
                            text: "Resusci Junior QCPR",
                            asset: "assets/resusci-junior-qcpr.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/resusci-junior-qcpr"
                        },{
                            text: "SimJunior",
                            asset: "assets/sim-junior.png",
                            heightPx: "144px",
                            link: "/kid-junior-manikin/sim-junior"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default ResusciJuniorQcprPage;