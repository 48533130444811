import { BREAKPOINTS, COLORS } from '@laerdal/life-react-components';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SubTopics = styled.div`
  ${BREAKPOINTS.MEDIUM}{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  ${BREAKPOINTS.LARGE}{
    display: flex;
  }
  display: column;
  justify-content: center;
  
  padding: 0px 128px;
  flex-direction: row;
  align-items: flex-start;
  gap: 53px;
`;

const Subtopic = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.black};
    text-decoration: none; 
  }

  display: flex;
  padding: 46px 0px 40px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 22px;

  p {
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
    margin: 0;
    padding: 0;
  }
`;

interface SubtopicsProps {
  items: FaqItem[]
}
interface FaqItem {
  icon: ReactNode;
  text: string;
  link: string;
}

const Subtopics = ({items}: SubtopicsProps) => {
    const navigate = useNavigate()
      
    return (
        <SubTopics>
          { items.map((item, index) =>
              <Subtopic 
                onClick={(e)=>{
                  e.preventDefault();
                  if(item.link!.startsWith('http'))
                    window.location.href = item.link!;
                  else
                    navigate(item.link!);
                }}
                href={item.link} 
                key={`link-${index}`}>
                {item.icon}
                <p>{item.text}</p>
              </Subtopic>
            )
          }
        </SubTopics>
    );
}

export default Subtopics;