import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../../commonComponents/Topics";
import Subtopics from "../../../../../commonComponents/Subtopics";
import Faqs from "../../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const AedTrainer3SoftwarePage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'AED Trainer',
                link: '/training-devices/aed-trainer'
            }, {
                text: 'AED Trainer 3',
                link: '/training-devices/aed-trainer/aed-trainer-3'
            },{
                text: 'Software',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Software"
                    items={[ 
                        { link:"/articles/000001337 ", text:" How to update AED Trainer 3 software or change scenario parameters?" },
                        { link:"/articles/000005540 ", text:" Software for AED Trainer 3" },
                        { link:"/articles/000002514 ", text:" Is it possible to create custom training scenarios on the AED Trainer 3?" },
                    ]}/>
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default AedTrainer3SoftwarePage;