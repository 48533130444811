import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LaerdalCompactSuctionUnitCabinetCasePage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Medical devices',
                link: '/medical-devices'
            },{
                text: 'Laerdal Compact Suction Unit',
                link: '/medical-devices/laerdal-compact-suction-unit'
            },{
                text: 'Cabinet/Case',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000007167 ", text:" Where can I find the most up to date User Guide for the LCSU 4?" },
                        { link:"/articles/000007145 ", text:" What do I do if I suspect that liquid has entered the LCSU 4 pump?" },
                        { link:"/articles/000007149 ", text:" How to perform Battery Test on LCSU 4?" },
                        { link:"/articles/000007136 ", text:" Is it necessary to use a scale or gauge when setting the LCSU 4 vacuum level?" },
                        { link:"/articles/000007153 ", text:" How to connect the battery in LCSU 4?" },
                        { link:"/articles/000007133 ", text:" Does the battery charge automatically when the AC/DC cable is connected to the LCSU 4?" },
                        { link:"/articles/000007130 ", text:" Can LCSU 4 be used for endotracheal suctioning?" },
                        { link:"/articles/000007135 ", text:" How often should I replace the battery in my LCSU 4?" },
                        { link:"/articles/000007129 ", text:" How frequently do I need to charge the battery if LCSU 4 is not used?" },
                        { link:"/articles/000007125 ", text:" What is the battery run time for LCSU 4?" },
                        { link:"/articles/000007154 ", text:" What is the Catalog Number for the LCSU 4 Battery?" },
                        { link:"/articles/000007166 ", text:" Why are there different dates on LCSU 4 batteries and labels?" },
                        
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LaerdalCompactSuctionUnitCabinetCasePage;