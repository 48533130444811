import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneQcprAccessoriesPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne QCPR',
                link: '/female-manikin/resusci-anne-qcpr'
            },{
                text: 'Accessories',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1000} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000001887 ", text:" Can I use the trauma legs on the Resusci Anne QCPR?" },
                        { link:"/articles/000004281 ", text:" How to install Resusci Anne QCPR Periodic Maintenance Kit?" },
                        { link:"/articles/000002618 ", text:" Is there a carry bag for the Resusci Anne First Aid / Trauma modules?" },
                        { link:"/articles/000006295 ", text:" How should I store the Resusci Anne hard, articulated Legs?" },
                        { link:"/articles/000006865 ", text:" Where can I find Safety Data Sheets (SDS) for Laerdal provided chemicals?" },
                        { link:"/articles/000006834 ", text:" Upgrade Resusci Anne torso model to full body manikin with arms and legs" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneQcprAccessoriesPage;