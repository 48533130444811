import { BREAKPOINTS, Button, COLORS, HyperLink, Size } from '@laerdal/life-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const FullPageContainer = styled.div`
  display:flex;
`;
const TopSectionContainer = styled.div`
  margin: 32px auto 0 auto;
  
  max-width: 1000px;
  padding: 0 16px ;
  flex-grow: 1;
  display:flex;
`;
const Breadcrumb = styled.div`
  padding:12px 0;
`;

const CrumbLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

const CrumbText = styled.span`
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

const Devider = styled.span`
  padding: 0 10px;
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

interface Props {
  breadcrumb: Crumb[]
}
interface Crumb {
  text: string;
  link?: string;
}

const BreadcrumbTopSection = (props: Props) => {    
  const navigate = useNavigate()
  
  return (
      <FullPageContainer>
        <TopSectionContainer>
          <Breadcrumb>
            { props.breadcrumb.map((item, index) =>
                <>
                  {!!item.link &&
                    <CrumbLink 
                      onClick={(e)=>{
                        e.preventDefault();
                        navigate(item.link!);
                      }}
                      href={item.link!}
                      >
                        {item.text}
                    </CrumbLink>
                  }
                  {!item.link &&
                    <CrumbText>
                        {item.text}
                    </CrumbText>
                  }
                  
                  {(props.breadcrumb.length != index + 1) &&
                    <Devider>/</Devider>
                  }
                </>
              )
            }
          </Breadcrumb>
        </TopSectionContainer>
      </FullPageContainer>
  );
}

export default BreadcrumbTopSection;