import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingAnneTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Nursing Anne',
                link: '/female-manikin/nursing-anne'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000001893 ", text:" Are the Fundus Balls available as spare part?" },
                        { link:"/articles/000003594 ", text:" Can I fill the Nursing Anne lungs with fluid?" },
                        { link:"/articles/000003595 ", text:" Can Nursing Anne be defibrillated?" },
                        { link:"/articles/000003653 ", text:" Do Nursing Anne and Nursing Kelly use the same stomach reservoir?" },
                        { link:"/articles/000001108 ", text:" Does the adult colon reservoir ship with the connectors and clamps?" },
                        { link:"/articles/000001056 ", text:" Is the Nursing Anne Basic chest plate without ECG available as spare part?" },
                        { link:"/articles/000003252 ", text:" Is the Nursing Anne manikin series available in ethnic skin tones?" },
                        { link:"/articles/000003259 ", text:" What is the fluid capacity of the colon reservoir for Nursing Anne and Nursing Kelly?" },
                        { link:"/articles/000003260 ", text:" What is the fluid capacity of the lung bags for Nursing Anne and Nursing Kelly?" },
                        { link:"/articles/000001054 ", text:" What is the stomach reservoir capacity for Nursing Anne?" },
                        { link:"/articles/000001766 ", text:" Can I perform CPR on a Nursing Anne manikin?" },
                        { link:"/articles/000003591 ", text:" Can Nursing Anne produce fetal tones?" },                            
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingAnneTorsoPage;