import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneSimulatorDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne Simulator',
                link: '/female-manikin/resusci-anne-simulator'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000005565", text:" What are the clinical features for Resusci Anne Simulator?" },
                        { link:"/articles/000002585", text:" Does the Resusci Anne Simulator contain latex?" },
                        { link:"/articles/000001894", text:" What circulation features does Resusci Anne Simulator have?" },
                        { link:"/articles/000001591", text:" What is the warranty on the Resusci Anne Simulator with SimPad?" },
                        { link:"/articles/000001471", text:" What Resusci Anne Simulator BP readings are controlled by SimPad?" },
                        { link:"/articles/000001437", text:" What Resusci Anne Simulator pulses are controlled by SimPad?" },
                        { link:"/articles/000002304", text:" Why did I receive a manual air pump with the Resusci Anne Simulator?" },
                        { link:"/articles/000001383", text:" Will I damage the compressed air container in the Resusci Anne Simulator if I overfill it?" },
                        { link:"/articles/000007180", text:" Where is the serial number located on my Resusci Anne Simulator / Resusci Anne Advanced SkillTrainer?" },
                        { link:"/articles/000006036", text:" Material Specifications for Resusci Anne Simulator" },
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneSimulatorDocumentationPage;