import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SimBabyMKIIPage = () => {
    return (
      <>
        <ProductTopSection
            title="SimBaby MK II"
            description="SimBaby™ is a tetherless simulator designed to help healthcare providers effectively recognize and respond to critically ill pediatric patients. The SimBaby simulator represents a 9-month-old pediatric patient and provides a highly realistic manikin that meets specific learning objectives focusing on initial assessment and treatment. This SimBaby comes in a medium skin tone."
            asset="assets/simbaby-mk-ii.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'SimBaby MK II'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=7"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/simbaby-how-to-videos/"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/baby-newborn-manikin/simbaby-mk-ii/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Arms",
                            link:"/baby-newborn-manikin/simbaby-mk-ii/arms"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/baby-newborn-manikin/simbaby-mk-ii/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Legs",
                            link:"/baby-newborn-manikin/simbaby-mk-ii/legs"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/baby-newborn-manikin/simbaby-mk-ii/torso"
                        },{
                            icon: <ContentIcons.Assignments size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/baby-newborn-manikin/simbaby-mk-ii/documentation"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your SimBaby"
                    items={[{
                            title: "Are 'how-to' videos available for SimBaby?",
                            subtitle: "",
                            link: "/articles/000005581"
                        },{
                            title: "What parts are available for the new SimBaby?",
                            subtitle: "",
                            link: "/articles/000004089"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[{
                        text:"Are 'how-to' videos available for SimBaby?",
                        link:"/articles/000005581"
                    },{
                        text:"What is the recommended ventilation volume for SimBaby when connected to a ventilator?",
                        link:"/articles/000005664"
                    },{
                        text:"What is the recommended setting for breaths per minute on a ventilator?",
                        link:"/articles/000005663"
                    },{
                        link:"/articles/000004092",
                        text:"Can I get a replacement User Guide for my SimBaby?"
                    },{
                        link:"/articles/000004109",
                        text:"Is the new SimBaby available in different skin tones?"
                    },{
                        link:"/articles/000004111",
                        text:"Does the SimBaby contain any latex parts on the simulator or the consumables?"
                    },{
                        link:"/articles/000004083",
                        text:"What devices can I control my SimBaby with?"
                    },{
                        link:"/articles/000004084",
                        text:"What is the length and weight of SimBaby?"
                    },{
                        link:"/articles/000006206",
                        text:"Where is the serial number located on SimBaby?"
                    },{
                        link:"/articles/000004089",
                        text:"What parts are available for the new SimBaby?"
                    },{
                        link:"/articles/000004100",
                        text:"What size needle is recommended when using with the new SimBaby IO leg?"
                    },{
                        link:"/articles/000004093",
                        text:"Is the User Guide for SimBaby available in any other languages than English?"
                    }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "SimNewB Tetherless",
                        asset: "assets/simnewb-tetherless.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simnewb-tetherless"
                    },{
                        text: "Little Baby QCPR",
                        asset: "assets/little-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/little-baby-qcpr"
                    },{
                        text: "Resusci Baby QCPR",
                        asset: "assets/resusci-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/resusci-baby-qcpr"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SimBabyMKIIPage;