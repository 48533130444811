import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LittleBabyQcprDocumentation = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Little Baby QCPR',
                link: '/baby-newborn-manikin/little-baby-qcpr'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[{
                        link:"/articles/000005656",
                        text:"User Guide for Little Baby QCPR"
                    },{
                        link:"/articles/000005655",
                        text:"What are the clinical features for Little Baby QCPR?"
                    },{
                        link:"/articles/000006318",
                        text:"What is 3:1 CPR Newborn protocol?"
                    },{
                        link:"/articles/000005552",
                        text:"Little Baby QCPR material specifications"
                    },{
                        link:"/articles/000006033",
                        text:"What is Little Baby QCPR?"
                    },{
                        link:"/articles/000005564",
                        text:"How does Little Baby QCPR work?"
                    }]}/>

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LittleBabyQcprDocumentation;