import { BREAKPOINTS, Button, COLORS, ComponentL, ComponentM, ComponentTextStyle, HorizontalCard, HyperLink, LoadingIndicator, PageWidth, Paginator, SearchBar, Size, SystemIcons } from "@laerdal/life-react-components";
import React, { useEffect } from "react";
import { useState } from "react";
import {  useLocation } from "react-router";
import styled from "styled-components";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import ArticleApi from "../../services/api/ArticleApi";
import {useDebouncedValue} from 'rooks';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import posthog from 'posthog-js';
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import {useMediaMatch} from "rooks";

const HeaderContainer = styled.div`
  background: ${COLORS.neutral_20} ;
  
  display: flex;
  flex-direction: column;
  
  width: 100%;
`;


const Search = styled.div`
  ${BREAKPOINTS.LARGE}{
    width:unset;
    padding:0
  }
  width:calc(100% - 32px);
  padding: 0 16px;
`;

const TopFrame = styled(PageWidth)`
  ${BREAKPOINTS.LARGE}{
    margin:64px 0;
  }
  margin:32px 0;

  width:100%;
  flex-grow: 1;  

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  
  flex-shrink: 0;
`;

const BreadcrumbFrame = styled(PageWidth)`

  ${BREAKPOINTS.LARGE}{
    width:100%;
    padding:0
  }
  max-width: 1024px;
  margin: 32px auto;
  width:calc(100% - 32px);
  padding: 0 16px;

  margin:32px 0;
  flex-grow: 0;  

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  
  flex-shrink: 0;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0 0 0;
`;

const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;

  li{
    width:40px !important;
  }
`;

const ArticleContainers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
`;

const ArticlePage = styled.div`
  margin: 0 0 50px 0;
`;

const NoResults = styled.div`
  border-radius: 8px;
  background: ${COLORS.neutral_20};
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Article = styled.div`
  margin-top:20px
`;
const ShowingLabel = styled.div`
  color: ${COLORS.neutral_600};
  font-feature-settings: 'liga' off;
  /* Components/Regular/S */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;
const ArticleDescription = styled.div`
  overflow-wrap: anywhere;

  color: ${COLORS.neutral_600};
  font-feature-settings: 'liga' off;
  /* Components/Regular/S */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;


const Breadcrumb = styled.div`
  padding:12px 0;
`;
const CrumbLink = styled.a`
  &:link, &:visited, &:hover, &:active {
    color: ${COLORS.primary_600};
    text-decoration: none; 
  }

  color: ${COLORS.primary_600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;
const CrumbText = styled.span`
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;
const Devider = styled.span`
  padding: 0 10px;
  color: ${COLORS.neutral_500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; 
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const ArticleSearchPage = () => {
  let query = useQuery();

  const navigate = useNavigate()

  const [searchTerm, setSearchTerm] = useState<string>(query.get("query") ?? '');

  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [articlesLoading, setArticlesLoading] = useState<boolean>(true);
  const [articles, setArticles] = useState<KnowledgeArticleListItemDto[]>([]);

  const [showingFirstIndex, setShowingFirstIndex] = useState<number>(1);
  const [showingLastIndex, setShowingLastIndex] = useState<number>(1);
  const [showingTotlCount, setShowingTotlCount] = useState<number>(1);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [searchTermDebounce] = useDebouncedValue(searchTerm, 500);

  const [abortController, setAbortController] = useState(new AbortController());

  const isMediumScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));

  useEffect(() => {

    // @ts-ignore
    posthog.capture('ArticleSearchPage Search',{
      searchTerm: searchTermDebounce
    });

    const routeSearchTerm = searchTerm ? `?query=${searchTerm}` : '';
    navigate(`/articles${routeSearchTerm}`);

    setShowPagination(false);
    setPage(1);
  }, [searchTermDebounce]);

  const articleCountPerPage = 12;
  const setPage = (page: number) => {
    // @ts-ignore
    window.scrollTo({top: 0, behavior: "instant"});
    setArticles([])
    setArticlesLoading(true);
    
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    const arrayIndex = page - 1;
    ArticleApi.GetKnowledgeArticles(articleCountPerPage, arrayIndex, searchTermDebounce, 'all', newAbortController.signal)
      .then((data) => {
        setArticles(data.items);
        setCurrentPage(page);
        setPageCount(Math.floor(data.totalCount / articleCountPerPage) + 1);
        
        
        setShowingFirstIndex((page - 1) * articleCountPerPage + 1);
        setShowingLastIndex( data.totalCount < page * articleCountPerPage ? data.totalCount : page * articleCountPerPage);
        setShowingTotlCount(data.totalCount);

        setShowPagination(true)

        if(data.items.length == 0)
        {
          // @ts-ignore
          posthog.capture('ArticleSearchPage SearchNoResults',{
            searchTerm: searchTerm,
            page: page
          });
        }
        setArticlesLoading(false)
      })
      .catch(error => {
        if(!axios.isCancel(error)) {
          setArticlesLoading(false)
        }
      })
  }

  return (
    <>
      <BreadcrumbFrame useMaxWidth={true} maxWidth={1024}>
        <Breadcrumb>
          <CrumbLink href='/'>
            Home
          </CrumbLink>
          <Devider>/</Devider>
          <CrumbText>
            Search results
          </CrumbText>
        </Breadcrumb>
      </BreadcrumbFrame>
      <HeaderContainer>
        <TopFrame useMaxWidth={true} maxWidth={ 1024 }>
          <Search>
            <SearchBar
              id="DemoSearchBarSmall"
              placeholder="Type a question or search by keyword"
              searchTerm={searchTerm}
              enterSearch={() => {
                
              }}
              setSearchTerm={(a)=>{ setSearchTerm(a)}}
              
              removeSearch={() => {
                setSearchTerm('');
                //@ts-ignore
                document.getElementById('DemoSearchBarSmall').focus()
              }}
              size={Size.Small}
            />
          </Search>
        </TopFrame>
      </HeaderContainer>
      <ArticlePage>
        <PageWidth useMaxWidth={true} maxWidth={ 1024  }>
          <SearchContainer>
            {articlesLoading && <LoadingIndicator />}

            { !articlesLoading &&
              <ArticleContainers key="newToSimCpature">
                <ShowingLabel>{`Showing ${showingFirstIndex}-${showingLastIndex} of ${showingTotlCount} items`}</ShowingLabel>
                {
                  articles.map((item, index )=>
                  (
                    <Article>
                      <HyperLink 
                        variant="default" 
                        href={`/articles/${item.articleNumber}`} 
                        onClick={(e)=>{
                          e.preventDefault();
                          // @ts-ignore
                          posthog.capture('ArticleSearchPage OpenArticle',{
                            searchTerm: searchTerm,
                            relatedArticle: item.articleNumber,
                            rank: index,
                            page: currentPage
                          });
                          navigate(`/articles/${item.articleNumber}`) 
                        }}>{item.title}</HyperLink>
                      <ArticleDescription>{item.description}</ArticleDescription>
                    </Article>
                  ))
                }
              </ArticleContainers>
            }

            {!articlesLoading && articles.length == 0 && 
              <NoResults>
                <NoResultsContainer>
                  <ComponentL textStyle={ComponentTextStyle.Bold}>The search did not return any results</ComponentL>
                  <ComponentM>There are no results for {searchTerm}</ComponentM>
                </NoResultsContainer>
                
                <NoResultsContainer>
                  <ComponentL textStyle={ComponentTextStyle.Bold}>Search tips</ComponentL>
                  <ComponentM>&#9679; Try searching by product type or SKU number</ComponentM>
                  <ComponentM>&#9679; Check the spelling or try other keywords</ComponentM>
                </NoResultsContainer>
                
                <NoResultsContainer>
                  <ComponentL textStyle={ComponentTextStyle.Bold}>Need help?</ComponentL>
                  <ComponentM>Feel free to reach out if you are experiencing any difficulties. </ComponentM>
                </NoResultsContainer>

                <CantFindCreateCase />
              </NoResults>
            }

            {showPagination && articles.length != 0 && 
              <PaginatorContainer>
                <Paginator 
                  pageCount={pageCount}
                  currentPage={currentPage}
                  baseUrl="/"
                  onPageChange={(p) => {
                              // @ts-ignore
                              posthog.capture('ArticleSearchPage PaginationChange',{
                                currentPage: currentPage,
                                newPage: p
                              });
                              setPage(p)
                              return true;
                            }}
                  />
              </PaginatorContainer>
            }
          </SearchContainer>

        </PageWidth>
      </ArticlePage> 
    </>);
};

export default ArticleSearchPage;