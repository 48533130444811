import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimJuniorAccessoriesPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            },{
                text: 'SimJunior',
                link: '/kid-junior-manikin/sim-junior'
            },{
                text: 'Accessories',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000003335 ", text:" Do you have a list of spare parts available for SimJunior (232-05050)?" },
                        { link:"/articles/000001817 ", text:" Does SimJunior (232-05050) ship with male and female genitalia?" },
                        { link:"/articles/000002205 ", text:" How do I make adjustments on the ELO Monitor?" },
                        { link:"/articles/000001208 ", text:" Is there a hard carrying case for SimJunior?" },
                        { link:"/articles/000002239 ", text:" Can I use a ventilator with SimJunior?" },
                        { link:"/articles/000001529 ", text:" What is the part number for the audio cable for SimNewB, SimJunior and SimMom?" },
                        { link:"/articles/000007032 ", text:" What is the Spare Part Number for SimJunior Manikin Power Supply?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimJuniorAccessoriesPage;