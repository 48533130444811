import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciJuniorAccessoriesPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Kid/Junior manikin',
                link: '/kid-junior-manikin'
            },{
                text: 'Resusci Junior QCPR',
                link: '/kid-junior-manikin/resusci-junior-qcpr'
            },{
                text: 'Accessories',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000006198 ", text:" How to change the Lung on Resusci Junior QCPR with standard Head?" },
                        { link:"/articles/000006197 ", text:" How to change the Lung on Resusci Junior QCPR with Airway Head?" },
                        { link:"/articles/000006193 ", text:" What can be upgraded using the new Resusci Junior Airway Head Upgrade Kit?" },
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciJuniorAccessoriesPage;