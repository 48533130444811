import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const LaerdalCompactSuctionUnitPage = () => {
    return (
      <>
        <ProductTopSection
            title="Laerdal Compact Suction Unit"
            description="The Laerdal Compact Suction Unit (LCSU) 4 makes suctioning in the most difficult situations, easier. The LCSU 4 offers two canister sizes, the smaller 300 ml canister and the larger 800 ml canister. This compact suction unit has a battery life of up to 45 minutes continuous use on free flow suction, allowing healthcare providers to tackle the toughest challenges."
            asset="assets/laerdal-compact-suction-unit.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'Laerdal Compact Suction Unit'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=371"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/laerdal-suction-unit/"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories LSR",
                            link:"/medical-devices/laerdal-compact-suction-unit/accessories-lsr"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Cabinet/Case",
                            link:"/medical-devices/laerdal-compact-suction-unit/cabinet-case"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Canister",
                            link:"/medical-devices/laerdal-compact-suction-unit/canister"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/medical-devices/laerdal-compact-suction-unit/documentation"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Laerdal Compact Suction Unit"
                    items={[{
                            title: "What materials are the LCSU 4 made of?",
                            subtitle: "",
                            link: "/articles/000007134"
                        },{
                            title: "How should I clean LCSU 4?",
                            subtitle: "",
                            link: "/articles/000007137"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005752 ", text:" Discontinuation of Laerdal Compact Suction Unit (LCSU3) Support" },
                        { link:"/articles/000007155 ", text:" Is the LCSU 4 serviceable?" },
                        { link:"/articles/000007165 ", text:" Is the LCSU 4 300 ml and 800 ml canister reusable?" },
                        { link:"/articles/000007126 ", text:" What is included in the LCSU 4 300 ml version?" },
                        { link:"/articles/000007146 ", text:" Is LCSU 4 supplied with a catheter?" },
                        { link:"/articles/000007158 ", text:" Is there a trouble shooting guide for the LCSU 4?" },
                        { link:"/articles/000007157 ", text:" How do you perform a Device Test for LCSU 4?" },
                        { link:"/articles/000007138 ", text:" Has the LCSU 4 been drop tested?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default LaerdalCompactSuctionUnitPage;