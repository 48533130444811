import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const MegaCodeKellyDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Male manikin',
                link: '/male-manikin'
            },{
                text: 'MegaCode Kelly',
                link: '/male-manikin/megacode-kelly'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000003697 ", text:" What is MegaCode Kelly's weight?" },
                        { link:"/articles/000003258 ", text:" What is the difference between the two black bulbs on MegaCode Kelly manikins?" },
                        { link:"/articles/000003269 ", text:" What is the difference in functionality between the Megacode Kelly Basic and Advanced manikins?" },
                        { link:"/articles/000001812 ", text:" What items ship with the Adult IO Leg (205-10150)?" },
                        { link:"/articles/000006203 ", text:" Where is the serial number located on MegaCode Kelly?" },
                        { link:"/articles/000003276 ", text:" What ships with the MegaCode Kelly Advanced (200-05050) manikin?" },
                        { link:"/articles/000003271 ", text:" What ships with the MegaCode Kelly Basic (203-05050) manikins?" },
                        { link:"/articles/000003274 ", text:" Where can I find a copy of the User Guide (DfU) for MegaCode Kelly?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default MegaCodeKellyDocumentationPage;