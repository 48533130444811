import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const BabyNewbornPage = () => {
    return (
      <>
        <ProductTopSection
            title="Baby/Newborn Manikin"
            description=""
            asset="assets/baby-newborn-manikin-big.jpg" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn Manikin'
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Topics 
                    items={[{
                            text: "Resusci Baby FirstAid",
                            asset: "assets/resusci-baby-firstaid.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/resusci-baby-firstaid"
                        },{
                            text: "Resusci Baby QCPR",
                            asset: "assets/resusci-baby-qcpr.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/resusci-baby-qcpr"
                        },{
                            text: "SimBaby MK II",
                            asset: "assets/simbaby-mk-ii.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/simbaby-mk-ii"
                        },{
                            text: "SimNewB Tetherless",
                            asset: "assets/simnewb-tetherless.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/simnewb-tetherless"
                        },{
                            text: "Little Baby QCPR",
                            asset: "assets/little-baby-qcpr.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/little-baby-qcpr"
                        },{
                            text: "Premature Anne Baby",
                            asset: "assets/premature-anne-baby.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/premature-anne-baby"
                        },{
                            text: "Nursing Baby",
                            asset: "assets/nursing-baby.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/nursing-baby"
                        },{
                            text: "ALS Baby",
                            asset: "assets/als-baby.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/als-baby"
                        },{
                            text: "Mini Baby",
                            asset: "assets/mini-baby.png",
                            heightPx: "144px",
                            link: "/baby-newborn-manikin/mini-baby"
                        },
                    ]}
                />

                {/* <Subtopics
                    items={[{
                            icon: <ContentIcons.Pain size="48" color={COLORS.primary_500} />,
                            text:"Broken",
                            link:"resuscitation-training/broken"
                        },{
                            icon: <ContentIcons.Cleaning size="48" color={COLORS.primary_500} />,
                            text:"Leakage",
                            link:"resuscitation-training/leakage"
                        },
                    ]}
                /> */}
                
                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006160 ", text:" Where is the serial number located on my Resusci Baby QCPR?" },
                        { link:"/articles/000005654 ", text:" What are the clinical features for Resusci Baby QCPR?" },
                        { link:"/articles/000003561 ", text:" Does Resusci Baby QCPR contain latex?" },
                        { link:"/articles/000001075 ", text:" What size infant does the Resusci Baby simulate?" },
                        { link:"/articles/000006005 ", text:" How to install Resusci Baby QCPR Upgrade Kit?" },
                        { link:"/articles/000005680 ", text:" What are the differences between the Resusci Baby QCPR (2013 - 2019 model) and current Resusci Baby QCPR?" },
                        { link:"/articles/000005935 ", text:" Material Specifications for Resusci Baby QCPR" },

                    ]}  
                    />
                <CantFindWhatYouNeed />
                <BigSmallContent 
                    big={{
                        title: "Laerdal Global Warranty",
                        description: "In the event that your Laerdal Medical product needs warranty service, please contact Laerdal Medical or the Authorized Dealer from whom it was purchased. In order to avoid any unnecessary inconvenience on your part, we suggest reading the Directions for Use carefully before contacting Laerdal Medical or our Authorized Dealer.",
                        linkText: "PDF",
                        linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                    }}
                    small={{
                        title: "Privacy Statement",
                        description: "This statement describes the purposes for which personal information is collected, parties with whom we may share it, and measures we take to protect your personal information.",
                        linkText: "Read more",
                        linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                    }}/>
                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default BabyNewbornPage;