import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const LittleAnneStackablePage = () => {
    return (
      <>
        <ProductTopSection
            title="Little Anne Stackable"
            description="The latest offering in the Little Anne product line builds upon the legacy of Little Anne QCPR by providing an easy, safe and portable solution for bystander CPR training centres and instructors. QCPR measurement and feedback remains the cornerstone of quality improvement, whilst significant cuts in CO2 emissions mean that purchasing and owning Little Anne is the environmentally conscious choice for training members of the community in quality CPR."
            asset="assets/little-anne-stackable.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Female manikin',
                link: '/female-manikin'
            }, {
                text: 'Little Anne Stackable'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=112"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/little-anne-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/female-manikin/little-anne-stackable/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/articles/000006958"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/female-manikin/little-anne-stackable/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Little Anne Stackabl"
                    items={[{
                            title: "How to replace Little Anne Back Skull?",
                            subtitle: "",
                            link: "/articles/000006953"
                        },{
                            title: "How to wash Little Anne Chest Skin?",
                            subtitle: "",
                            link: "/articles/000006964"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006953 ", text:" How to replace Little Anne Back Skull?" },
                        { link:"/articles/000006955 ", text:" How to replace Little Anne Front Skull?" },
                        { link:"/articles/000006957 ", text:" How to replace Little Anne Head Elastic Band?" },
                        { link:"/articles/000006960 ", text:" How to replace Little Anne Battery Cover Lid?" },
                        { link:"/articles/000006962 ", text:" How to disassemble Little Anne 6-pack?" },
                        { link:"/articles/000006963 ", text:" How to wash Little Anne Face and Chest Skin?" },
                        { link:"/articles/000006964 ", text:" How to wash Little Anne Chest Skin?" },
                        { link:"/articles/000006965 ", text:" How to replace Little Anne Airway Filter?" },
                        { link:"/articles/000006966 ", text:" How to setup Little Anne 6-pack?" },
                        { link:"/articles/000006967 ", text:" How to setup Little Anne single unit?" },
                        { link:"/articles/000006959 ", text:" How to replace Little Anne Chest Assembly?" },
                        { link:"/articles/000007326 ", text:" What is included in spare part 135-28050 for Little Anne?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-qcpr"
                        },{
                            text: "Resusci Anne QCPR",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },
                        {
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default LittleAnneStackablePage;