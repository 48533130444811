import { BREAKPOINTS, Button, COLORS, HyperLink, IconButton, LoadingIndicator, PageWidth, Size, SystemIcons, useToast } from "@laerdal/life-react-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import HelpdeskModalDialog from "../../commonComponents/HelpdeskModalDialog";
import { KnowledgeArticleDto } from "../../model/dto/knowledgeArticleDto";
import ArticleApi from "../../services/api/ArticleApi";
import { KnowledgeArticleListItemDto } from "../../model/dto/knowledgeArticleListItemDto";
import posthog from 'posthog-js';
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";

const Containter = styled.div`
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  flex-direction: column;

  flex-grow: 1; */
    /* z-index: 1; */
    /* display: flex; */

  width:100%;
  display: flex;
  column-gap: 64px;
  margin-bottom: 64px;
`;

const FirstColumn = styled.div`
  ${BREAKPOINTS.LARGE}{
    flex: 0 0 700px;
  }
  display: flex;
  flex-direction: column;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column; 
  width:100%;
`;

const ArticleHtmlContainer = styled.div`
  img {
    max-width: 100%;
    height: auto !important;
  }
`;

const LoadingIndicatorContainer = styled.div`
  margin: 0 auto;
`;

const ArticlePageContainer = styled.div`
  margin: 0 0 50px 0;
`;

const RelatedArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
  margin: 40px 0 0 0;
`;

const DesktopH6 = styled.div`
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 30px */
  margin-bottom:8px;
`;

const LinkWrapper = styled.div`
  margin:  0 0 16px 0;
`;

const ArticlePage = () => {
  const { product, category, articleNumber, title } = useParams<{ product: string, category:string, articleNumber: string, title:string }>();
  
  const navigate = useNavigate()
  const location = useLocation();
  const [article, setArticle] = useState<KnowledgeArticleDto | undefined>(undefined);
  const [loadingArticle, setLoadingArticle] = useState<boolean>(true);
  const [articles, setArticles] = useState<KnowledgeArticleListItemDto[]>([]);
  const [articlesLoading, setArticlesLoading] = useState<boolean>(true);




  useEffect(() => {
    setArticle(undefined);
    setArticles([]);
    setLoadingArticle(true);
    setArticlesLoading(true);

    ArticleApi.GetKnowledgeArticle(articleNumber ?? '', title)
      .then((data) => {
        data.publicContent = data.publicContent ? data.publicContent.replaceAll("{{currentURL}}", location.pathname) : data.publicContent;

        data.publicContent = data.publicContent ? data.publicContent.replaceAll("<table>", '<div style="width: calc(100% - 32px); overflow-x: auto; -webkit-overflow-scrolling: touch;"><table>') : data.publicContent;
        data.publicContent = data.publicContent ? data.publicContent.replaceAll("</table>", '</table></div>') : data.publicContent;

        setArticle(data);
        setLoadingArticle(false);
        posthog.capture('ArticleView',{
          currentArticle: articleNumber,
          version: 'web'
        });

        ArticleApi.GetKnowledgeArticles(6, 0, data.title, '')
          .then((data) => {
            setArticles(data.items.filter(x => x.articleNumber != articleNumber));
            setArticlesLoading(false);
          });
        });
    }, [product, articleNumber]);

  return (
    <>
      <ArticlePageContainer>
        <PageWidth useMaxWidth={true} maxWidth={1024}>
          <Button 
            variant="tertiary" 
            icon={<SystemIcons.ArrowLineLeft />} 
            size={Size.Small} 
            onClick={() => {
              //@ts-ignore
              posthog.capture('SUPP-ArticlePage Back',{
                currentArticle: articleNumber,
              });
              window.history.back();
            }}>
            Back
          </Button>
          
          <Containter>
            { (articlesLoading || articlesLoading ) &&
              <LoadingIndicatorContainer>
                <LoadingIndicator />
              </LoadingIndicatorContainer> 
            }
            
            { !articlesLoading && !articlesLoading &&
              <FirstColumn>
                {article && <h1>{article.title}</h1>}
                {article && <ArticleHtmlContainer dangerouslySetInnerHTML={{__html: article.publicContent ?? ''}}/>}
              </FirstColumn>
            }

            { !articlesLoading && !articlesLoading &&
              <SecondColumn>
                <RelatedArticlesContainer>
                  <DesktopH6>Related articles</DesktopH6>
                  
                  { 
                        articles.map(item =>
                        (
                          <LinkWrapper key={`article-${item.articleNumber}`} >
                            <HyperLink  
                              onClick={(e)=> {
                                e.preventDefault()
                                // @ts-ignore
                                posthog.capture('ArticlePage OpenRelatedArticle',{
                                  currentArticle: articleNumber,
                                  relatedArticle: item.articleNumber,
                                });
                                navigate(`/articles/${item.articleNumber}`)
                              }}
                              id="HyperLinkDefault" 
                              variant="default" 
                              href={`#`}  >

                              {item.title}
                            </HyperLink>
                        </LinkWrapper>
                        ))
                  }
                  {!articlesLoading && articles.length == 0 && "No related articles"}
                </RelatedArticlesContainer>
              </SecondColumn>
            }
          </Containter>
          <CantFindCreateCase />
        </PageWidth>
      </ArticlePageContainer>

      
    </>);
};

export default ArticlePage;
