import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const MiniAnnePlusDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Mini Anne Plus',
                link: '/female-manikin/mini-anne-plus'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000006028 ", text:" Mini Anne Plus - CPR Course Video" },
                        { link:"/articles/000005533 ", text:" User Guide for Mini Anne Plus" },
                        { link:"/articles/000006010 ", text:" What are the product features for Mini Anne Plus?" },
                        { link:"/articles/000001634 ", text:" Is there a list of all part numbers for Mini Anne Plus?" },
                        { link:"/articles/000001978 ", text:" What are the weight and dimensions for the Kneel Mats for Mini Anne Plus?" },
                        { link:"/articles/000001783 ", text:" What's the difference between CPR Anytime and Mini Anne Plus?" },
                        { link:"/articles/000001668 ", text:" What's the difference between the AHA's CPR in Schools kit and Mini Anne Plus?" },
                        { link:"/articles/000001997 ", text:" What are the specifications/characteristics of the Mini Anne Plus Compression Clicker?" },
                        { link:"/articles/000006019 ", text:" What is Mini Anne Plus?" },
                        { link:"/articles/000003989 ", text:" Mini Anne Plus material specifications" },                              
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default MiniAnnePlusDocumentationPage;