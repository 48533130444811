import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneQcprDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne QCPR',
                link: '/female-manikin/resusci-anne-qcpr'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000006161 ", text:" Resusci QCPR Manikins FAQs - Serial Number Location" },
                        { link:"/articles/000005678 ", text:" What is Resusci Anne QCPR, Resusci Junior QCPR and Resusci Baby QCPR?" },
                        { link:"/articles/000005742 ", text:" Which Resusci manikins work with HeartCode?" },
                        { link:"/articles/000006158 ", text:" Where is the serial number located on my Resusci Anne QCPR?" },
                        { link:"/articles/000003564 ", text:" Does Resusci Anne QCPR contain latex?" },
                        { link:"/articles/000001248 ", text:" Is there research articles that prove the success of using VAM manikins for CPR?" },
                        { link:"/articles/000002397 ", text:" What are the options when the clothes are dirty?" },
                        { link:"/articles/000002061 ", text:" What is the life expectancy of the Resusci QCPR manikins (number of compressions)?" },
                        { link:"/articles/000001635 ", text:" What is the maximum compression depth for Resusci Anne QCPR?" },
                        { link:"/articles/000001795 ", text:" What is the pairing code for bluetooth for Resusci Anne QCPR?" },
                        { link:"/articles/000003784 ", text:" What is wrong when my Resusci Anne QCPR turns off after a few seconds?" },
                        { link:"/articles/000001651 ", text:" What parts should be replaced at intervals for Resusci Anne QCPR?" },
                        { link:"/articles/000003887 ", text:" Is it recommended to have spare batteries available using the rechargeable Resusci Anne QCPR?" },
                        { link:"/articles/000006832 ", text:" How to mount Hard Legs onto my Resusci Anne?" },
                        { link:"/articles/000004189 ", text:" What are the differences between Pre-2018 Resusci Anne QCPR and new Resusci Anne QCPR?" },
                        { link:"/articles/000005930 ", text:" Material Specifications for Resusci Anne QCPR" },
                        { link:"/articles/000005888 ", text:" How to train Prone CPR on Resusci Anne?" },
                        { link:"/articles/000004167 ", text:" How to identify if I have a Resusci QCPR BLE enabled manikin?" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneQcprDocumentationPage;