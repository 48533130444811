import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimManALSPageShockLinkPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Male manikin',
                link: '/male-manikin'
            },{
                text: 'SimMan ALS',
                link: '/male-manikin/simman-als'
            },{
                text: 'ShockLink',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000004349 ", text:" After upgrading from Shocklink to LiveShock can you then go back to using Shocklink?" },
                        { link:"/articles/000003523 ", text:" Can I use ShockLink Remote Control or ShockLink App when using SimMan ALS with ShockLink?" },
                        { link:"/articles/000003500 ", text:" Can you pace SimMan ALS using ShockLink?" },
                        { link:"/articles/000003496 ", text:" Can you use an AED with SimMan ALS?" },
                        { link:"/articles/000004269 ", text:" How to defibrillate SimMan ALS using LiveShock?" },
                        { link:"/articles/000005803 ", text:" Use of Electrode Pads and SimMan ALS/SimMan 3G PLUS LiveShock" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimManALSPageShockLinkPage;