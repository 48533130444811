import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimBabyMKIIHeadPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'SimBaby MK II',
                link: '/baby-newborn-manikin/simbaby-mk-ii'
            },{
                text: 'Head',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[                    {
                        link:"000005652",
                        text:"Can SimBaby with Tracheostomy be used on a ventilator??"
                    },{
                        link:"000005649",
                        text:"Does the tracheostomy function effect any other function in SimBaby??"
                    },{
                        link:"000005651",
                        text:"Does the tracheal tube need to be lubricated prior to insertion??"
                    },{
                        link:"000005672",
                        text:"How to Setup the SimBaby Tracheostomy function in SimPad??"
                    },{
                        link:"000005392",
                        text:"Product Data Sheet for Silicone Lubricant?"
                    },{
                        link:"000005659",
                        text:"What are the recommended tracheal tube sizes for SimBaby??"
                    },{
                        link:"000003608",
                        text:"Is nasal intubation possible on SimBaby??"
                    },{
                        link:"000004090",
                        text:"What are the sizes of different airway adjuncts that can be used by the new SimBaby??"
                    },{
                        link:"000002210",
                        text:"What size BVM is the best one for use with SimBaby??"
                    },{
                        link:"000004147",
                        text:"Why doesn't vocal sound sometimes play on SimBaby and SimNewB??"
                    }]}/>

                    <CantFindCreateCase />

            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimBabyMKIIHeadPage;