import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const VVacPage = () => {
    return (
      <>
        <ProductTopSection
            title="V-Vac"
            description="The V-VAC is a manual suction device intended for pharyngeal suction. V-VAC is a portable unit designed to provide quick and effective emergency suction in situations where conventional powered apparatus cannot be used or is not available."
            asset="assets/v-vac.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'V-Vac'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=286"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <GetToKnow 
                    title="Get to know your V-Vac"
                    items={[{
                            title: "What is the difference between the V-VAC Starter kit and Training kit?",
                            subtitle: "",
                            link: "/articles/000003555"
                        },{
                            title: "How should I clean my V-VAC?",
                            subtitle: "",
                            link: "/articles/000007170"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003555 ", text:" What is the difference between the V-VAC Starter kit and Training kit?" },
                        { link:"/articles/000006221 ", text:" Can I have a copy of the User Guide for V-VAC?" },
                        { link:"/articles/000007168 ", text:" Is the V-VAC MRI compatible?" },
                        { link:"/articles/000007171 ", text:" Which materials are V-VAC made of?" },
                        { link:"/articles/000007169 ", text:" What is the lot number structure represented on the V-Vac Starter kit?" },
                        { link:"/articles/000007170 ", text:" How should I clean my V-VAC?" },
                        { link:"/articles/000007235 ", text:" Is there a replacement for the 18fr suction catheter in V-VAC?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default VVacPage;