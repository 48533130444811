import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const SimBabyMKIITorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'SimBaby MK II',
                link: '/baby-newborn-manikin/simbaby-mk-ii'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[{
                        link:"000005650",
                        text:"Can you insert fluids into the stoma?"
                    },{
                        link:"000005945",
                        text:"Which tidal volumes are supported by SimBaby?"
                    },{
                        link:"000004099",
                        text:"How long does it take to charge the battery in SimBaby?"
                    },{
                        link:"000004098",
                        text:"How long will the battery last when using the new SimBaby?"
                    },{
                        link:"000004249",
                        text:"How to prepare the SimBaby Chest Drain Module?"
                    },{
                        link:"000004246",
                        text:"How to replace the SimBaby Needle Decompression Module?"
                    },{
                        link:"000004104",
                        text:"I am having trouble getting my tapes to stick to the new SimBaby skin what do you recommend?"
                    },{
                        link:"000004091",
                        text:"What is the recommended size of the chest drain tube for the new SimBaby?"
                    },{
                        link:"000005583",
                        text:"How much does SimBaby lungs expand/chest rise at normal breathing?"
                    },{
                        link:"000006278",
                        text:"Pneumothorax functions in SimBaby"
                    }]}/>

                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default SimBabyMKIITorsoPage;