import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const MiniBabyPage = () => {
    return (
      <>
        <ProductTopSection
            title="Mini Baby"
            description="The Mini Baby kit includes an inflatable pediatric manikin with an integrated compression clicker and a simulated AED trainer. Together with the free instructional video, this is the perfect way to learn pediatric CPR from the safety of your own home."
            asset="assets/mini-baby.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Mini Baby'
            }]}
            
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.ProvidersOrders size="48" color={COLORS.primary_500} />,
                            text:"Document request",
                            link:"/baby-newborn-manikin/mini-baby/documentation"
                        },{
                            icon: <ContentIcons.CPRTraining size="48" color={COLORS.primary_500} />,
                            text:"CPR Course",
                            link:"/articles/000006020"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Mini Baby"
                    items={[{
                            title: "What is Mini Baby?",
                            subtitle: "",
                            link: "/articles/000006013"
                        },{
                            title: "Mini Baby material specifications",
                            subtitle: "",
                            link: "/articles/000003982"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[{
                        text:"User Guide for Mini Baby",
                        link:"/articles/000005536"
                    },{
                        text:"What is Mini Baby?",
                        link:"/articles/000006021"
                    },{
                        text:"Mini Baby material specifications",
                        link:"/articles/000003982"
                    }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "SimBaby MK II",
                        asset: "assets/simbaby-mk-ii.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simbaby-mk-ii"
                    },{
                        text: "SimNewB Tetherless",
                        asset: "assets/simnewb-tetherless.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/simnewb-tetherless"
                    },{
                        text: "Little Baby QCPR",
                        asset: "assets/little-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/little-baby-qcpr"
                    },{
                        text: "Resusci Baby QCPR",
                        asset: "assets/resusci-baby-qcpr.png",
                        heightPx: "144px",
                        link: "/baby-newborn-manikin/resusci-baby-qcpr"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default MiniBabyPage;