import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const LaerdalCompactSuctionUnitCanisterPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Medical devices',
                link: '/medical-devices'
            },{
                text: 'Laerdal Compact Suction Unit',
                link: '/medical-devices/laerdal-compact-suction-unit'
            },{
                text: 'Canister',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[ 
                        { link:"/articles/000007144 ", text:" Is there a reusable canister system available for use with LCSU 4?" },
                        { link:"/articles/000007156 ", text:" How do you assemble the LCSU 4 Canisters?" },
                        { link:"/articles/000007137 ", text:" How should I clean LCSU 4?" },
                        { link:"/articles/000007152 ", text:" Shall a wet High Efficiency Filter kit be replaced?" },                        
                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default LaerdalCompactSuctionUnitCanisterPage;