import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const AedTrainer1Page = () => {
    return (
      <>
        <ProductTopSection
            title="AED Trainer"
            description="Designed to represent the typical AED use experience, the AED Trainer helps build basic competency in using an AED device as part of more efficient BLS, First Aid, and Community CPR programs. Simple enough for bystanders and powerful enough for professionals, the hand-held device can be used for standalone AED training or as part of CPR scenarios integrating with QCPR app."
            asset="assets/aed-trainer.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'AED Trainer',
                link: '/training-devices/aed-trainer'
            }, {
                text: 'AED Trainer'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=632"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/aed-trainer/"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/training-devices/aed-trainer/aed-trainer/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Mobile Application",
                            link:"/training-devices/aed-trainer/aed-trainer/mobile-application"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Pads",
                            link:"/training-devices/aed-trainer/aed-trainer/pads"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your AED Trainer"
                    items={[{
                            title: "What is AED Trainer?",
                            subtitle: "",
                            link: "/articles/000006930"
                        },{
                            title: "How does AED Trainer work?",
                            subtitle: "",
                            link: "/articles/000007216"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006926 ", text:" Training scenarios for AED Trainer" },
                        { link:"/articles/000007216 ", text:" How does AED Trainer work?" },
                        { link:"/articles/000006932 ", text:" User Guide for AED Trainer" },
                        { link:"/articles/000006923 ", text:" AED Trainer material specifications" },
                        { link:"/articles/000007177 ", text:" Battery Runtime for AED Trainer" },
                        { link:"/articles/000006930 ", text:" What is AED Trainer?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "AED Trainer 2",
                            asset: "assets/aed-trainer-2.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer-2"
                        },{
                            text: "AED Trainer 3",
                            asset: "assets/aed-trainer-3.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer-3"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default AedTrainer1Page;