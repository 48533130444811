import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciAnneQcprMobileApplicationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Female manikin',
                link: '/female-manikin'
            },{
                text: 'Resusci Anne QCPR',
                link: '/female-manikin/resusci-anne-qcpr'
            },{
                text: 'Mobile Application',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000005689 ", text:" What feedback devices do Resusci Anne QCPR, Resusci Junior QCPR and Resusci Baby QCPR work with?" },
                        { link:"/articles/000004233 ", text:" What is Ventilation audio feedback?" },
                        { link:"/articles/000005700 ", text:" Resusci QCPR Solutions - Connection" },
                        { link:"/articles/000005701 ", text:" Resusci QCPR Solutions - Setting up and running sessions" },
                        { link:"/articles/000005703 ", text:" Resusci QCPR Solutions - Real time feedback" },
                        { link:"/articles/000005702 ", text:" Resusci QCPR Solutions - Documentation" },
                        { link:"/articles/000005699 ", text:" Resusci QCPR Solutions - Availability" },
                        { link:"/articles/000004173 ", text:" How to update firmware on my Resusci Anne QCPR (2018->) and Resusci Junior QCPR using Apps?" },
                        { link:"/articles/000005705 ", text:" Resusci QCPR Solutions - Settings and manikin guidelines" },
                        { link:"/articles/000005698 ", text:" Resusci QCPR solutions overview" },

                    ]}/>
                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciAnneQcprMobileApplicationPage;