import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../../commonComponents/Topics";
import Subtopics from "../../../../../commonComponents/Subtopics";
import Faqs from "../../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const AedTrainer1DocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'AED Trainer',
                link: '/training-devices/aed-trainer'
            }, {
                text: 'AED Trainer',
                link: '/training-devices/aed-trainer/aed-trainer'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Documentation"
                    items={[ 
                        { link:"/articles/000006926 ", text:" Training scenarios for AED Trainer" },
                        { link:"/articles/000007216 ", text:" How does AED Trainer work?" },
                        { link:"/articles/000006932 ", text:" User Guide for AED Trainer" },
                        { link:"/articles/000006923 ", text:" AED Trainer material specifications" },
                        { link:"/articles/000007177 ", text:" Battery Runtime for AED Trainer" },
                        { link:"/articles/000006930 ", text:" What is AED Trainer?" },
                    ]}/>
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default AedTrainer1DocumentationPage;