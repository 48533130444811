import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const ResusciBabyQcprTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Resusci Baby QCPR',
                link: '/baby-newborn-manikin/resusci-baby-qcpr'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000005710 ", text:" How to change Lung on Resusci Baby with Airway Head?" },
                        { link:"/articles/000005674 ", text:" How to change Lung on Resusci Baby QCPR with standard Head?" },
                        { link:"/articles/000005971 ", text:" How to replace the Li-Ion Battery in my Resusci Baby QCPR (BLE)?" },
                        { link:"/articles/000005692 ", text:" Can I upgrade my Resusci Baby to the new wireless standard that is compatible with the Apps?" },
                        { link:"/articles/000005684 ", text:" What chest skins should I use with my Resusci Baby?" },

                    ]}/>

                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default ResusciBabyQcprTorsoPage;