import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../commonComponents/Topics";
import Subtopics from "../../commonComponents/Subtopics";
import Faqs from "../../commonComponents/Faqs";
import CantFindCreateCase from "../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../commonComponents/ProductTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const MedicalDevicesPage = () => {
    return (
      <>
        <ProductTopSection
            title="Medical devices"
            description=""
            asset="assets/medical-devices-big.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Medical devices'
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Topics 
                    items={[{
                            text: "BaxStrap",
                            asset: "assets/baxstrap.png",
                            heightPx: "144px",
                            link: "/medical-devices/baxstrap"
                        },{
                            text: "CPRmeter 2",
                            asset: "assets/cprmeter-2.png",
                            heightPx: "144px",
                            link: "/medical-devices/cprmeter-2"
                        },{
                            text: "Face Shields",
                            asset: "assets/face-shields.png",
                            heightPx: "144px",
                            link: "/medical-devices/face-shields"
                        },{
                            text: "HeartStart Trainer",
                            asset: "assets/heartstart-trainer.png",
                            heightPx: "144px",
                            link: "/medical-devices/heartstart-trainer"
                        },{
                            text: "Laerdal Compact Suction Unit",
                            asset: "assets/laerdal-compact-suction-unit.png",
                            heightPx: "144px",
                            link: "/medical-devices/laerdal-compact-suction-unit"
                        },{
                            text: "Laerdal Silicone Resuscitators",
                            asset: "assets/laerdal-silicone-resuscitators.png",
                            heightPx: "144px",
                            link: "/medical-devices/laerdal-silicone-resuscitators"
                        },{
                            text: "Laerdal Suction Unit",
                            asset: "assets/laerdal-suction-unit.png",
                            heightPx: "144px",
                            link: "/medical-devices/laerdal-suction-unit"
                        },{
                            text: "NeoBeat",
                            asset: "assets/neobeat.png",
                            heightPx: "144px",
                            link: "/medical-devices/neobeat"
                        },{
                            text: "NeoNatalie Resuscitator Oxygen Kit",
                            asset: "assets/neonatalie-kit.png",
                            heightPx: "108px",
                            link: "/medical-devices/neonatalie-kit"
                        },{
                            text: "Penguin Suction",
                            asset: "assets/penguin-suction.png",
                            heightPx: "144px",
                            link: "/medical-devices/penguin-suction"
                        },{
                            text: "Pocket Mask",
                            asset: "assets/pocket-mask.png",
                            heightPx: "144px",
                            link: "/medical-devices/pocket-mask"
                        },{
                            text: "Silicone Masks",
                            asset: "assets/silicone-masks.png",
                            heightPx: "144px",
                            link: "/medical-devices/silicone-masks"
                        },{
                            text: "SpeedBlocks",
                            asset: "assets/speedblocks.png",
                            heightPx: "144px",
                            link: "/medical-devices/speedblocks"
                        },{
                            text: "Stifneck Collars",
                            asset: "assets/stifneck-collars.png",
                            heightPx: "144px",
                            link: "/medical-devices/stifneck-collars"
                        },{
                            text: "Stifneck Select",
                            asset: "assets/stifneck-select.png",
                            heightPx: "144px",
                            link: "/medical-devices/stifneck-select"
                        },{
                            text: "The Bag",
                            asset: "assets/the-bag.png",
                            heightPx: "144px",
                            link: "/medical-devices/the-bag"
                        },{
                            text: "Thomas TubeHolder",
                            asset: "assets/thomas-tubeholder.png",
                            heightPx: "144px",
                            link: "/medical-devices/thomas-tubeholder"
                        },{
                            text: "V-Vac",
                            asset: "assets/v-vac.png",
                            heightPx: "144px",
                            link: "/medical-devices/v-vac"
                        },
                    ]}
                />
  
                <CantFindWhatYouNeed />
                <BigSmallContent 
                    big={{
                        title: "Laerdal Global Warranty",
                        description: "In the event that your Laerdal Medical product needs warranty service, please contact Laerdal Medical or the Authorized Dealer from whom it was purchased. In order to avoid any unnecessary inconvenience on your part, we suggest reading the Directions for Use carefully before contacting Laerdal Medical or our Authorized Dealer.",
                        linkText: "PDF",
                        linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                    }}
                    small={{
                        title: "Privacy Statement",
                        description: "This statement describes the purposes for which personal information is collected, parties with whom we may share it, and measures we take to protect your personal information.",
                        linkText: "Read more",
                        linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                    }}/>
                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default MedicalDevicesPage;