import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SpeedBlocksPage = () => {
    return (
      <>
        <ProductTopSection
            title="SpeedBlocks"
            description="The Laerdal SpeedBlocks are used to stabilize the patients head from latera, flexion and extension movement."
            asset="assets/speedblocks.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Medical devices',
                link: '/medical-devices'
            }, {
                text: 'SpeedBlocks'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=276"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <GetToKnow 
                    title="Get to know your SpeedBlocks"
                    items={[{
                            title: "Do I have to use SpeedBlocks in conjunction with a Cervical Collar?",
                            subtitle: "",
                            link: "/articles/000007041"
                        },{
                            title: "What comes with the SpeedBlock Head Immobilizer Starter Pack?",
                            subtitle: "",
                            link: "/articles/000007046"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000007041 ", text:" Do I have to use SpeedBlocks in conjunction with a Cervical Collar?" },
                        { link:"/articles/000007039 ", text:" What boards are the SpeedBlock Head Immobilizer system compatible with?" },
                        { link:"/articles/000007043 ", text:" What type of material is used for SpeedBlock Head Strap?" },
                        { link:"/articles/000007046 ", text:" What comes with the SpeedBlock Head Immobilizer Starter Pack?" }            
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                        text: "CPRmeter 2",
                        asset: "assets/cprmeter-2.png",
                        heightPx: "144px",
                        link: "/medical-devices/cprmeter-2"
                    },{
                        text: "Face Shields",
                        asset: "assets/face-shields.png",
                        heightPx: "144px",
                        link: "/medical-devices/face-shields"
                    },{
                        text: "HeartStart Trainer",
                        asset: "assets/heartstart-trainer.png",
                        heightPx: "144px",
                        link: "/medical-devices/heartstart-trainer"
                    }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SpeedBlocksPage;