import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingBabyTorsoPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Nursing Baby',
                link: '/baby-newborn-manikin/nursing-baby'
            },{
                text: 'Torso',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[
                        { link:"/articles/000002785 ", text:" Can fluid be used in the lungs of Nursing Baby for realistic suctioning?" },
                        { link:"/articles/000002787 ", text:" How much fluid will the stomach reservoir on Nursing Baby hold for NG Tube Insertion procedures?" },
                        { link:"/articles/000002824 ", text:" How should I clean and store Nursing Baby?" },
                        { link:"/articles/000002782 ", text:" Is the Nursing Baby available in ethnic skin tones?" },
                    ]}/>

                    <CantFindCreateCase />

            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingBabyTorsoPage;