import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../../commonComponents/BigSmallContent";
import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import BreadcrumbTopSection from "../../../../commonComponents/BreadcrumbTopSection";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const NursingBabyDocumentationPage = () => {
    return (
      <>
        <BreadcrumbTopSection
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Baby/Newborn manikin',
                link: '/baby-newborn-manikin'
            },{
                text: 'Nursing Baby',
                link: '/baby-newborn-manikin/nursing-baby'
            },{
                text: 'Documentation',
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Faqs 
                    title="Relevant articles"
                    items={[{
                        link:"/articles/000002784",
                        text:"Does Nursing Baby have enema administration capabilities?"
                    },{
                        link:"/articles/000001253",
                        text:"Does Nursing Baby contain latex?"
                    },{
                        link:"/articles/000002779",
                        text:"Where is the serial number located on Nursing Baby?"
                    },{
                        link:"/articles/000002775",
                        text:"What ships with Nursing Baby?"
                    },{
                        link:"/articles/000002786",
                        text:"What size NG Tube should be used with Nursing Baby?"
                    },{
                        link:"/articles/000002804",
                        text:"What size tracheostomy tube is recommended for use with Nursing Baby?"
                    },{
                        link:"/articles/000002783",
                        text:"What size urinary catheter is recommended for use with Nursing Baby?"
                    },{
                        link:"/articles/000002773",
                        text:"What spare parts are available for Nursing Baby?"
                    },{
                        link:"/articles/000002778",
                        text:"Where can I find a copy of the User Guide for Nursing Baby?"
                    }]}/>

                    <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default NursingBabyDocumentationPage;