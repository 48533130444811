import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const QcprAppPage = () => {
    return (
      <>
        <ProductTopSection
            title="QCPR App"
            description="The QCPR App pairs with a wide-range of Laerdal manikins and devices to take CPR training to the next level."
            asset="assets/qcpr-app.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Software',
                link: '/software'
            }, {
                text: 'QCPR App'
            }]}
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/qcpr-classroom-app-how-to-videos/"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Mobile Application",
                            link:"/software/qcpr-app/mobile-application"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your QCPR App"
                    items={[{
                            title: "What is QCPR App?",
                            subtitle: "",
                            link: "/articles/000005882"
                        },{
                            title: "What is QCPR Learner App?",
                            subtitle: "",
                            link: "/articles/000003654"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005975 ", text:" Anniewhere - How to interpret feedback symbols?" },
                        { link:"/articles/000005988 ", text:" Anniewhere - How to sign up for access?" },
                        { link:"/articles/000005874 ", text:" Are Laerdal Apps in compliance with WCAG guidelines?" },
                        { link:"/articles/000005548 ", text:" Can I export the CPR data from the QCPR App to my computer?" },
                        { link:"/articles/000005879 ", text:" How to view QCPR SkillReporter App results in Excel?" },
                        { link:"/articles/000005404 ", text:" QCPR Classroom App - How to set up the Classroom system?" },
                        { link:"/articles/000005406 ", text:" QCPR Classroom App - How to run QCPR Race" },
                        { link:"/articles/000005407 ", text:" QCPR Classroom App - How to run a Training session?" }
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Shock Link",
                            asset: "assets/shock-link.png",
                            heightPx: "144px",
                            link: "/software/shock-link"
                        },{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/software/aed-trainer"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default QcprAppPage;