import axios from 'axios';
import { SearchResultDto } from '../../model/dto/searchResultDto';


class SearchApi {
  Search = async (
    searchTerm: string | null,
    header: string | null | undefined, 
    h1:string | null | undefined, 
    h2:string | null | undefined, 
    h3:string | null | undefined, 
    h4:string | null | undefined, 
    h5:string | null | undefined, 
    h6:string | null | undefined, 
    src:string | null | undefined, 
    product:string | null | undefined, 
    locale:string | null | undefined, 
    source:string | null | undefined, 
    signal?: AbortSignal
    ): Promise<SearchResultDto[]> => {

    if(!!searchTerm){
      //@ts-ignore
      posthog.capture('Query searched', {query: searchTerm, product: product, source: source })
    }

    let url = `${process.env.REACT_APP_API_URL}/search`;

    
    const searchParams = new URLSearchParams();
    if(searchTerm) searchParams.append("query", searchTerm);
    if(header) searchParams.append("header", header);
    if(h1) searchParams.append("h1", h1);
    if(h2) searchParams.append("h2", h2);
    if(h3) searchParams.append("h3", h3);
    if(h4) searchParams.append("h4", h4);
    if(h5) searchParams.append("h5", h5);
    if(h6) searchParams.append("h6", h6);
    if(src) searchParams.append("src", src);
    if(product) searchParams.append("productLines", product);
    if(locale) searchParams.append("locale", locale);
    if(source) searchParams.append("source", source);
    
    url = `${url}?${searchParams.toString()}`;

    return axios
      .get(url, { signal : signal })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };
  
}

export default new SearchApi;