import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";
import BigSmallContent from "../../../commonComponents/BigSmallContent";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import styled from "styled-components";


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
`;

const AedTrainerPage = () => {
    return (
      <>
        <ProductTopSection
            title="AED Trainer"
            description="Laerdal Medical's AED Trainer products are essential tools for teaching the effective use of automated external defibrillators (AEDs) in emergency situations. These training devices, such as the Laerdal AED Trainer 3 and the Laerdal AED Trainer 2, replicate the functionality and operation of real AEDs without delivering actual shocks, providing a safe and realistic training environment."
            asset="assets/aed-trainer.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            },{
                text: 'Training Devices',
                link: '/training-devices'
            },{
                text: 'AED Trainer'
            }]}
        />
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <Topics 
                    items={[{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer"
                        },{
                            text: "AED Trainer 2",
                            asset: "assets/aed-trainer-2.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer-2"
                        },{
                            text: "AED Trainer 3",
                            asset: "assets/aed-trainer-3.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer-3"
                        }
                    ]}
                />

           
                
                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006930 ", text:" What is AED Trainer?" },
                        { link:"/articles/000006037 ", text:" What is AED Trainer 2?" },
                        { link:"/articles/000005530 ", text:" User Guide for AED Trainer 2" },
                        { link:"/articles/000005531 ", text:" User Guide for AED Trainer 3" },
                        { link:"/articles/000006035 ", text:" What is AED Trainer 3?" },
                    ]}  
                    />
                <CantFindWhatYouNeed />
                <BigSmallContent 
                    big={{
                        title: "Laerdal Global Warranty",
                        description: "In the event that your Laerdal Medical product needs warranty service, please contact Laerdal Medical or the Authorized Dealer from whom it was purchased. In order to avoid any unnecessary inconvenience on your part, we suggest reading the Directions for Use carefully before contacting Laerdal Medical or our Authorized Dealer.",
                        linkText: "PDF",
                        linkUrl: "https://cdn.laerdal.com/downloads/f4781/Att1toPRO-ML01-0295.pdf",
                    }}
                    small={{
                        title: "Privacy Statement",
                        description: "This statement describes the purposes for which personal information is collected, parties with whom we may share it, and measures we take to protect your personal information.",
                        linkText: "Read more",
                        linkUrl: "https://laerdal.com/support/customer-service/privacy-policy/"
                    }}/>
                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>
      </>);
  };
  
  export default AedTrainerPage;