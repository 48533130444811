import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../../commonComponents/Topics";
import Subtopics from "../../../../commonComponents/Subtopics";
import Faqs from "../../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const AedTrainer3Page = () => {
    return (
      <>
        <ProductTopSection
            title="AED Trainer 3"
            description="The AED Trainer 3 is designed to prepare emergency responders to use the HeartStart FR3 automatic external defibrillator (AED) and other AEDs. Together with appropriate manikins, the AED Trainer 3 is intended to give realistic training on delivering correct treatment, including shock delivery and CPR, to a cardiac arrest victim."
            asset="assets/aed-trainer-3.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Training Devices',
                link: '/training-devices'
            }, {
                text: 'AED Trainer',
                link: '/training-devices/aed-trainer'
            }, {
                text: 'AED Trainer 3'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=355"
        />

        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/training-devices/aed-trainer/aed-trainer-3/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Software",
                            link:"/training-devices/aed-trainer/aed-trainer-3/software"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Pads",
                            link:"/training-devices/aed-trainer/aed-trainer-3/pads"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your AED Trainer"
                    items={[{
                            title: "What is the intended use for AED Trainer 3",
                            subtitle: "",
                            link: "/articles/000001657"
                        },{
                            title: "What is AED Trainer 3?",
                            subtitle: "",
                            link: "/articles/000006035"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005531 ", text:" User Guide for AED Trainer 3" },
                        { link:"/articles/000004197 ", text:" Training scenarios for AED Trainer 3" },
                        { link:"/articles/000001657 ", text:" What is the intended use for AED Trainer 3" },
                        { link:"/articles/000002299 ", text:" What languages are available for AED Trainer 2 and 3?" },
                        { link:"/articles/000005424 ", text:" Can AED Trainer 3 and other AED Trainers be used with RA AST and RA Simulator?" },
                        { link:"/articles/000006035 ", text:" What is AED Trainer 3?" },
                        { link:"/articles/000006328 ", text:" How to use the Laerdal AED Trainer 3 for the first time?" },
                        { link:"/articles/000006285 ", text:" Do AED Trainers comply with 2020 Guidelines?" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "AED Trainer",
                            asset: "assets/aed-trainer.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer"
                        },{
                            text: "AED Trainer 2",
                            asset: "assets/aed-trainer-2.png",
                            heightPx: "144px",
                            link: "/training-devices/aed-trainer/aed-trainer-2"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default AedTrainer3Page;