import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const NursingAnnePage = () => {
    return (
      <>
        <ProductTopSection
            title="Nursing Anne"
            description="Nursing Anne Advanced is a full-body, lifelike vinyl manikin designed to teach all skills from basic patient handling to advanced nursing, including the measurement of noninvasive blood pressure and the auscultation and recognition of normal and abnormal heart, lung and bowel sounds and fetal tones."
            asset="assets/nursing-anne.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Female manikin',
                link: '/female-manikin'
            }, {
                text: 'Nursing Anne'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=129"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/female-manikin/nursing-anne/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/female-manikin/nursing-anne/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/female-manikin/nursing-anne/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/female-manikin/nursing-anne/torso"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Pelvis",
                            link:"/female-manikin/nursing-anne/pelvis"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Nursing Anne"
                    items={[{
                            title: "How to attach the legs to Nursing Anne?",
                            subtitle: "",
                            link: "/articles/000002625"
                        },{
                            title: "Can Nursing Anne be defibrillated?",
                            subtitle: "",
                            link: "/articles/000003595"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000003651 ", text:" Are there temperature limits or environmental conditions to consider for operation of or storing Nursing Anne?" },
                        { link:"/articles/000001485 ", text:" Does Nursing Anne contain latex?" },
                        { link:"/articles/000003240 ", text:" Where is the serial number located on Nursing Anne?" },
                        { link:"/articles/000002589 ", text:" What parts are available for Nursing Anne?" },
                        { link:"/articles/000003251 ", text:" Where can I find a copy of the User Guide for Nursing Anne?" },
                        { link:"/articles/000003626 ", text:" How to perform NG tube insertion on Nursing Anne?" },
                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/nursing-anne"
                        },{
                            text: "Resusci Anne QCPR",
                            asset: "assets/nursing-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/nursing-anne"
                        },
                        {
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default NursingAnnePage;