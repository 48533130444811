import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const ResusciAnneSimulatorPage = () => {
    return (
      <>
        <ProductTopSection
            title="Resusci Anne Simulator"
            description="The Resusci Anne Simulator (RA Sim) manikin is designed for the unique training needs of emergency care in both pre-hospital and in-hospital environments. The manikin is optimized for quality simulation training in a wide range of ALS scenarios, helping transform training into a mobile, dynamic and realistic learning experience for both the instructor and the trainee. This User Guide covers two configurations of the RA Sim: RA Sim AED Link version and RA Sim Paddle version. Unless otherwise specified, the information in this User Guide applies to both configurations."
            asset="assets/resusci-anne-simulator.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Female manikin',
                link: '/female-manikin'
            }, {
                text: 'Resusci Anne Simulator'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=122"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/female-manikin/resusci-anne-simulator/documentation"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Accessories",
                            link:"/female-manikin/resusci-anne-simulator/accessories"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Head",
                            link:"/female-manikin/resusci-anne-simulator/head"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Software",
                            link:"/female-manikin/resusci-anne-simulator/software"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Torso",
                            link:"/female-manikin/resusci-anne-simulator/torso"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your Resusci Anne Simulator"
                    items={[{
                            title: "What are the clinical features for Resusci Anne Simulator?",
                            subtitle: "",
                            link: "/articles/000005565"
                        },{
                            title: "What circulation features does Resusci Anne Simulator have?",
                            subtitle: "",
                            link: "/articles/000001894"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000005565", text:" What are the clinical features for Resusci Anne Simulator?" },
                        { link:"/articles/000002585", text:" Does the Resusci Anne Simulator contain latex?" },
                        { link:"/articles/000001894", text:" What circulation features does Resusci Anne Simulator have?" },
                        { link:"/articles/000001591", text:" What is the warranty on the Resusci Anne Simulator with SimPad?" },
                        { link:"/articles/000001471", text:" What Resusci Anne Simulator BP readings are controlled by SimPad?" },
                        { link:"/articles/000001437", text:" What Resusci Anne Simulator pulses are controlled by SimPad?" },
                        { link:"/articles/000002304", text:" Why did I receive a manual air pump with the Resusci Anne Simulator?" },
                        { link:"/articles/000001383", text:" Will I damage the compressed air container in the Resusci Anne Simulator if I overfill it?" },
                        { link:"/articles/000007180", text:" Where is the serial number located on my Resusci Anne Simulator / Resusci Anne Advanced SkillTrainer?" },
                        { link:"/articles/000006036", text:" Material Specifications for Resusci Anne Simulator" },
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "Little Anne QCPR",
                            asset: "assets/little-anne-qcpr.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-simulator"
                        },{
                            text: "Resusci Anne Simulator",
                            asset: "assets/resusci-anne-simulator.png",
                            heightPx: "144px",
                            link: "/female-manikin/resusci-anne-simulator"
                        },
                        {
                            text: "Little Anne Stackable",
                            asset: "assets/little-anne-stackable.png",
                            heightPx: "144px",
                            link: "/female-manikin/little-anne-stackable"
                        },{
                            text: "Mini Anne",
                            asset: "assets/mini-anne.png",
                            heightPx: "144px",
                            link: "/female-manikin/mini-anne"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default ResusciAnneSimulatorPage;