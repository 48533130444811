import { COLORS, ContentIcons, PageWidth } from "@laerdal/life-react-components";
import Topics from "../../../commonComponents/Topics";
import Subtopics from "../../../commonComponents/Subtopics";
import Faqs from "../../../commonComponents/Faqs";
import CantFindCreateCase from "../../../commonComponents/CantFindCreateCase";
import CantFindWhatYouNeed from "../../../commonComponents/CantFindWhatYouNeed";

import styled from "styled-components";
import ProductTopSection from "../../../commonComponents/ProductTopSection";
import DetailsWithYoutube from "../../../commonComponents/DetailsWithYoutube";
import GetToKnow from "../../../commonComponents/GetToKnow";
import LaerdalCustomerCare from "../../../commonComponents/LaerdalCustomerCare";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:32px;
`;
const FullNeutralPageContainer = styled.div`
    display:flex;
    justify-content: center;
    flex-grow: 1;
    padding: 32px 0;
`;

const SimMan3GPlusPage = () => {
    return (
      <>
        <ProductTopSection
            title="SimMan 3G PLUS"
            description="SimMan 3G PLUS is a durable patient simulator created for high-quality simulation training in a multitude of different medical procedures and patient cases. Use SimMan 3G PLUS as a stand-alone simulator, or expand your training possibilities with high-quality add-ons and your own equipment. SimMan 3G PLUS offers interchangeable headskins, articulating arms, and LiveShock."
            asset="assets/simman-3g-plus.png" 
            breadcrumb={[{
                text: 'Home',
                link: '/'
            }, {
                text: 'Male manikin',
                link: '/male-manikin'
            }, {
                text: 'SimMan 3G PLUS'
            }]}
            buttonLink="https://laerdal.com/ProductDownloads.aspx?productId=609"
        />
        <FullNeutralPageContainer>
            <PageContainer>
                <Subtopics
                    items={[{
                            icon: <ContentIcons.Video size="48" color={COLORS.primary_500} />,
                            text: "How-to videos",
                            link: "https://laerdal.com/support/how-to-videos/simman-3g-plus-how-to-videos/"
                        },{
                            icon: <ContentIcons.DocumentChecked size="48" color={COLORS.primary_500} />,
                            text:"Documentation",
                            link:"/articles/000007321"
                        },{
                            icon: <ContentIcons.Adult size="48" color={COLORS.primary_500} />,
                            text:"Arms",
                            link:"/articles/000006280"
                        }
                    ]}
                />
                <GetToKnow 
                    title="Get to know your SimMan 3G PLUS"
                    items={[{
                            title: "Can I use SimMan 3G Arms on SimMan 3G PLUS?",
                            subtitle: "",
                            link: "/articles/000006280"
                        },{
                            title: "Where are the different pulse sites located?",
                            subtitle: "",
                            link: "/articles/000007321"
                        }
                    ]}
                />
            </PageContainer>
        </FullNeutralPageContainer>

        {/* <DetailsWithYoutube 
            title="Mini Anne - CPR Course Video" 
            subtitle="Learn life saving CPR skills with Mini Anne"
            description="The Mini Anne CPR Course Video offers a concise overview of the Mini Anne CPR training kit. It demonstrates how the kit effectively teaches basic CPR skills for both individual and group training. Through clear demonstrations, viewers learn to perform CPR using the Mini Anne manikin."
            youtubeLink="https://www.youtube.com/embed/OdZe3L9DboM?si=cPz1LMWe1aXqwPcn"
            /> */}

                
        <PageWidth useMaxWidth={true} maxWidth={1024} style={{width:"calc(100% - 32px)"}}>
            <PageContainer>
                <CantFindWhatYouNeed />

                <Faqs 
                    title="FAQs"
                    items={[
                        { link:"/articles/000006280 ", text:" Can I use SimMan 3G Arms on SimMan 3G PLUS?" },
                        { link:"/articles/000007321 ", text:" Where are the different pulse sites located?" },
                        
                    ]}/>

                <Topics 
                    title="Similar products"
                    items={[{
                            text: "MegaCode Kelly",
                            asset: "assets/megacode-kelly.png",
                            heightPx: "144px",
                            link: "/male-manikin/megacode-kelly"
                        },{
                            text: "SimMan 3G Essential",
                            asset: "assets/simman-3g-essential.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential"
                        },{
                            text: "SimMan 3G Essential Bleeding",
                            asset: "assets/simman-3g-essential-bleeding.png",
                            heightPx: "144px",
                            link: "/male-manikin/simman-3g-essential-bleeding"
                        }
                    ]}
                />

                <CantFindCreateCase />
            </PageContainer>
        </PageWidth>

        
      </>);
  };
  
  export default SimMan3GPlusPage;